import styled from 'styled-components';

export const HomeContainerStyled = styled.div`
  padding: 60px 20px 104px 20px;
  .menu_item_wrap {
    margin-bottom: 34px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
