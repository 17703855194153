import styled from "styled-components";

export const QuestionAnsItemStyled = styled.div`
  .question_ans_item_container {
    .qai_question {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .qai_ans {
      padding-left: 15px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
    }
  }

  .theme_standard {
  }
`;
