import styled from "styled-components";

export const TextAreaStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  textarea {
    border-radius: 9px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_5};
    width: 100%;
    height: 122px;
    box-sizing: border-box;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.WHITE_1 : theme.COLORS.GRAY_3};
    padding: 8px 9px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: ${({ disabled }) => (!disabled ? "auto" : "not-allowed")};
    &::placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }

  &.theme_question {
    .label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    }
    textarea {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    }
  }

  &.theme_activity_form {
    textarea {
      border: 1.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      height: 44px;
    }
  }
`;
