import AlertAction from './AlertAction';
import AlertLabel from './AlertLabel';
import Avatar from './Avatar';
import BGLoading from './BGLoading';
import HeaderIconTitle from './HeaderIconTitle';
import LabelEmpty from './LabelEmpty';
import Loading from './Loading';
import RewardStatus from './RewardStatus';

const EXPORT = {
  AlertAction,
  AlertLabel,
  Avatar,
  BGLoading,
  HeaderIconTitle,
  LabelEmpty,
  Loading,
  RewardStatus,
};

export default EXPORT;
