import React from "react";
import { connect } from "react-redux";
import { FavouriteContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { MyPointWidget } from "widgets";
import { Cards, Displays, Headers, Icons } from "components";

class FavouriteContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux } = this.props;
    let res = await userService.GET_QUESTION_LIST(
      `?is_active=true&pagination=false`
    );
    if (res && res.status === 200) {
      this.setState({
        data: {
          ...res.data,
          docs: res.data.docs.filter(
            (e) =>
              !e.is_join ||
              (e.is_join && e.customer_joined_list.includes(authenRedux._id))
          ),
        },
        isLoading: false,
      });
    }
  };

  handleClickItem = (e) => {
    this.props.router.navigate(
      `${ROUTE_PATH.SPECIAL_POINTS_FAV}/test/${e._id}`
    );
  };

  render() {
    const { t } = this.props;
    const { isLoading, data } = this.state;
    return (
      <FavouriteContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"25px"}
          icon={<Icons.SpecialPoint />}
          topLabel={"Special Points"}
          bottomLabel={"คะแนนพิเศษ"}
        />
        <div className="title_status">{t("Survey", { ns: "home" })}</div>
        {isLoading ? (
          <Displays.Loading />
        ) : !data || data.docs.length === 0 ? (
          <Displays.LabelEmpty label={t("No Data", { ns: "common" })} />
        ) : (
          data.docs.map((e, i) => (
            <div
              key={i}
              className="fav_item_wrap"
              onClick={() => this.handleClickItem(e)}
            >
              <Cards.Question
                src={e.front_img}
                title={e.title}
                description={e.description}
              />
            </div>
          ))
        )}
      </FavouriteContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common", "home"])(withRouter(FavouriteContainer)));
