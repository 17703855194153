import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LabelEmptyStyled } from './styled';

const LabelEmpty = ({ theme_standard, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LabelEmptyStyled>
      <div className={customClass}>{label}</div>
    </LabelEmptyStyled>
  );
};

LabelEmpty.propTypes = {};

export default LabelEmpty;
