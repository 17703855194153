import React from 'react';

export default function renderIcon({
  width = 40.781,
  height = 40.781,
  color = '#fff',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40.781 40.781"
    >
      <g id="Group_90" data-name="Group 90" transform="translate(-34 -495.682)">
        <path
          id="Path_160"
          data-name="Path 160"
          d="M1950.794,1118.04a20.391,20.391,0,1,1,20.391-20.39A20.413,20.413,0,0,1,1950.794,1118.04Zm0-37.449a17.058,17.058,0,1,0,17.058,17.058A17.078,17.078,0,0,0,1950.794,1080.592Z"
          transform="translate(-1896.404 -581.577)"
          fill={color}
        />
        <path
          id="Path_161"
          data-name="Path 161"
          d="M1957.118,1092.531l1.5,4.6a1.416,1.416,0,0,0,1.346.979h4.838a1.416,1.416,0,0,1,.833,2.561l-3.914,2.843a1.416,1.416,0,0,0-.515,1.583l1.495,4.6a1.416,1.416,0,0,1-2.178,1.583l-3.915-2.844a1.414,1.414,0,0,0-1.664,0l-3.915,2.844a1.416,1.416,0,0,1-2.178-1.583l1.495-4.6a1.416,1.416,0,0,0-.514-1.583l-3.914-2.843a1.416,1.416,0,0,1,.833-2.561h4.838a1.416,1.416,0,0,0,1.346-.979l1.5-4.6a1.416,1.416,0,0,1,2.693,0"
          transform="translate(-1901.381 -586.344)"
          fill={color}
        />
      </g>
    </svg>
  );
}
