import React from 'react';

export default function renderIcon({
  width = 23.303,
  height = 31.314,
  color = '#4B1E78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.303 31.314"
    >
      <g id="Group_259" data-name="Group 259" transform="translate(0 0)">
        <g id="Group_257" data-name="Group 257">
          <path
            id="Path_3826"
            data-name="Path 3826"
            d="M590.422,7507.662a10.351,10.351,0,1,1-10.351,10.35,10.362,10.362,0,0,1,10.351-10.35m0-1.083a11.433,11.433,0,1,0,11.433,11.433,11.433,11.433,0,0,0-11.433-11.433Z"
            transform="translate(-578.812 -7506.579)"
            fill={color}
          />
          <path
            id="Path_3827"
            data-name="Path 3827"
            d="M584.907,7543.51h-.028a.542.542,0,0,1-.478-.35l-1.487-3.964-3.47.929a.541.541,0,0,1-.619-.775l3.945-7.482.957.505-3.367,6.386,2.748-.737a.538.538,0,0,1,.646.332l1.224,3.263,3.615-7.228.968.484-4.169,8.338A.542.542,0,0,1,584.907,7543.51Z"
            transform="translate(-578.762 -7512.196)"
            fill={color}
          />
          <path
            id="Path_3828"
            data-name="Path 3828"
            d="M599.49,7543.51a.541.541,0,0,1-.484-.3l-4.169-8.338.968-.484,3.614,7.228,1.224-3.263a.54.54,0,0,1,.647-.332l2.748.737-3.368-6.386.958-.505,3.945,7.482a.54.54,0,0,1-.618.775l-3.47-.929L600,7543.159a.542.542,0,0,1-.478.35Z"
            transform="translate(-582.333 -7512.196)"
            fill={color}
          />
        </g>
        <g
          id="Group_258"
          data-name="Group 258"
          transform="translate(6.547 5.319)"
        >
          <path
            id="Path_3829"
            data-name="Path 3829"
            d="M596.653,7517.5v1.142h-8.824V7517.5h8.824m.119-.652H587.71a.532.532,0,0,0-.532.532v1.381a.532.532,0,0,0,.532.532h9.062a.532.532,0,0,0,.532-.532v-1.381a.532.532,0,0,0-.532-.532Z"
            transform="translate(-587.178 -7514.18)"
            fill={color}
          />
          <path
            id="Path_3830"
            data-name="Path 3830"
            d="M596.031,7519.891l.018,5.471-7.4.019-.018-5.489h7.4m.018-.652h-7.416a.634.634,0,0,0-.633.634v5.508a.633.633,0,0,0,.633.634h7.416a.633.633,0,0,0,.633-.634v-5.508a.634.634,0,0,0-.633-.634Z"
            transform="translate(-587.36 -7514.709)"
            fill={color}
          />
          <path
            id="Path_3831"
            data-name="Path 3831"
            d="M590.386,7516.581l-1.393-.933a1.014,1.014,0,0,1-.35-1.21,1.765,1.765,0,0,1,1.318-1.018c.4-.05,1.173.513,1.595.9a2.759,2.759,0,0,1,1.059,1.866l-.651-.01c.013-.9-1.623-2.062-1.959-2.105a1.175,1.175,0,0,0-.74.565c-.109.344.072.46.075.462l1.409.942Z"
            transform="translate(-587.489 -7513.417)"
            fill={color}
          />
          <path
            id="Path_3832"
            data-name="Path 3832"
            d="M595.444,7516.581l-.362-.542,1.392-.933c.053-.035.191-.157.091-.472a1.162,1.162,0,0,0-.766-.566c-.309.044-1.945,1.205-1.932,2.106l-.651.01a2.76,2.76,0,0,1,1.058-1.866c.422-.385,1.192-.94,1.6-.9a1.767,1.767,0,0,1,1.318,1.018,1.017,1.017,0,0,1-.366,1.222Z"
            transform="translate(-588.519 -7513.417)"
            fill={color}
          />
          <path
            id="Path_3833"
            data-name="Path 3833"
            d="M594.722,7519.312H591.7v-2.369h3.027Zm-2.375-.651h1.724v-1.066h-1.724Z"
            transform="translate(-588.181 -7514.2)"
            fill={color}
          />
          <path
            id="Path_3834"
            data-name="Path 3834"
            d="M594.405,7525.778h-2.194v-6.6h2.194Zm-1.543-.651h.892v-5.3h-.892Z"
            transform="translate(-588.296 -7514.696)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
