import React from "react";
// import PropTypes from 'prop-types'
import { LoadingStyled } from "./styled";
import cx from "classnames";

const Loading = ({ theme_standard_loading }) => {
  const customClass = cx({
    theme_standard_loading: theme_standard_loading,
  });

  return (
    <LoadingStyled>
      <div className={customClass}>
        {/* <Icons.Loading /> */}
        <img
          className="yamaha_loading"
          alt="yamaha loading"
          src="/assets/images/icons/note-gif-33.gif"
        />
      </div>
    </LoadingStyled>
  );
};

Loading.propTypes = {};

export default Loading;
