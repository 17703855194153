import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ProductRedeemStyled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';

const ProductRedeem = ({ theme_standard, src, redeemPoint }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ProductRedeemStyled>
      <div className={customClass}>
        <div className="product_redeem_container">
          <img
            className="reward_img"
            alt="reward yamaha"
            src={src ? IMG_PATH + src : '/assets/images/mockups/reward-1.jpg'}
          />
          <div className="redeem_point">{redeemPoint} Points</div>
        </div>
      </div>
    </ProductRedeemStyled>
  );
};

ProductRedeem.propTypes = {};

export default ProductRedeem;
