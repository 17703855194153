import styled from "styled-components";

export const ActivityStyled = styled.div`
  .new_container {
    cursor: pointer;
    .new_img_wrap {
      position: relative;
      margin-bottom: 15px;
      .new_img {
        width: 100%;
        height: auto;
      }
      .status_line {
        position: absolute;
        bottom: 0px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .new_content_block {
      overflow: hidden;
      .n_header {
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S19};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
      .n_description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S11};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }

  .theme_standard {
  }
`;
