import styled from 'styled-components';

export const QuickTestContainerStyled = styled.div`
  padding: 60px 18px 104px 18px;
  .form_title {
    margin-bottom: 7px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S23};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
`;
