import React from "react";
import { QuickTestContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxWallet } from "store/reducers/walletReducer";
import { toast } from "react-toastify";
import { MyPointWidget } from "widgets";
import { Displays, Forms, Headers, Icons } from "components";

class QuickTestContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux, router } = this.props;
    let res = await Promise.all([
      userService.GET_QUESTION_DETAIL(router.params?.id),
      userService.GET_ASSESSMENT_BY_ME_QUESTION(
        router.params?.id,
        authenRedux.member_type_active
      ),
    ]);
    if (res[0] && res[0].status === 200) {
      let initialTemp = {
        ...res[0].data,
      };
      if (res[1].status === 200) {
        initialTemp = {
          ...initialTemp,
          question_list: res[1]?.data.question_list.map((e) => ({
            ...e,
          })),
          isAssessment: true,
        };
      }
      this.setState({
        initialValue: initialTemp,
        assessmentData: initialTemp.isAssessment ? res[1].data : false,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleSubmit = async (values) => {
    const { authenRedux, router } = this.props;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
      question_id: router.params.id,
      member_type: authenRedux.member_type_active,
    };
    let res = await userService.POST_ASSESSMENT_DETAIL(params);
    if (res && res.status === 200) {
      this.updateMyWallet();
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error("ประเมิณไม่สำเร็จ");
    }
  };

  updateMyWallet = async () => {
    let res = await userService.GET_MY_WALLET();
    if (res) {
      this.props.setReduxWallet(res.data);
      this.handleNextStep();
    }
  };

  handleBack = () => {
    this.props.router.navigate(-1);
  };

  handleNextStep = () => {
    const { router } = this.props;
    const { initialValue } = this.state;
    this.props.router.navigate(
      `${ROUTE_PATH.SPECIAL_POINTS_FAV}/test/${router.params?.id}/success?point=${initialValue.point}`
    );
  };

  render() {
    const { isLoading, isBgLoading, initialValue } = this.state;
    return (
      <QuickTestContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"25px"}
          icon={<Icons.SpecialPoint />}
          topLabel={"Special Points"}
          bottomLabel={"คะแนนพิเศษ"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="form_title">{initialValue.title}</div>
            <Forms.QuickTestForm
              initialValue={initialValue}
              onBack={this.handleBack}
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </QuickTestContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QuickTestContainer));
