import React from 'react';

export default function renderIcon({
  width = 19.963,
  height = 22.886,
  color = '#4B1E78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19.963 22.886"
    >
      <g id="Group_260" data-name="Group 260" transform="translate(0 0)">
        <path
          id="Path_3835"
          data-name="Path 3835"
          d="M803.494,7517.323v2.57H785.783v-2.57h17.711m.077-1.125H785.706a1.049,1.049,0,0,0-1.049,1.048v2.724a1.049,1.049,0,0,0,1.049,1.049h17.866a1.048,1.048,0,0,0,1.049-1.049v-2.724a1.048,1.048,0,0,0-1.049-1.048Z"
          transform="translate(-784.657 -7511.011)"
          fill={color}
        />
        <path
          id="Path_3836"
          data-name="Path 3836"
          d="M802.144,7522.907a.124.124,0,0,1,.123.124v10.857a.123.123,0,0,1-.123.123H787.524a.123.123,0,0,1-.123-.123v-10.857a.124.124,0,0,1,.123-.124h14.619m0-1.125H787.524a1.249,1.249,0,0,0-1.248,1.249v10.857a1.249,1.249,0,0,0,1.248,1.249h14.619a1.249,1.249,0,0,0,1.249-1.249v-10.857a1.248,1.248,0,0,0-1.249-1.249Z"
          transform="translate(-785.017 -7512.251)"
          fill={color}
        />
        <path
          id="Path_3837"
          data-name="Path 3837"
          d="M791.048,7515.619l-2.747-1.837a1.922,1.922,0,0,1-.658-2.3,3.391,3.391,0,0,1,2.53-1.95c.764-.076,2.261,1,3.083,1.748.952.867,2.082,2.2,2.061,3.62l-1.126-.017c.026-1.819-3.274-4.166-3.933-4.231a2.368,2.368,0,0,0-1.542,1.17c-.237.748.178,1,.182,1l2.775,1.855Z"
          transform="translate(-785.293 -7509.53)"
          fill={color}
        />
        <path
          id="Path_3838"
          data-name="Path 3838"
          d="M800.931,7515.619l-.626-.936,2.747-1.837c.088-.058.431-.327.211-1.021a2.363,2.363,0,0,0-1.578-1.171c-.623.067-3.924,2.414-3.9,4.233l-1.126.017c-.021-1.421,1.109-2.753,2.061-3.62.821-.748,2.322-1.831,3.082-1.748a3.388,3.388,0,0,1,2.53,1.95,1.924,1.924,0,0,1-.687,2.315Z"
          transform="translate(-787.323 -7509.53)"
          fill={color}
        />
        <path
          id="Path_3839"
          data-name="Path 3839"
          d="M799.473,7520.992h-5.808v-4.513h5.808Zm-4.682-1.126h3.556v-2.261h-3.556Z"
          transform="translate(-786.658 -7511.073)"
          fill={color}
        />
        <path
          id="Path_3840"
          data-name="Path 3840"
          d="M798.849,7534.616h-4.168v-12.853h4.168Zm-3.042-1.126h1.917v-10.6h-1.917Z"
          transform="translate(-786.884 -7512.248)"
          fill={color}
        />
      </g>
    </svg>
  );
}
