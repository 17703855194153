import styled from "styled-components";

export const ActQuestionPreviewContainerStyled = styled.div`
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  min-width: 300px;
  max-width: 360px;
  max-height: 64vh;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  overflow: auto;
  .aqp_title {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
  }
  .action_row {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 16px;
    .btn_col {
      width: 100%;
      max-width: 177px;
    }
  }
`;
