export const RENDER_ACTIVIRY_TYPE = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'JOIN ONLINE',
      };
    case '2':
      return {
        label: 'JOIN ONSITE',
      };
    default:
      return false;
  }
};
 