import styled from "styled-components";

export const UserInfoStyled = styled.div`
  .user_info_container {
    .ui_row {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .ui_label {
        flex-shrink: 0;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
      .ui_value {
        padding-left: 5px;
        display: flex;
        column-gap: 8px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
  }

  .theme_standard {
  }
`;
