import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ActivitiesItemStyled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';
import { RENDER_ACTIVIRY_TYPE } from 'utils/functions/activity';

const ActivitiesItem = ({ theme_standard, src, name, type }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderType = RENDER_ACTIVIRY_TYPE(type);
  return (
    <ActivitiesItemStyled>
      <div className={customClass}>
        <div className="activities_container">
          <div className="act_left_col">
            <img
              className="act_img"
              alt="acitivities"
              src={
                src ? IMG_PATH + src : '/assets/images/mockups/activities-1.jpg'
              }
            />
          </div>
          <div className="act_right_col">
            <div className="act_name_label">{name}</div>
            {renderType && (
              <div className="act_btn_wrap">{renderType.label}</div>
            )}
          </div>
        </div>
      </div>
    </ActivitiesItemStyled>
  );
};

ActivitiesItem.propTypes = {};

export default ActivitiesItem;
