import styled from 'styled-components';

export const ProductwarranteeNotLoginContainerStyled = styled.div`
  .title {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .product_wrap {
    margin-bottom: 25px;
  }
  .product_more_item {
    padding: 12px 0px;
    ${({ theme }) => theme.COLORS.GRAT_1};
    border-top: 1px solid;
    &:first-child {
      margin-top: 4412px;
    }
  }
  .btn_add_sn {
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .input_regis_type_wrap {
    margin-bottom: 42px;
  }
`;
