import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AlertActionStyled } from './styled';
import { Buttons } from 'components';

const AlertAction = ({
  theme_standard,
  src = '/assets/images/logos/logo.png',
  label,
  isBooking,
  onCancel,
  onAccept,
  onBooking,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AlertActionStyled isThreeAction={isBooking}>
      <div className={customClass}>
        <div className="alert_action_container">
          <img className="aa_logo" alt="yamaha logo" src={src} />
          <div className="aa_header">{label}</div>
          <div className="aa_action_row">
            <div className="aa_col">
              <Buttons.BgStandard
                theme_cancel
                label={'ยกเลิก'}
                onClick={onCancel}
              />
            </div>
            {isBooking && (
              <div className="aa_col">
                <Buttons.BgStandard label={'จอง'} onClick={onBooking} />
              </div>
            )}
            <div className="aa_col">
              <Buttons.BgStandard label={'ยืนยัน'} onClick={onAccept} />
            </div>
          </div>
        </div>
      </div>
    </AlertActionStyled>
  );
};

AlertAction.propTypes = {};

export default AlertAction;
