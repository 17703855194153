import React from 'react';
import { connect } from 'react-redux';
import { ActivityHistoryContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import moment from 'moment';
import { RenderCommaMoney } from 'utils/functions/formatMoney';
import { userService } from 'apiServices';
import { withTranslation } from "react-i18next";
import { Displays, Tables } from 'components';

class ActivityHistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?member_type=${this.props.authenRedux.member_type_active}`);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.authenRedux &&
      this.props.authenRedux !== prevProps.authenRedux
    ) {
      this.fetchData(
        `?member_type=${this.props.authenRedux.member_type_active}`
      );
    }
  }

  fetchData = async (queryString) => {
    let res = await userService.GET_MY_ACTIVITY(queryString);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  render() {
    const { t } = this.props;
    const { isLoading, data } = this.state;
    return (
      <ActivityHistoryContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <Tables.HeaderRowPanel
            columns={columns({ t })}
            data={data}
            onClickRow={this.handleClickHistory}
          />
        )}
      </ActivityHistoryContainerStyled>
    );
  }
}

const columns = ({ t }) => [
  {
    title: t('Date/Time', { ns: 'profile' }),
    field: 'created_at',
    headerStyle: { width: '184px' },
    style: { width: 184 },
    render: ({ value }) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: t('Name', { ns: 'profile' }),
    field: 'activity_name',
    headerStyle: { textAlign: 'center' },
  },
  {
    title: t('Points', { ns: 'profile' }),
    field: 'activity_point',
    headerStyle: { width: '184px', textAlign: 'center' },
    style: { width: 184, textAlign: 'center' },
    render: ({ origin, value }) => `${RenderCommaMoney(value)} Points`,
  },
];

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['profile'])(withRouter(ActivityHistoryContainer)));
