import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AvatarStyled } from './styled';
// import { IMG_PATH } from '../../../utils/constants/imgPath';

const Avatar = ({ theme_standard, src, size }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <AvatarStyled size={size}>
      <div className={customClass}>
        <div className="avatar_container">
          <img
            className="avatar_img"
            alt="trongpok avatar"
            src={!src ? '/assets/images/icons/avatar.jpg' : src}
          />
        </div>
      </div>
    </AvatarStyled>
  );
};

Avatar.propTypes = {};

export default Avatar;
