import React from "react";
import { NewDetailContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import Linkify from "react-linkify";
import { MyPointWidget } from "widgets";
import { Cards, Displays, Headers, Icons } from "components";

class NewDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { router } = this.props;
    let res = await userService.GET_NEW_DETAIL(router.params?.id);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <NewDetailContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          theme_head_only
          icon={<Icons.MenuNews color="#fff" />}
          topLabel={"News"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <Cards.NewInfo
            src={data.inside_img}
            title={data.title}
            description={
              // data.description
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {data.description}
              </Linkify>
            }
          />
        )}
      </NewDetailContainerStyled>
    );
  }
}

export default withRouter(NewDetailContainer);
