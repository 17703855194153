import React, { useState } from 'react';
import { SpinWheelWidgetStyled } from './styled';
import { useSelector } from 'react-redux';
import { Wheel } from 'react-custom-roulette';
import { toast } from 'react-toastify';
import { userService } from 'apiServices';
import { Buttons } from 'components';

const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min;
};

const SpinWheelWidget = ({ data, onSpinSuccess }) => {
  const { authenRedux, walletRedux } = useSelector((state) => state);

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(
    getRandomArbitrary(0, data.length - 1)
  );

  const _handleSpinClick = async () => {
    if (walletRedux.coin === 0) {
      toast.error('คุณไม่มีแต้มในการเล่น');
    } else {
      let params = {
        member_type: authenRedux.member_type_active,
      };
      let res = await userService.POST_PLAY_LUCKY_DRAW(params);
      if (res && res.status === 200) {
        let findReward = !res.data.reward
          ? -1
          : data.findIndex((e) => e.id === res.data.reward._id);
        const newPrizeNumber = findReward !== -1 ? findReward : 0;
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
      } else {
        toast.error('โปรดลองเล่นใหม่');
      }
    }
  };

  return (
    <SpinWheelWidgetStyled>
      {!data || data.length === 0 ? (
        <>ของรางวัลหมด</>
      ) : (
        <>
          <div className="wheel_wrap">
            <Wheel
              style={{ width: 348, height: 348 }}
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              outerBorderColor={'white'}
              radiusLineWidth={0}
              spinDuration={0.5}
              onStopSpinning={() => {
                onSpinSuccess && onSpinSuccess(prizeNumber);
                setMustSpin(false);
              }}
            />
            <div className="point_center">
              <div className="point_nest" />
            </div>
          </div>

          <Buttons.BgStandard
            label="Spin the wheel"
            disabled={mustSpin}
            onClick={_handleSpinClick}
          />
        </>
      )}
    </SpinWheelWidgetStyled>
  );
};

export default SpinWheelWidget;
