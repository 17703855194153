import styled from "styled-components";

export const ConditionCardStyled = styled.div`
  .cdc_header {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
  }
  .cdc_body {
    margin-bottom: 20px;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.COLORS.BLACK_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
  }

  .theme_standard {
  }
`;
