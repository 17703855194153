import { useState, useEffect } from 'react';
import { SystemLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { userService } from 'apiServices';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { setReduxWallet } from 'store/reducers/walletReducer';
import { ROUTE_PATH } from 'utils/constants/routePath';
import {
  LanguageWidget,
  MenuBarWidget,
  NotificationWidget,
  PopupWidget,
} from 'widgets';
import { Displays } from 'components';

const SystemLayout = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    _fetchMyInfomation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchMyInfomation = async () => {
    let resArr = await Promise.all([
      userService.GET_MY_PROFILE(),
      userService.GET_MY_WALLET(),
      userService.GET_PDPA_LATEST(),
    ]);
    if (resArr) {
      dispatch(setReduxAuthen(resArr[0].data));
      dispatch(setReduxWallet(resArr[1].data));
      if (
        !resArr[0].data.pdpa_accepted_latest_ref_id ||
        resArr[0].data.pdpa_accepted_latest_ref_id !== resArr[2].data._id
      ) {
        navigate(`${ROUTE_PATH.PDPA}`);
      }
      _setIsLoading(false);
    }
  };

  return (
    <SystemLayoutStyled>
      <div className="lang_mainlayout">
        <LanguageWidget />
      </div>
      <div className="notification_mainlayout">
        <NotificationWidget />
      </div>
      <div className="popup_mainlayout">
        <PopupWidget />
      </div>
      <div className="body_mainlayout">
        {_isLoading ? <Displays.Loading /> : <Outlet />}
      </div>
      <div className="menu_mainlayout">
        <MenuBarWidget />
      </div>
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
