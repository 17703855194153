import { BtnLineWidgetStyled } from './styled';

const BtnLineWidget = () => {
  return (
    <BtnLineWidgetStyled>
      <a
        href="https://liff.line.me/1645278921-kWRPP32q/?accountId=540ptyrb"
        target="_blank"
        rel="noopener noreferrer"
      >
        Add Line Official
      </a>
    </BtnLineWidgetStyled>
  );
};

export default BtnLineWidget;
