import React from 'react';

export default function renderIcon({
  width = 82.337,
  height = 64.827,
  color = '#4b1e78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 82.337 64.827"
    >
      <g
        id="Group_310"
        data-name="Group 310"
        transform="translate(-1299.469 -3930.184)"
      >
        <path
          id="Path_3546"
          data-name="Path 3546"
          d="M1397.158,3993.586a23.887,23.887,0,0,1-1.726,4.09,8.841,8.841,0,0,1-7.936,4.038c-5.787-.026-11.575-.007-17.361-.007q-22.746,0-45.493.006a9.483,9.483,0,0,1-8.549-4.625,8.91,8.91,0,0,1-1.27-4.905q.014-11.817,0-23.631c0-4.983-.017-9.966.006-14.949a9.313,9.313,0,0,1,7.276-9.115,11.935,11.935,0,0,1,2.472-.231c3.8-.024,7.609-.011,11.414-.011h.792a19.791,19.791,0,0,1,.925-3.193,7.021,7.021,0,0,1,6.787-4.142c7.663-.022,15.325-.044,22.988.009a7.064,7.064,0,0,1,7.2,5.473,18.052,18.052,0,0,1,.3,1.852h.839c3.885,0,7.769-.006,11.655,0a9.269,9.269,0,0,1,9.554,7.814.7.7,0,0,0,.122.2Zm-41.186,3.983h3.3q14.026,0,28.052,0c3.314,0,5.262-1.616,5.642-4.874a44.21,44.21,0,0,0,.135-5.047q.014-16.8.009-33.6a5.384,5.384,0,0,0-2.092-4.637,5.868,5.868,0,0,0-3.721-1.013q-7.074.022-14.146,0a2.161,2.161,0,0,1-2.358-2.336c-.005-.429-.03-.86,0-1.286.178-2.255-.918-3.862-3.836-3.824-6.8.092-13.61.029-20.416.03-.83,0-1.663-.024-2.491.024a2.8,2.8,0,0,0-2.708,2.587c-.07.717-.052,1.444-.059,2.167-.017,1.851-.8,2.64-2.629,2.641-4.769,0-9.538-.011-14.307.006a5.056,5.056,0,0,0-5.354,5.342q-.018,19.21.018,38.42a6.1,6.1,0,0,0,.541,2.552c1,2.087,2.834,2.85,5.074,2.848Q1340.3,3997.561,1355.972,3997.569Z"
          transform="translate(-15.352 -6.704)"
          fill={color}
        />
        <path
          id="Path_3547"
          data-name="Path 3547"
          d="M1356.492,3998.078q-15.674,0-31.348,0c-2.24,0-4.071-.761-5.074-2.849a6.1,6.1,0,0,1-.541-2.552q-.049-19.21-.018-38.42a5.056,5.056,0,0,1,5.354-5.342c4.769-.017,9.538,0,14.307-.006,1.832,0,2.612-.79,2.629-2.641.007-.723-.012-1.45.059-2.167a2.8,2.8,0,0,1,2.708-2.587c.828-.048,1.661-.024,2.491-.024,6.806,0,13.611.062,20.416-.03,2.917-.038,4.014,1.569,3.836,3.824-.034.427-.009.857,0,1.286a2.161,2.161,0,0,0,2.358,2.336q7.074.012,14.146,0a5.872,5.872,0,0,1,3.721,1.013,5.384,5.384,0,0,1,2.092,4.637q-.012,16.8-.009,33.6a44.186,44.186,0,0,1-.135,5.047c-.38,3.258-2.328,4.873-5.642,4.874q-14.026,0-28.052,0Zm.018-43.843a19.254,19.254,0,1,0,19.261,19.3A19.3,19.3,0,0,0,1356.51,3954.235Zm27.879,7.57a3.809,3.809,0,1,0-3.845-3.814A3.8,3.8,0,0,0,1384.389,3961.805Z"
          transform="translate(-15.872 -7.212)"
          fill={color}
        />
        <path
          id="Path_3548"
          data-name="Path 3548"
          d="M1358.728,3955.831a19.254,19.254,0,1,1-19.256,19.219A19.331,19.331,0,0,1,1358.728,3955.831Zm15.012,19.427a15.009,15.009,0,1,0-15.01,14.941A14.962,14.962,0,0,0,1373.74,3975.258Z"
          transform="translate(-18.09 -8.808)"
          fill={color}
        />
        <path
          id="Path_3549"
          data-name="Path 3549"
          d="M1392.016,3963.4a3.809,3.809,0,1,1,3.807-3.844A3.825,3.825,0,0,1,1392.016,3963.4Z"
          transform="translate(-23.499 -8.802)"
          fill="#fff"
        />
        <path
          id="Path_3550"
          data-name="Path 3550"
          d="M1374.271,3975.8a15.009,15.009,0,1,1-15-15.11A14.982,14.982,0,0,1,1374.271,3975.8Z"
          transform="translate(-18.621 -9.347)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
