import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ConditionCardStyled } from './styled';

const ConditionCard = ({ theme_standard, children, footerNode, title }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ConditionCardStyled>
      <div className={customClass}>
        <div className="cdc_header">{title}</div>
        <div className="cdc_body">{children}</div>
        <div className="cdc_footer">{footerNode && footerNode}</div>
      </div>
    </ConditionCardStyled>
  );
};

ConditionCard.propTypes = {};

export default ConditionCard;
