import React from "react";

export default function renderIcon({
  width = 14.883,
  height = 14.252,
  color = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14.883 14.252"
    >
      <path
        id="Path_4157"
        data-name="Path 4157"
        d="M2016.443,1452.769a.99.99,0,0,0-.582-1.791h-3.488a.989.989,0,0,1-.942-.684l-1.078-3.317a.99.99,0,0,0-1.884,0l-1.077,3.317a.99.99,0,0,1-.942.684h-3.488a.99.99,0,0,0-.582,1.791l2.822,2.051a.989.989,0,0,1,.36,1.107l-1.078,3.318a.99.99,0,0,0,1.524,1.107l2.822-2.051a.991.991,0,0,1,1.164,0l2.822,2.051a.99.99,0,0,0,1.524-1.107l-1.078-3.318a.99.99,0,0,1,.36-1.107Z"
        transform="translate(-2001.97 -1446.292)"
        fill={color}
      />
    </svg>
  );
}
