import styled from 'styled-components';

export const PointRedeemContainerStyled = styled.div`
  position: relative;
  padding: 60px 20px 136px 20px;
  .title {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .footer {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 92px;
    margin: auto;
    width: calc(100% - 20px);
    max-width: calc(480px - 28px);
    border-radius: 12px;
    border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    display: flex;
    overflow: hidden;
    .f_col {
      border-right: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      flex-basis: 33.33%;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 6px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      cursor: pointer;
      &.active {
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      &:last-child {
        border-right: none;
      }
    }
  }
`;
