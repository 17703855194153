import styled from 'styled-components';

export const ProductWarranteeFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 74px;
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 145px;
  }
`;
