import React from 'react';

export default function renderIcon({
  width = 43.368,
  height = 41.242,
  color = '#4b1e78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 43.368 41.242"
    >
      <g
        id="Group_242"
        data-name="Group 242"
        transform="translate(-105.213 -850.806)"
      >
        <path
          id="Path_112"
          data-name="Path 112"
          d="M2022.9,1455.656a1.432,1.432,0,0,0-.842-2.59h-5.043a1.43,1.43,0,0,1-1.362-.989l-1.559-4.8a1.432,1.432,0,0,0-2.724,0l-1.558,4.8a1.43,1.43,0,0,1-1.362.989H2003.4a1.432,1.432,0,0,0-.842,2.59l4.08,2.965a1.431,1.431,0,0,1,.52,1.6l-1.559,4.8a1.432,1.432,0,0,0,2.2,1.6l4.08-2.965a1.434,1.434,0,0,1,1.683,0l4.08,2.965a1.432,1.432,0,0,0,2.2-1.6l-1.559-4.8a1.431,1.431,0,0,1,.52-1.6Z"
          transform="translate(-1886.341 -585.498)"
          fill={color}
        />
        <path
          id="Union_2"
          data-name="Union 2"
          d="M1497.3,1185.738a20.073,20.073,0,0,1-15.208-17.159c-.027-.233-.039-.467-.053-.7-.068-1.113-.019-2.241,0-3.36a2.154,2.154,0,0,1,2.777-.022c0,.058,0,.115,0,.175a20.848,20.848,0,0,0,.046,3.189,16.827,16.827,0,0,0,6.195,11.648,17.293,17.293,0,0,0,25.286-3.584c.179-.268.31-.571.615-1.139l-2.62.931a1.341,1.341,0,0,1-1.948-.85,1.322,1.322,0,0,1,.985-1.781q2.875-1.063,5.76-2.1a1.4,1.4,0,0,1,2,.985c.667,1.886,1.336,3.773,1.968,5.672.284.856.179,1.679-.793,2-1.018.333-1.6-.244-1.9-1.184-.221-.676-.472-1.341-.8-2.258-.422.6-.7,1-.977,1.4a20.2,20.2,0,0,1-16.578,8.724A19.733,19.733,0,0,1,1497.3,1185.738Zm-13.865-17.346a2.173,2.173,0,0,0,1.382-.5A2.175,2.175,0,0,1,1483.432,1168.393Z"
          transform="translate(-1375.152 -294.77)"
          fill={color}
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Union_1"
          data-name="Union 1"
          d="M1520.526,1185.693c0-.058,0-.114,0-.176a20.9,20.9,0,0,0-.069-3.189q.045-.039.091-.075-.047.036-.091.075a16.826,16.826,0,0,0-6.279-11.6,17.294,17.294,0,0,0-25.26,3.77c-.177.27-.305.572-.6,1.144l2.612-.95a1.342,1.342,0,0,1,1.955.836,1.323,1.323,0,0,1-.972,1.789q-2.867,1.081-5.744,2.142a1.4,1.4,0,0,1-2.01-.971c-.681-1.881-1.364-3.762-2.011-5.656-.292-.853-.193-1.678.778-2,1.015-.34,1.6.232,1.913,1.17.225.674.481,1.338.813,2.252.418-.607.694-1,.967-1.4a20.107,20.107,0,0,1,36.6,8.746c.03.232.043.466.058.7a2.192,2.192,0,0,0-1.094-.484,2.2,2.2,0,0,1,1.094.485c.077,1.112.035,2.239.03,3.359a2.189,2.189,0,0,1-1.4.529h-.011A2.182,2.182,0,0,1,1520.526,1185.693Z"
          transform="translate(-1376.286 -312.675)"
          fill={color}
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
