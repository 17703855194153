import React from "react";
import { PointRedeemDetailContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxWallet } from "store/reducers/walletReducer";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { Buttons, Cards, Displays, Modals } from "components";

class PointRedeemDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { router } = this.props;
    let res = await userService.GET_REWARD_DETAIL(router.params?.id);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
      isShowModal: false,
    });
  };

  handleClikRedeem = () => {
    const { data } = this.state;
    this.setState({
      renderModal: (
        <Displays.AlertAction
          label="ยืนยันการแลก"
          isBooking={data.type === "1" ? true : false}
          onCancel={this.handleCloseModal}
          onBooking={async () => {
            const body = document.body;
            body.style.overflow = "auto";
            this.setState({
              isBgLoading: true,
            });
            let params = {
              business_unit_id: data.business_unit_ref_id,
              reward_id: data._id,
              is_ecopon: data.type === "1",
            };
            let res = await userService.POST_REWARD_REDEEM(params);
            if (res && res.status === 200) {
              let resWallet = await userService.GET_MY_WALLET();
              if (resWallet) {
                this.props.setReduxWallet(resWallet.data);
                this.redeem(data.type, res.data._id, false);
              }
            } else {
              this.handleCloseModal();
              this.setState({
                isBgLoading: false,
              });
              toast.error("แลกไม่สำเร็จ กรุณาตรวจสอบเงื่อนไข");
            }
          }}
          onAccept={async () => {
            const body = document.body;
            body.style.overflow = "auto";
            this.setState({
              isBgLoading: true,
            });
            let params = {
              business_unit_id: data.business_unit_ref_id,
              reward_id: data._id,
              is_ecopon: data.type === "1",
            };
            if (data.type === "1") {
              params.is_used = true;
            }
            let res = await userService.POST_REWARD_REDEEM(params);
            if (res && res.status === 200) {
              let resWallet = await userService.GET_MY_WALLET();
              if (resWallet) {
                this.props.setReduxWallet(resWallet.data);
                this.redeem(data.type, res.data._id, true);
              }
            } else {
              this.handleCloseModal();
              this.setState({
                isBgLoading: false,
              });
              toast.error("แลกไม่สำเร็จ กรุณาตรวจสอบเงื่อนไข");
            }
          }}
        />
      ),
      isShowModal: true,
    });
  };

  redeem = (key, rewardHistoryID, isUsed) => {
    switch (key) {
      case "1":
        if (isUsed) {
          this.props.router.navigate(
            `${ROUTE_PATH.POINT_REDEEM}/detail/${rewardHistoryID}/qrcode`
          );
        } else {
          this.props.router.navigate(`${ROUTE_PATH.POINT_REDEEM_E_COUPON}`);
        }
        break;
      case "2":
      case "3":
        this.props.router.navigate(
          `${ROUTE_PATH.POINT_REDEEM}/detail/${rewardHistoryID}/shipping`
        );
        break;
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;
    const { isLoading, isBgLoading, isShowModal, data, renderModal } =
      this.state;
    return (
      <PointRedeemDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="reward_wrap">
              <Cards.Reward src={data.img} description={data.description} />
            </div>
            <div className="btn_wrap">
              <Buttons.BgStandard
                label={t("Redeem", { ns: "common" })}
                height="50px"
                onClick={this.handleClikRedeem}
              />
            </div>
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </PointRedeemDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(withRouter(PointRedeemDetailContainer)));
