import { useState } from 'react';
import { BtnEmailUpdateWidgetStyled } from './styled';
import { useDispatch } from 'react-redux';
import {
  setReduxAuthenEmail,
  setReduxAuthenFirstname,
  setReduxAuthenLastname,
  setReduxAuthenPhoneNumber,
  setReduxAuthenOrganizationPhoneNumber,
  setReduxAuthenOrganizationProvince,
  setReduxAuthenOrganizationEmail,
} from 'store/reducers/authenReducer';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Displays, Forms, Modals } from 'components';

const BtnEmailUpdateWidget = ({ type = 'email', memberType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);

  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_isShowModal, _setIsShowModal] = useState(false);

  const _handleClickScan = () => {
    _setIsShowModal(true);
  };

  const _handleCloseModal = () => {
    _setIsBgLoading(false);
    _setIsShowModal(false);
  };

  const _handleSubmit = async (values) => {
    _setIsBgLoading(true);
    let params = {
      ...values,
    };
    if(values.province){
      params.province = values.province.value
    }
    if (memberType === '2') {
      let res = await userService.PUT_MY_ORGANIZE_UPDATE_DETAIL(params);
      if (res && res.status === 200) {
        switch (type) {
          case 'email':
            dispatch(setReduxAuthenOrganizationEmail(res.data.email));
            break;
          case 'phone_number':
            dispatch(
              setReduxAuthenOrganizationPhoneNumber(res.data.phone_number)
            );
            break;
          case 'province':
            dispatch(setReduxAuthenOrganizationProvince(res.data.province));
            break;
          default:
            break;
        }
        _handleCloseModal();
        toast.success('อัพเดตสำเร็จ');
      } else {
        _handleCloseModal();
        toast.error('ไม่สามารถอัพเดตได้');
      }
    } else {
      let res = await userService.PUT_EMAIL_UPDATE_DETAIL(params);
      if (res && res.status === 200) {
        switch (type) {
          case 'first_name':
            dispatch(setReduxAuthenFirstname(res.data.first_name));
            break;
          case 'last_name':
            dispatch(setReduxAuthenLastname(res.data.last_name));
            break;
          case 'phone_number':
            dispatch(setReduxAuthenPhoneNumber(res.data.phone_number));
            break;
          case 'email':
            dispatch(setReduxAuthenEmail(res.data.email));
            break;
          default:
            break;
        }
        _handleCloseModal();
        toast.success('อัพเดตสำเร็จ');
      } else {
        _handleCloseModal();
        toast.error('ไม่สามารถอัพเดตได้');
      }
    }
  };

  const _renderType = () => {
    switch (type) {
      case 'first_name':
        return <Forms.FirstnameForm onSubmit={_handleSubmit} />;
      case 'last_name':
        return <Forms.LastnameForm onSubmit={_handleSubmit} />;
      case 'phone_number':
        return <Forms.PhoneForm onSubmit={_handleSubmit} />;
      case 'email':
        return <Forms.EmailForm onSubmit={_handleSubmit} />;
      case 'province':
        return <Forms.ProvinceForm onSubmit={_handleSubmit} />;
      default:
        break;
    }
  };

  return (
    <>
      <Displays.BGLoading visible={_isBgLoading} />
      <BtnEmailUpdateWidgetStyled onClick={_handleClickScan}>
        {t('Edit')}
      </BtnEmailUpdateWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_isShowModal}
        onCloseModal={_handleCloseModal}
      >
        {_renderType()}
      </Modals.BodyEmpty>
    </>
  );
};

export default BtnEmailUpdateWidget;
