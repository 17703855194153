import styled from 'styled-components';

export const ProductWarranteeStyled = styled.div`
  .product_warrantee_container {
    .pw_row {
      display: flex;
      &.block {
        display: block;
      }
      .label {
        color: ${({ theme }) => theme.COLORS.PURPLE_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
      .value {
        margin-left: 4px;
        color: ${({ theme }) => theme.COLORS.PURPLE_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
        &.flex {
          display: flex;
          column-gap: 20px;
        }
      }
      .pw_status {
        border-radius: 10px;
        min-width: 82px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ statusBg }) => statusBg};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S10};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
    .btn_edit_pw {
      margin-top: 4px;
      border-radius: 12px;
      width: fit-content;
      padding: 0 12px;
      background: ${({ theme }) => theme.COLORS.RED_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      cursor: pointer;
    }
  }

  .theme_standard {
  }
`;
