import styled from "styled-components";

export const TextFieldStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  input {
    border-radius: 9px;
    border: 1.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    padding: 0px 15px;
    width: calc(100% - 30px);
    height: 42px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: ${({ disabled }) => (!disabled ? "auto" : "not-allowed")};
    &::placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
  input:focus {
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
