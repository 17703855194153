import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { NewStyled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';

const New = ({ theme_standard, src, title, description }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <NewStyled>
      <div className={customClass}>
        <div className="new_container">
          <div className="n_left_col">
            <img
              className="new_img"
              alt="news yamaha"
              src={src ? IMG_PATH + src : '/assets/images/mockups/new-1.jpg'}
            />
          </div>
          <div className="n_right_col">
            <div className="n_header">{title}</div>
            <div className="n_description">{description}</div>
          </div>
        </div>
      </div>
    </NewStyled>
  );
};

New.propTypes = {};

export default New;
