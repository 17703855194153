import React, { useEffect } from "react";
import { ProductWarranteeNoLoginFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import address from "utils/jsons/thailandAddress/address.json";
import { GENDER_TYPE } from "utils/functions/customer";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ProductWarranteeNoLoginForm = ({ onSubmit, initialValues }) => {
  const schema = yup.object().shape({
    first_name: yup.string().required("Firstname is required"),
    last_name: yup.string().required("Lastname is required"),
    gender: yup.object().required("Gender is required"),
    province: yup.object().required("Province is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Invalid phone number"),
    email: yup.string().required("Email is required"),
    birthday: yup.string().required("Birthday is required"),
    image_file: yup
      .array()
      .required("Receipt is required")
      .min(1, "Receipt is required"),
    // is_accept_user_info: yup.bool().oneOf([true], "Accept must be checked"),
    // is_accept_term_condition: yup
    //   .bool()
    //   .oneOf([true], "Accept must be checked"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      let params = {
        ...initialValues,
        is_accept_user_info: true,
        is_accept_term_condition: true,
      };
      if (initialValues.province) {
        params.province = {
          label: initialValues.province,
          value: initialValues.province,
        };
      }
      if (initialValues.gender) {
        let genderTemp = GENDER_TYPE.find(
          (e) => e.value === initialValues.gender
        );
        params.gender = {
          label: genderTemp.label,
          value: genderTemp.value,
        };
      }

      // if (initialValues.slip_img) {
      //   params.image_file = [{ url: initialValues.slip_img }];
      // }
      reset(params);
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation(["common", "register"]);

  const renderProvinceOptions = () => {
    return address.reduce((result, data, i) => {
      if (i === 0) {
        result.push({ label: data.province, value: data.province });
      } else {
        let find = result.find((e) => e.value === data.province);
        if (!find) {
          result.push({ label: data.province, value: data.province });
        }
      }
      return result;
    }, []);
  };

  const disabled = !(
    initialValues &&
    (initialValues.status === "1" || initialValues.status === "5")
  );
  return (
    <ProductWarranteeNoLoginFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={`* ${t("Name", { ns: "register" })}`}
                errors={errors.first_name?.message}
                disabled={disabled}
              />
            )}
            name="first_name"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={`* ${t("Surname", { ns: "register" })}`}
                errors={errors.last_name?.message}
                disabled={disabled}
              />
            )}
            name="last_name"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                options={GENDER_TYPE}
                placeholder={`* ${t("Gender", { ns: "register" })}`}
                errors={errors.gender?.message}
              />
            )}
            name="gender"
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                options={renderProvinceOptions()}
                placeholder={`* ${t("Province", { ns: "register" })}`}
                errors={errors.province?.message}
                disabled={disabled}
              />
            )}
            name="province"
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={`* ${t("Mobile number", { ns: "register" })}`}
                errors={errors.phone_number?.message}
                disabled={disabled}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DateTime
                {...field}
                isShowMonthDropdown
                isShowYearDropdown
                maxDate={new Date()}
                placeholder={`* ${t("Date of birth", { ns: "register" })}`}
                errors={errors.birthday?.message}
                disabled={disabled}
              />
            )}
            name="birthday"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={`* ${t("Email", { ns: "register" })}`}
                errors={errors.email?.message}
                disabled={disabled}
              />
            )}
            name="email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Upload
                {...field}
                acceptType="cover"
                // placeholder={t('test')}
                maxMBFileSizeWarning={8}
                errors={errors.image_file?.message}
                disabled={disabled}
              />
            )}
            name="image_file"
            defaultValue={[]}
          />
        </div>
        {/* <div className="input_wrap mb_12">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label={
                  <span onClick={() => handleClickShowModal("user_info")}>
                    ยอมรับนโยบายเรื่องความเป็นส่วนตัว
                  </span>
                }
                errors={errors.is_accept_user_info?.message}
                disabled={disabled}
              />
            )}
            name="is_accept_user_info"
            defaultValue={false}
          />
        </div>
        <div className="input_wrap mb_82">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.InputCheckBox
                {...field}
                label={
                  <span onClick={() => handleClickShowModal("term_condition")}>
                    ยอมรับเงื่อนไขการลงทะเบียนและการรับประกันสินค้า
                  </span>
                }
                errors={errors.is_accept_term_condition?.message}
                disabled={disabled}
              />
            )}
            name="is_accept_term_condition"
            defaultValue={false}
          />
        </div> */}
        {initialValues &&
          (initialValues.status === "1" || initialValues.status === "5") && (
            <div className="btn_submit_wrap">
              <Buttons.BgStandard label="ตกลง" type="submit" />
            </div>
          )}
      </form>
    </ProductWarranteeNoLoginFormStyled>
  );
};

ProductWarranteeNoLoginForm.propTypes = {};

export default ProductWarranteeNoLoginForm;
