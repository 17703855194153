import React from "react";
import { ProductWarranteeNotLoginSuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { BtnLineWidget } from "widgets";
import { Displays, Headers } from "components";

class ProductWarranteeNotLoginSuccessContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { router } = this.props;
    localStorage.clear();
    if (!router.params?.id) {
      this.router.push("/");
    } else {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { router } = this.props;
    let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(
      router.params?.id
    );
    if (res && res.status === 200) {
      switch (res.data.status) {
        case "1":
          this.handleRenderStatus("error");
          break;
        case "2":
        case "3":
        case "4":
        case "5":
          this.handleRenderStatus("success");
          break;
        default:
          break;
      }
    } else {
      this.handleRenderStatus("fail");
    }
  };

  handleRenderStatus = (key) => {
    const { router, t } = this.props;
    switch (key) {
      case "success":
        if (router.params?.status !== "success") {
          this.props.router.navigate(
            `${ROUTE_PATH.PRODUCT_WARRANTEE_NOT_LOGIN}/${router.params?.id}/success`
          );
        }
        this.setState({
          renderStatus: (
            <>
              <Displays.AlertLabel
                label={[
                  t("Warranty information", { ns: "common" }),
                  t("Already registered", { ns: "common" }),
                ]}
              />
              <div className="recomment_label">
                <div>
                  {t(
                    "You can add Line Official to check the E-Warranty status via Siam Music Members.",
                    { ns: "common" }
                  )}
                </div>
              </div>
            </>
          ),
          isLoading: false,
        });
        break;
      case "fail":
        if (router.params?.status !== "fail") {
          this.props.router.navigate(
            `${ROUTE_PATH.PRODUCT_WARRANTEE_NOT_LOGIN}/${router.params?.id}/fail`
          );
        }
        this.setState({
          renderStatus: (
            <>
              <Displays.AlertLabel
                label={[
                  t("Your code", { ns: "common" }),
                  t("incorrect", { ns: "common" }),
                ]}
              />
              <div className="recomment_label">
                <div>
                  {t("If in doubt Please contact the staff.", { ns: "common" })}
                </div>
              </div>
            </>
          ),
          isLoading: false,
        });
        break;
      case "error":
        if (router.params?.status !== "error") {
          this.props.router.navigate(
            `${ROUTE_PATH.PRODUCT_WARRANTEE_NOT_LOGIN}/${router.params?.id}/error`
          );
        }
        this.setState({
          renderStatus: (
            <>
              <Displays.AlertLabel
                label={[
                  t("Your registration method is incorrect.", { ns: "common" }),
                  t("Please restart.", { ns: "common" }),
                ]}
              />
              <div className="recomment_label">
                <div>
                  {t("If in doubt Please contact the staff.", { ns: "common" })}
                </div>
              </div>
            </>
          ),
          isLoading: false,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { isLoading, renderStatus } = this.state;
    return (
      <ProductWarranteeNotLoginSuccessContainerStyled>
        <Headers.Logo />
        {isLoading ? <Displays.Loading /> : renderStatus}
        <div className="btn_wrap">
          <BtnLineWidget />
        </div>
      </ProductWarranteeNotLoginSuccessContainerStyled>
    );
  }
}

export default withTranslation(["common"])(
  withRouter(ProductWarranteeNotLoginSuccessContainer)
);
