import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { QuestionAnsItemStyled } from "./styled";

const QuestionAnsItem = ({ theme_standard, question, answer, no }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <QuestionAnsItemStyled>
      <div className={customClass}>
        <div className="question_ans_item_container">
          <div
            className="qai_question"
            dangerouslySetInnerHTML={{ __html: question }}
          >
          </div>
          <div className="qai_ans">{answer}</div>
        </div>
      </div>
    </QuestionAnsItemStyled>
  );
};

QuestionAnsItem.propTypes = {};

export default QuestionAnsItem;
