import styled from 'styled-components';

export const HistoryContainerStyled = styled.div`
  padding: 60px 20px 104px 20px;
  .product_item_wrap {
    padding: 0 3px;
  }
  .line_between {
    margin: 15px 0 12px 0px;
    height: 1px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
  }
`;
