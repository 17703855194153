import React from "react";
import { NewContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { ROUTE_PATH } from "utils/constants/routePath";
import { MyPointWidget } from "widgets";
import { Cards, Displays, Headers, Icons } from "components";

class NewContainerNewContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_NEW_LIST(`?is_active=true`);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handlClickItem = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.NEW}/${e._id}`);
  };

  render() {
    const { t } = this.props;
    const { isLoading, data } = this.state;
    return (
      <NewContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          theme_head_only
          icon={<Icons.MenuNews color="#fff" />}
          topLabel={t("News", { ns: "news" })}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : !data || data.docs.length === 0 ? (
          <Displays.LabelEmpty label={t("No Data", { ns: "common" })} />
        ) : (
          <div className="fav_row">
            {data.docs.map((e, i) => (
              <div
                key={i}
                className="new_item_wrap"
                onClick={() => this.handlClickItem(e)}
              >
                <Cards.New
                  src={e.front_img}
                  title={e.title}
                  description={e.description}
                />
              </div>
            ))}
          </div>
        )}
      </NewContainerStyled>
    );
  }
}

export default withTranslation(["common", "news"])(
  withRouter(NewContainerNewContainer)
);
