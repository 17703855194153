import styled from 'styled-components';

export const AlertActionStyled = styled.div`
  width: 100%;
  max-width: 354px;
  .alert_action_container {
    border-radius: 13px;
    padding: 51px 27px 33px 27px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .aa_logo {
      margin: auto;
      margin-bottom: 31px;
      width: 200px;
      height: auto;
    }
    .aa_header {
      margin-bottom: 47px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S27};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .aa_action_row {
      display: flex;
      column-gap: ${({ isThreeAction }) =>
        isThreeAction ? '8px' : '27px'};
      .aa_col {
        flex: 1;
      }
    }
  }

  .theme_standard {
  }
`;
