import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { ActivityFormQuestionFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { IMG_PATH } from "utils/constants/imgPath";
import { Buttons, Inputs } from "components";

const ActivityFormQuestionForm = forwardRef(
  ({ formDisabled, initialValue, isEdit, onBack, onSubmit }, ref) => {
    const schema = yup.object().shape({
      question_list: yup.array().of(
        yup.object().shape({
          answer: yup
            .mixed()
            .test("is-string-or-array", "required", (value) => {
              return typeof value === "string" || Array.isArray(value);
            }),
          // answer: yup.mixed().oneOf([yup.string(), yup.array()]),
          //  yup.string().required("Answer is required"),
        })
      ),
    });

    const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
      watch,
      setError,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const {
      fields,
      // append, remove
    } = useFieldArray({
      control,
      name: "question_list",
    });

    const watchQuestionList = watch("question_list");

    const { t } = useTranslation(["common", "form"]);

    useEffect(() => {
      if (initialValue) {
        reset({
          ...initialValue,
        });
      }
    }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit(_handleSubmit)();
        // handleSubmit();
      },
    }));

    const _handleSubmit = (values) => {
      let isErr = false;
      if (values.question_list && values.question_list.length > 0) {
        values.question_list.forEach((e, i) => {
          if (
            (e.answer === "Other" ||
              e.answer === "อื่นๆ" ||
              e.answer === "อื่นๆ:") &&
            !e.other
          ) {
            isErr = true;
            setError(`question_list[${i}].other`, {
              type: "custom",
              message: "require",
            });
          }
        });
        if (!isErr) {
          onSubmit(values);
        }
      }
    };

    return (
      <ActivityFormQuestionFormStyled>
        {initialValue && (
          <form onSubmit={handleSubmit(_handleSubmit)}>
            {fields.map((item, index) => (
              <div key={item.id} className="input_wrap">
                {item.question && (
                  <div
                    className="f_question_label"
                    dangerouslySetInnerHTML={{ __html: item.question }}
                  />
                )}
                {item.img_path && (
                  <img
                    className="f_question_img"
                    src={IMG_PATH + item.img_path}
                    alt="yamaha activity question"
                  />
                )}
                {item.type === "1" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Radio
                        {...field}
                        theme_question
                        options={
                          item.answer_list
                            ? item.answer_list.map((e) => ({
                                label: e,
                                value: e,
                              }))
                            : []
                        }
                        disabled={
                          (initialValue && initialValue.isAssessment) ||
                          formDisabled
                        }
                        disabledType="no_choice"
                        errors={
                          errors?.question_list &&
                          errors?.question_list[index]?.answer?.message
                        }
                      />
                    )}
                    name={`question_list.${index}.answer`}
                  />
                )}
                {item.type === "2" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextArea
                        {...field}
                        theme_question
                        disabled={
                          (initialValue && initialValue.isAssessment) ||
                          formDisabled
                        }
                        errors={
                          errors?.question_list &&
                          errors?.question_list[index]?.answer?.message
                        }
                      />
                    )}
                    name={`question_list.${index}.answer`}
                  />
                )}
                {item.type === "3" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Upload
                        {...field}
                        theme_question
                        disabled={formDisabled}
                        acceptType="img-pdf"
                        placeholder={t("Upload image/PDF File", { ns: "common" })}
                        maxMBFileSizeWarning={8}
                        errors={
                          errors?.question_list &&
                          errors?.question_list[index]?.answer?.message
                        }
                        // disabled={disabled}
                      />
                    )}
                    name={`question_list.${index}.answer`}
                    // defaultValue={""}
                  />
                )}
                {((watchQuestionList &&
                  watchQuestionList[index]?.answer === "Other") ||
                  watchQuestionList[index]?.answer === "อื่นๆ" ||
                  watchQuestionList[index]?.answer === "อื่นๆ:") && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        disabled={formDisabled}
                        placeholder={"Other"}
                        errors={
                          errors?.question_list &&
                          errors?.question_list[index]?.other?.message
                        }
                      />
                    )}
                    name={`question_list.${index}.other`}
                    defaultValue=""
                  />
                )}
              </div>
            ))}
            {isEdit && (
              <div className="action_row">
                <div className="btn_col">
                  <Buttons.BgStandard
                    theme_cancel
                    label={t("Back", { ns: "common" })}
                    onClick={onBack}
                  />
                </div>
                <div className="btn_col">
                  <Buttons.BgStandard
                    type="submit"
                    label={t("Edit", { ns: "common" })}
                  />
                </div>
              </div>
            )}
          </form>
        )}
      </ActivityFormQuestionFormStyled>
    );
  }
);

ActivityFormQuestionForm.propTypes = {};

export default ActivityFormQuestionForm;
