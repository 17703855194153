import styled from 'styled-components';

export const NavigationMenuItemStyled = styled.div`
  .natigation_menu_item_container {
    position: relative;
    border-radius: 23px;
    padding: 0 17px;
    height: 77px;
    display: flex;
    align-items: center;
    column-gap: 19px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    cursor: pointer;
    .nmi_left_col {
      flex-basis: 38px;
      flex-shrink: 0;
    }
    .nmi_right_col {
      flex: 1;
      .rc_label {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
    .surver_status {
      border-radius: 12px;
      position: absolute;
      right: 13px;
      bottom: 14px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S11};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
  }

  .theme_standard {
  }
`;
