import React from "react";
import { ActQuestionPreviewContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { Buttons, Cards, Displays } from "components";

class ActQuestionPreviewContainer extends React.Component {
  render() {
    const { data, isBgLoading, t, onBack, onConfirm } = this.props;
    return (
      <ActQuestionPreviewContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="aqp_title">ยืนยันคำตอบ</div>
        <div className="answer_row">
          {data &&
            data.map((e, i) => (
              <div key={i} className="question_wrap">
                <Cards.QuestionAnsItem
                  no={i + 1}
                  question={e.question}
                  answer={e.type === "3" ? "อัพโหลดสำเร็จ" : e.answer}
                />
              </div>
            ))}
        </div>
        <div className="action_row">
          <div className="btn_col">
            <Buttons.BgStandard
              label={t("Edit data", { ns: "common" })}
              onClick={onBack}
            />
          </div>
          <div className="btn_col">
            <Buttons.BgStandard
              label={t("Confirm", { ns: "common" })}
              onClick={onConfirm}
            />
          </div>
        </div>
      </ActQuestionPreviewContainerStyled>
    );
  }
}

export default withTranslation(["profile", "common"])(
  withRouter(ActQuestionPreviewContainer)
);
