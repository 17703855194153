import React from 'react';
import { ProvinceFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import address from 'utils/jsons/thailandAddress/address.json';
import { useTranslation } from 'react-i18next';
import { Buttons, Inputs } from 'components';

const ProvinceForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    province: yup.object().required('Province is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(['common', 'register']);

  const renderProvinceOptions = () => {
    return address.reduce((result, data, i) => {
      if (i === 0) {
        result.push({ label: data.province, value: data.province });
      } else {
        let find = result.find((e) => e.value === data.province);
        if (!find) {
          result.push({ label: data.province, value: data.province });
        }
      }
      return result;
    }, []);
  };

  return (
    <ProvinceFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                options={renderProvinceOptions()}
                placeholder={`* ${t('Province', { ns: 'register' })}`}
                errors={errors.province?.message}
              />
            )}
            name="province"
          />
        </div>
        <Buttons.BgStandard type="submit" label={t('Edit', { ns: 'common' })} />
      </form>
    </ProvinceFormStyled>
  );
};

ProvinceForm.propTypes = {};

export default ProvinceForm;
