import React, { useState, forwardRef, useEffect } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DateTimeStyled } from "./styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTime = ({
  theme_standard,
  errors,
  onChange,
  label,
  isShowMonthDropdown,
  isShowYearDropdown,
  placeholder,
  value,
  disabled,
  maxDate,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (value && value !== startDate) {
      setStartDate(new Date(value));
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type="button"
      className="date_container"
      onClick={onClick}
      ref={ref}
    >
      {value ? (
        value
      ) : (
        <span className="date_time_placeholder">{placeholder}</span>
      )}
    </button>
  ));

  const _handleChange = (e) => {
    setStartDate(e);
    onChange(e);
  };

  return (
    <DateTimeStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          placeholder={placeholder}
          onChange={(date) => _handleChange(date)}
          customInput={<ExampleCustomInput />}
          showMonthDropdown={isShowMonthDropdown}
          showYearDropdown={isShowYearDropdown}
          dropdownMode="select"
          disabled={disabled}
          maxDate={maxDate}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </DateTimeStyled>
  );
};

DateTime.propTypes = {};

export default DateTime;
