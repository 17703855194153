import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InputSearchStyled } from './styled';
import { Buttons } from 'components';

const InputSearch = ({ theme_standard, label, errors, onSearch, ...props }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_input, _setInput] = useState();

  const _handleChange = (e) => {
    _setInput(e.target.value);
  };

  const _handleSearch = () => {
    onSearch && onSearch(_input);
  };

  return (
    <InputSearchStyled>
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <div className="input_search_row">
          <input {...props} onChange={_handleChange} />
          <div className="ins_btn_col">
            <Buttons.BgStandard
              theme_search_btn
              label={'ค้นหา'}
              onClick={_handleSearch}
            />
          </div>
        </div>
        {errors && <div className="errors">{errors}</div>}
      </div>
    </InputSearchStyled>
  );
};

InputSearch.propTypes = {};

export default InputSearch;
