import React from "react";
import { ProductWarranteeSuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { withTranslation } from "react-i18next";
import { MyPointWidget } from "widgets";
import { Displays, Headers, Icons } from "components";

class ProductWarranteeSuccessContainer extends React.Component {
  handleClickClose = () => {
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTEE}`);
  };

  render() {
    const { t } = this.props;
    return (
      <ProductWarranteeSuccessContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"50px"}
          icon={<Icons.SubMenuProductWarrantee color="#fff" />}
          topLabel={"Product Warranty"}
          bottomLabel={"รับประกันสินค้า"}
        />
        <Displays.AlertLabel
          label={[
            t("Warranty information", { ns: "common" }),
            t("Already registered", { ns: "common" }),
          ]}
          actionLabel={t("Close", { ns: "common" })}
          onClickAction={this.handleClickClose}
        />
      </ProductWarranteeSuccessContainerStyled>
    );
  }
}

export default withTranslation(["common"])(
  withRouter(ProductWarranteeSuccessContainer)
);
