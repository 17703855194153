import React from 'react';
import { connect } from 'react-redux';
import { HistoryContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiServices';
import { withTranslation } from 'react-i18next';
import { MemberSwitchTypeWidget, MyPointWidget } from 'widgets';
import { Cards, Displays, Headers, Icons } from 'components';

class HistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData(`?member_type=${this.props.authenRedux.member_type_active}`);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.authenRedux &&
      this.props.authenRedux !== prevProps.authenRedux
    ) {
      this.fetchData(
        `?member_type=${this.props.authenRedux.member_type_active}`
      );
    }
  }

  fetchData = async (queryString) => {
    let res = await userService.GET_MY_PRODUCT_WARRANTEE(queryString);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handleEditProductWarranty = (e) => {
    this.props.router.navigate(
      `/system/home/product-warrantee/${e.serial_no}-${e.item_no}`
    );
  };

  render() {
    const { t } = this.props;
    const { isLoading, data } = this.state;
    return (
      <HistoryContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          icon={<Icons.MenuHistory color="#fff" />}
          topLabel={[
            t('Product Warranty', { ns: 'history' }),
            t('History', { ns: 'history' }),
          ]}
        />
        <MemberSwitchTypeWidget />
        {isLoading ? (
          <Displays.Loading />
        ) : !data || data.length === 0 ? (
          <Displays.LabelEmpty label={t('No Data', { ns: 'common' })} />
        ) : (
          data.map((e, i) => (
            <div key={i}>
              <div className="product_item_wrap">
                <Cards.ProductWarrantee
                  isEdit
                  description={e.description}
                  descriptionLine2={e.description_line_2}
                  sn={e.serial_no}
                  warrantyPeriod={e.warranty_period}
                  warrantyUnit={e.warranty_unit}
                  warrantyDescription={e.warranty_description_th}
                  rejectedReason={e.rejected_reason}
                  status={e.status}
                  approvedWarrantyDate={e.approved_warranty_date}
                  extensionPeriod={e.extension_period}
                  extensionUnit={e.extension_unit}
                  onEdit={() => this.handleEditProductWarranty(e)}
                />
              </div>
              <div className="line_between" />
            </div>
          ))
        )}
      </HistoryContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['common', 'history'])(withRouter(HistoryContainer)));
