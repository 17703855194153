import React from "react";

export default function renderIcon({ width = 17, height = 17, isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
    >
      <g id="Group_386" data-name="Group 386" transform="translate(-41 -259)">
        {isActive && (
          <circle
            id="Ellipse_34"
            data-name="Ellipse 34"
            cx="6.5"
            cy="6.5"
            r="6.5"
            transform="translate(43 261)"
            fill="#4b1e78"
          />
        )}
        <g
          id="Ellipse_35"
          data-name="Ellipse 35"
          transform="translate(41 259)"
          fill="none"
          stroke="#4b1e78"
          strokeWidth="1"
        >
          <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
          <circle cx="8.5" cy="8.5" r="8" fill="none" />
        </g>
      </g>
    </svg>
  );
}
