export const GENDER_TYPE = [
  {
    label: "ชาย",
    labelEN: "Male",
    value: "1",
  },
  {
    label: "หญิง",
    labelEN: "Female",
    value: "2",
  },
  // {
  //   label: "LGBTQIA+",
  //   labelEN: "LGBTQIA",
  //   value: "3",
  // },
  // {
  //   label: "อื่นๆ",
  //   labelEN: "Others",
  //   value: "4",
  // },
  {
    label: "ไม่ต้องการระบุ",
    labelEN: "Not Specific",
    value: "5",
  },
];

export const RENDER_GENDER_TYPE = (key) => {
  switch (key) {
    case "1":
      return {
        labelTh: "ชาย",
        labelEn: "Male",
      };
    case "2":
      return {
        labelTh: "หญิง",
        labelEn: "Female",
      };
    case "3":
      return {
        labelTh: "LGBTQIA+",
        labelEn: "LGBTQIA+",
      };
    case "4":
      return {
        labelTh: "อื่นๆ",
        labelEn: "Others",
      };
    case "5":
      return {
        labelTh: "ไม่ต้องการระบุ",
        labelEn: "Not Specific",
      };
    default:
      return {
        labelTh: "ไม่ต้องการระบุ",
        labelEn: "Not Specific",
      };
  }
};
