import React from "react";

export default function renderIcon({
  width = 32,
  height = 32,
  color = "#471E77",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.11 44.74L48.22 41.27V27.84C48.22 19.59 43.28 12.69 34.65 10.86V9.03C34.65 6.8 32.63 5 30.13 5C27.63 5 25.61 6.8 25.61 9.03V10.86C16.96 12.69 12.04 19.57 12.04 27.84V41.27L8.15 44.74C6.25 46.43 7.58 49.33 10.26 49.33H49.97C52.68 49.33 54.01 46.43 52.11 44.74ZM42.19 43.96H18.07V27.84C18.07 21.18 22.62 15.75 30.13 15.75C37.64 15.75 42.19 21.18 42.19 27.84V43.96ZM30.13 57.39C33.45 57.39 36.16 54.97 36.16 52.02H24.1C24.1 54.98 26.78 57.39 30.13 57.39Z"
        fill="#471E77"
      />
      <path
        d="M43 34C51.8366 34 59 26.8366 59 18C59 9.16344 51.8366 2 43 2C34.1634 2 27 9.16344 27 18C27 26.8366 34.1634 34 43 34Z"
        fill="white"
      />
      <path
        d="M43 32C50.732 32 57 25.732 57 18C57 10.268 50.732 4 43 4C35.268 4 29 10.268 29 18C29 25.732 35.268 32 43 32Z"
        fill={color}
      />
    </svg>
  );
}
