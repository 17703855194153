import styled from 'styled-components';

export const ActivitiesItemStyled = styled.div`
  .activities_container {
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    display: flex;
    .act_left_col {
      flex-basis: 50%;
      flex-shrink: 0;
      max-height: 100px;
      .act_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .act_right_col {
      flex-basis: calc(50% - 24px);
      flex-shrink: 0;
      padding: 15px 12px 9px 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .act_name_label {
        margin-bottom: 9px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 19px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      }
      .act_btn_wrap {
        border-radius: 11px;
        padding: 0px 12px 0px 12px;
        width: 109px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        cursor: pointer;
      }
    }
  }

  .theme_standard {
  }
`;
