import styled from "styled-components";

export const PDPAFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 12px;
  }
  .papd_item {
    margin-bottom: 12px;
    border-radius: 3px;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    &.mb_22 {
      margin-bottom: 22px;
    }
    .pdpa_title {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
    }
    .pdpa_sub_title {
      margin-bottom: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: pre-wrap;
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
    }
    .pdpa_more {
      margin-bottom: 12px;

      text-decoration: underline;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S13};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      cursor: pointer;
    }
  }
`;
