import React from "react";
import { ProductwarranteeNotLoginContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import {
  TermConditionContainer,
  UserInfoConditionContainer,
} from "containers/RegisterContainer/Modals";
import { userService } from "apiServices";
import moment from "moment";
import { toast } from "react-toastify";
import PDPAContainer from "containers/PDPAContainer";
import { withTranslation } from "react-i18next";
import { AlertControl } from "components/Modals/Alert";
import { BtnScanQRWidget, InputRegisTypeWidget } from "widgets";
import { Cards, Displays, Forms, Modals, Headers } from "components";

class ProductwarranteeNotLoginContainer extends React.Component {
  state = {
    isLoading: true,
    step: 1,
  };

  componentDidMount() {
    const { router } = this.props;
    localStorage.clear();
    if (!router.params?.id) {
      this.router.push("/");
    } else {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { router } = this.props;
    let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(
      router.params?.id
    );
    if (res && res.status === 200) {
      if (
        res.data.status === "2" ||
        res.data.status === "3" ||
        res.data.status === "4"
        // ||res.data.status === '5'
      ) {
        this.handleNextStep();
      } else {
        this.setState({
          data: res.data,
          isLoading: false,
          isBgLoading: false,
        });
      }
    } else {
      toast.error("เลขซีเรียลไม่ถูกต้อง");
      this.props.router.navigate(
        `${ROUTE_PATH.PRODUCT_WARRANTEE_NOT_LOGIN}/${router.params?.id}/fail`
      );
    }
  };

  renderShowModal = (e) => {
    switch (e) {
      case "user_info":
        this.setState({
          renderModal: (
            <UserInfoConditionContainer onClose={this.handleCloseModal} />
          ),
          isShowModal: true,
        });
        break;
      case "term_condition":
        this.setState({
          renderModal: (
            <TermConditionContainer onClose={this.handleCloseModal} />
          ),
          isShowModal: true,
        });
        break;
      default:
        break;
    }
  };

  handleCloseModal = () => {
    this.setState({
      renderModal: null,
      isShowModal: false,
    });
  };

  handleChangeMemberType = (e) => {
    this.setState({
      memberType: e,
    });
  };

  handleAddProductWarranty = async (sn) => {
    const { productWarrantyAddMore } = this.state;
    this.setState({
      isBgLoading: true,
    });
    const split = sn.split('/');
    const serialNo = split[split.length - 1];
    let hasDuplicate = !productWarrantyAddMore
      ? false
      : productWarrantyAddMore.some(
          (e) => e.serial_no === serialNo || `${e.serial_no}-${e.item_no}` === serialNo
        );
    if (hasDuplicate) {
      this.setState({
        isBgLoading: false,
      });
      AlertControl.show({
        title: "แจ้งเตือน",
        description: "Serial no นี้ ถูกใช้แล้ว",
      });
    } else {
      let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(serialNo);
      if (res && res.status === 200) {
        if (res.data.status !== "1") {
          this.setState({
            isBgLoading: false,
          });
          toast.error("เลขประกันของท่านถูกใช้แล้ว กรุณาติดต่อเจ้าหน้าที่");
        } else {
          let temp = !productWarrantyAddMore ? [] : [...productWarrantyAddMore];
          temp.push(res.data);
          this.setState({
            productWarrantyAddMore: [...temp],
            isBgLoading: false,
          });
        }
      } else {
        toast.error('ไม่มี Serial no นี้ในระบบ')
      }
    }
  };

  handleDeleteProductAddMore = (index) => {
    const { productWarrantyAddMore } = this.state;
    let temp = [...productWarrantyAddMore];
    temp.splice(index, 1);
    this.setState({
      productWarrantyAddMore: [...temp],
    });
  };

  handleSubmit = async (values) => {
    const { data, memberType, productWarrantyAddMore } = this.state;
    let params = {};
    switch (memberType) {
      case "1":
        params = {
          ...values,
          product_warrantee_id: data._id,
          gender: values.gender.value,
          province: values.province.value,
          birthday: moment(values.birthday).format("YYYY-MM-DD"),
          email: values.email,
          member_type: "1",
        };
        break;
      case "2":
        const productWarrantyList = [{ product_warrantee_id: data._id }];
        if (productWarrantyAddMore && productWarrantyAddMore.length > 0) {
          productWarrantyAddMore.forEach((e) => {
            productWarrantyList.push({ product_warrantee_id: e._id });
          });
        }
        params = {
          organization_name: values.organization_name,
          organization_province: values.organization_province.value,
          organization_phone_number: values.organization_phone_number,
          organization_email: values.organization_email,
          product_warranty_list: JSON.stringify(productWarrantyList),
          image_file: values.image_file,
        };
        if (values.organization_coporate_id) {
          params.organization_coporate_id = values.organization_coporate_id;
        }
        break;
      default:
        break;
    }
    this.setState({
      productWarrantyRegis: params,
      step: 2,
    });
  };
  handleAcceptPDPA = async (e) => {
    const { productWarrantyRegis, memberType } = this.state;
    let params = {
      ...productWarrantyRegis,
      pdpa_accepted_latest_ref_id: e.pdpa_id,
    };
    let paramsPDPA = {
      first_name:
        memberType === "1" ? params.first_name : params.organization_name,
      last_name: memberType === "1" ? params.last_name : "",
      phone_number:
        memberType === "1"
          ? params.phone_number
          : params.organization_phone_number,
      pdpa_id: e.pdpa_id,
      is_accepted_privacy_pdpa: e.is_accepted_privacy_pdpa ? true : false,
      is_accepted_marketing_pdpa: e.is_accepted_marketing_pdpa ? true : false,
      is_accepted_product_warranty_pdpa: e.is_accepted_product_warranty_pdpa
        ? true
        : false,
      is_accepted_certified_true_info_pdpa:
        e.is_accepted_certified_true_info_pdpa ? true : false,
      is_not_login: true,
      is_member: false,
    };
    this.setState({
      isBgLoading: true,
    });
    if (
      !params?.image_file ||
      params?.image_file.filter((e) => e.isNewUpload).length === 0
    ) {
      toast.error("กรุณาอัพโหลดสลิปใบเสร็จ");
    } else {
      const bodyFormData = new FormData();
      Object.keys(params).forEach((key) => {
        bodyFormData.set(key, params[key]);
      });
      bodyFormData.set("member_type", memberType);
      bodyFormData.append("image", params?.image_file[0]);
      let resArr = await Promise.all([
        memberType === "1"
          ? userService.POST_NO_MEMBER_UPLOAD_SLIP(bodyFormData)
          : userService.POST_UPLOAD_COPERATE_REGIS(bodyFormData),
        userService.POST_PDPA_ACCEPTED_NOT_LOGIN_HISTORY(paramsPDPA),
      ]);
      if (resArr) {
        this.setState({
          isBgLoading: false,
        });
        toast.success("ลงทะเบียนสำเร็จ");
        this.handleNextStep();
      } else {
        this.setState({
          isBgLoading: false,
        });
        toast.error("ลงทะเบียนไม่สำเร็จ");
      }
    }
  };

  handleNextStep = () => {
    const { router } = this.props;
    this.props.router.navigate(
      `${ROUTE_PATH.PRODUCT_WARRANTEE_NOT_LOGIN}/${router.params?.id}/success`
    );
  };

  render() {
    const { t } = this.props;
    const {
      isLoading,
      isBgLoading,
      isShowModal,
      renderModal,
      data,
      memberType,
      step,
      productWarrantyAddMore,
    } = this.state;
    return (
      <ProductwarranteeNotLoginContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {step === 1 ? (
          <>
            <Headers.Logo />
            <div className="title">
              {t("Warranty information", { ns: "common" })}
            </div>
            {isLoading ? (
              <Displays.Loading />
            ) : (
              <>
                <div className="product_wrap">
                  <Cards.ProductWarrantee
                    description={data.description}
                    descriptionLine2={data.description_line_2}
                    sn={data.serial_no}
                    warrantyPeriod={data.warranty_period}
                    warrantyUnit={data.warranty_unit}
                    warrantyDescription={data.warranty_description_th}
                    status={data.status}
                    approvedWarrantyDate={data.approved_warranty_date}
                    extensionPeriod={data.extension_period}
                    extensionUnit={data.extension_unit}
                    rejectedReason={data.rejected_reason}
                  />
                </div>
                {memberType === "2" && (
                  <>
                    {productWarrantyAddMore &&
                      productWarrantyAddMore.map((e, i) => (
                        <div key={i} className="product_more_item">
                          <Cards.ProductWarrantee
                            description={e.description}
                            descriptionLine2={e.description_line_2}
                            sn={e.serial_no}
                            warrantyPeriod={e.warranty_period}
                            warrantyUnit={e.warranty_unit}
                            warrantyDescription={e.warranty_description_th}
                            status={e.status}
                            approvedWarrantyDate={e.approved_warranty_date}
                            extensionPeriod={e.extension_period}
                            extensionUnit={e.extension_unit}
                            rejectedReason={e.rejected_reason}
                            isDelete
                            onDelete={() =>
                              this.handleDeleteProductAddMore(i, e)
                            }
                          />
                        </div>
                      ))}
                    <div className="btn_add_sn">
                      <BtnScanQRWidget
                        btnLabel={t("Scan to add product", { ns: "common" })}
                        onScanSuccess={this.handleAddProductWarranty}
                      />
                    </div>
                  </>
                )}
                <div className="input_regis_type_wrap">
                  <InputRegisTypeWidget
                    onChange={this.handleChangeMemberType}
                  />
                </div>
                {memberType &&
                  (memberType === "1" ? (
                    <Forms.ProductWarranteeNoLoginForm
                      initialValues={data}
                      onSubmit={this.handleSubmit}
                      handleClickShowModal={this.renderShowModal}
                    />
                  ) : (
                    <Forms.ProductWarranteeCoperateNoLoginForm
                      initialValues={data}
                      onSubmit={this.handleSubmit}
                      handleClickShowModal={this.renderShowModal}
                    />
                  ))}
              </>
            )}
          </>
        ) : (
          <PDPAContainer type="modal" onAccept={this.handleAcceptPDPA} />
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ProductwarranteeNotLoginContainerStyled>
    );
  }
}

export default withTranslation(["profile", "common"])(
  withRouter(ProductwarranteeNotLoginContainer)
);
