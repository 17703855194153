import React from 'react';

export default function renderIcon({
  width = 65.653,
  height = 65.652,
  color = '#fff',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 65.653 65.652"
    >
      <g
        id="Group_231"
        data-name="Group 231"
        transform="translate(-1875.282 -5286.434)"
      >
        <g
          id="Group_229"
          data-name="Group 229"
          transform="translate(1875.282 5286.434)"
        >
          <path
            id="Path_3786"
            data-name="Path 3786"
            d="M1940.935,5319.26a32.827,32.827,0,1,1-32.827-32.826,32.827,32.827,0,0,1,32.827,32.826"
            transform="translate(-1875.282 -5286.435)"
            fill="#4b1e78"
          />
        </g>
        <g
          id="Group_230"
          data-name="Group 230"
          transform="translate(1884.84 5295.991)"
        >
          <path
            id="Path_3787"
            data-name="Path 3787"
            d="M1910.488,5344.908a23.268,23.268,0,1,1,23.268-23.268A23.294,23.294,0,0,1,1910.488,5344.908Zm0-42.733a19.465,19.465,0,1,0,19.466,19.466A19.488,19.488,0,0,0,1910.488,5302.175Z"
            transform="translate(-1887.221 -5298.372)"
            fill={color}
          />
          <path
            id="Path_3788"
            data-name="Path 3788"
            d="M1914.852,5313.068l1.706,5.25a1.615,1.615,0,0,0,1.536,1.117h5.52a1.615,1.615,0,0,1,.95,2.922l-4.466,3.244a1.615,1.615,0,0,0-.587,1.806l1.706,5.25a1.615,1.615,0,0,1-2.485,1.807l-4.467-3.246a1.615,1.615,0,0,0-1.9,0l-4.466,3.246a1.616,1.616,0,0,1-2.486-1.807l1.706-5.25a1.617,1.617,0,0,0-.587-1.806l-4.467-3.244a1.616,1.616,0,0,1,.95-2.922h5.52a1.615,1.615,0,0,0,1.536-1.117l1.706-5.25a1.615,1.615,0,0,1,3.072,0"
            transform="translate(-1890.048 -5301.08)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
