import styled from 'styled-components';

export const ProfileContainerStyled = styled.div`
  padding: 60px 20px 104px 20px;
  .tab_container {
    border-radius: 12px;
    border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    display: flex;
    overflow: hidden;
    .f_col {
      border-right: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      flex-basis: 33.33%;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 6px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      cursor: pointer;
      &.active {
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      &:last-child {
        border-right: none;
      }
      .tab_muti_label {
        text-align: center;
      }
    }
  }
`;
