import styled from 'styled-components';

export const QuickTestFormStyled = styled.div`
  .input_wrap {
  }
  .passed_label {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 145px;
  }
`;
