import React from "react";
import { ProductWarranteeFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const ProductWarranteeForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    image_file: yup
      .array()
      .required("Receipt is required")
      .min(1, "Receipt is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(["common"]);

  return (
    <ProductWarranteeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Upload
                {...field}
                maxMBFileSizeWarning={8}
                acceptType="cover"
                errors={errors.image_file?.message}
              />
            )}
            name="image_file"
            defaultValue={[]}
          />
        </div>
        <div className="btn_submit_wrap">
          <Buttons.BgStandard label={t("OK", { ns: "common" })} type="submit" />
        </div>
      </form>
    </ProductWarranteeFormStyled>
  );
};

ProductWarranteeForm.propTypes = {};

export default ProductWarranteeForm;
