import { useState, useEffect } from "react";
import { NotificationWidgetStyled } from "./styled";
import { userService } from "apiServices";
import { useNavigate } from "react-router-dom";
import { useComponentVisible } from "utils/functions/detectClickOutside";
import { ROUTE_PATH } from "utils/constants/routePath";
import { useTranslation } from "react-i18next";
import { Icons } from "components";

const NotificationWidget = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  const [_isAlert, _setIsAlert] = useState(false);
  const [_data, _setData] = useState();

  useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = async () => {
    let res = await userService.GET_NOTIFICATION_LIST();
    if (res && res.status === 200) {
      let findAlert = res.data.docs.find((e) => !e.is_read);
      _setIsAlert(findAlert ? true : false);
      _setData(res.data);
    }
  };

  const _handleClickNoti = () => {
    setIsComponentVisible(!isComponentVisible);
    if (_isAlert) {
      userService.POST_NOTIFICATION_READ_ALL();
      _setIsAlert(false);
    }
  };

  const _handleClickNotiItem = (e) => {
    _handleClickNoti();
    switch (e.feature_type) {
      case "1":
        navigate(`${ROUTE_PATH.NEW}/${e.feature_id}`);
        break;
      case "2":
        navigate(`${ROUTE_PATH.ACTIVITIES}/${e.feature_id}`);
        break;
      case "3":
        navigate(`${ROUTE_PATH.SPECIAL_POINTS_FAV}/test/${e.feature_id}`);
        break;
      default:
        break;
    }
  };

  return (
    <NotificationWidgetStyled ref={ref}>
      <div className="btn_notification" onClick={_handleClickNoti}>
        {_isAlert ? <Icons.BellAlert color="#FF0000" /> : <Icons.Bell />}
      </div>
      {isComponentVisible && (
        <div className="notification_body">
          {!_data || _data.docs.length === 0
            ? t("No notificatoin", { ns: "common" })
            : _data.docs.map((e, i) => (
                <div
                  key={i}
                  className="noti_item"
                  onClick={() => _handleClickNotiItem(e)}
                >
                  <div className="noti_title">{e.title}</div>
                  <div className="noti_description">{e.message}</div>
                </div>
              ))}
        </div>
      )}
    </NotificationWidgetStyled>
  );
};

export default NotificationWidget;
