import styled from "styled-components";

export const InputRegisTypeWidgetStyled = styled.div`
  .btn_inr_item {
    border-radius: 9px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_4}21;
    padding: 12px 10px;
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.PURPLE_3}21;
    cursor: pointer;
    &.mb_10 {
      margin-bottom: 10px;
    }
    &.active {
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_4}73;
      background: ${({ theme }) => theme.COLORS.PURPLE_3}73;
    }
    .inr_icon_wrap {
      margin-left: 15px;
      margin-right: 12px;
    }
    .inr_label {
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }
`;
