import React from "react";

export default function renderIcon({
  width = 15,
  height = 15,
  color = "#4E595F",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9599 7.84037L14.6966 2.53268C14.7917 2.44951 14.8681 2.3461 14.9205 2.22961C14.9729 2.11313 15 1.9864 15 1.85808C15 1.72977 14.9729 1.60299 14.9205 1.4865C14.8681 1.37002 14.7917 1.26661 14.6966 1.18343C14.4936 1.00217 14.2336 0.902344 13.9647 0.902344C13.6957 0.902344 13.4357 1.00217 13.2327 1.18343L7.49605 6.48299L1.76726 1.18343C1.56425 1.00217 1.30433 0.902344 1.03535 0.902344C0.766368 0.902344 0.506438 1.00217 0.303426 1.18343C0.20831 1.26661 0.131918 1.37002 0.07953 1.4865C0.0271418 1.60299 0 1.72977 0 1.85808C0 1.9864 0.0271418 2.11313 0.07953 2.22961C0.131918 2.3461 0.20831 2.44951 0.303426 2.53268L6.04012 7.84037L0.303426 13.1643C0.20831 13.2475 0.131918 13.3509 0.07953 13.4674C0.0271418 13.5838 0 13.7106 0 13.8389C0 13.9672 0.0271418 14.094 0.07953 14.2105C0.131918 14.327 0.20831 14.4304 0.303426 14.5135C0.505686 14.6964 0.765901 14.7973 1.03535 14.7973C1.3048 14.7973 1.565 14.6964 1.76726 14.5135L7.49605 9.21403L13.2327 14.5135C13.435 14.6964 13.6952 14.7973 13.9647 14.7973C14.2341 14.7973 14.4943 14.6964 14.6966 14.5135C14.7917 14.4304 14.8681 14.327 14.9205 14.2105C14.9729 14.094 15 13.9672 15 13.8389C15 13.7106 14.9729 13.5838 14.9205 13.4674C14.8681 13.3509 14.7917 13.2475 14.6966 13.1643L8.9599 7.84037Z"
        fill={color}
      />
    </svg>
  );
}
