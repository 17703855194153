import styled from 'styled-components';

export const SpinWheelWidgetStyled = styled.div`
  text-align: center;
  .wheel_wrap {
    margin: auto;
    margin-bottom: 60px;
    position: relative;
    width: 348px;
    display: flex;
    justify-content: center;
    align-items: center;
    .bhdLno {
      width: 276px;
      height: 276px;
    }
    .point_center {
      position: absolute;
      border-radius: 60px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.PURPLE_2};
      z-index: 10;
      .point_nest {
        border-radius: 46px;
        width: 46px;
        height: 46px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
      }
    }
  }
`;
