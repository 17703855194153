import React from "react";
import { RedeemHistoryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import moment from "moment";
import { RenderCommaMoney } from "utils/functions/formatMoney";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants/routePath";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import { withTranslation } from "react-i18next";
import { Displays, Modals, Navigations, Tables } from "components";

class RedeemHistoryContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (redirectID) => {
    let res = await userService.GET_MY_REWARD();
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
        isBgLoading: false,
      });
      if (redirectID) {
        const find = res.data.find((e) => e._id === redirectID);
        if (find) {
          this.handleClickHistory(find);
        }
      }
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: null,
    });
  };
  handleClickHistory = (e) => {
    switch (e.reward_type) {
      case "1":
        if (e.status === "2") {
          this.setState({
            isShowModal: true,
            renderModal: (
              <Displays.AlertAction
                label="ยืนยันการแลก"
                onCancel={this.handleCloseModal}
                onAccept={async () => {
                  const body = document.body;
                  body.style.overflow = "auto";
                  this.setState({
                    isBgLoading: true,
                  });
                  let res = await userService.PUT_USE_E_COUPON(e._id);
                  if (res && res.status === 200) {
                    this.fetchData(e._id);
                  } else {
                    this.handleCloseModal();
                    this.setState({
                      isBgLoading: false,
                    });
                    toast.error("ใช้ไม่สำเร็จ กรุณาตรวจสอบเงื่อนไข");
                  }
                }}
              />
            ),
          });
        } else {
          let dataCheck = moment(e.barcode_expire).format("YYYY-MM-DD");
          let today = moment().format("YYYY-MM-DD");
          if (
            moment(today).isBefore(dataCheck) ||
            moment(dataCheck).isSame(today) === true
          ) {
            this.setState({
              isShowModal: true,
              renderModal: (
                <div className="tab_zone">
                  <Navigations.BottomTab
                    data={[
                      {
                        key: "reward_eletronic",
                        name: "รหัส",
                        element: (
                          <div>
                            {`รหัส ${e.reward_e_counpon_code}`}
                            {/* <div className="btn_link">
                            <a
                              href={'e.code'}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Go to redeem
                            </a>
                          </div> */}
                            {/* {(e.code.search('https') !== -1 ||
                        e.code.search('http') !== -1) && (
                        <div className="btn_link">
                          <a
                            href={e.code}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Go to redeem
                          </a>
                        </div>
                      )} */}
                          </div>
                        ),
                      },
                      {
                        key: "reward_card",
                        name: "บาร์โค้ด",
                        element: (
                          <div style={{ textAlign: "center" }}>
                            <Barcode
                              value={e.reward_e_counpon_code}
                              width={0.5}
                              displayValue={false}
                            />
                          </div>
                        ),
                      },
                      {
                        key: "reward_etc",
                        name: "คิวอาร์โค้ด",
                        element: (
                          <div style={{ textAlign: "center" }}>
                            <QRCode
                              value={e.reward_e_counpon_code}
                              size={190}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              ),
            });
          } else {
            toast.error("ขออภัย e-coupon หมดอายุ");
          }
        }
        break;
      case "2":
      case "3":
        if (e.status === "2") {
          this.props.router.navigate(
            `${ROUTE_PATH.POINT_REDEEM}/detail/${e._id}/shipping`
          );
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;
    const { isLoading, isBgLoading, data, isShowModal, renderModal } =
      this.state;
    return (
      <RedeemHistoryContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <Tables.HeaderRowPanel
            columns={columns({ t })}
            data={data}
            onClickRow={this.handleClickHistory}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RedeemHistoryContainerStyled>
    );
  }
}

const columns = ({ t }) => [
  {
    title: t("Date/Time", { ns: "profile" }),
    field: "created_at",
    headerStyle: { width: "184px" },
    style: { width: 184 },
    render: ({ value }) => moment(value).format("DD-MM-YYYY"),
  },
  {
    title: t("Name", { ns: "profile" }),
    field: "reward_name",
    headerStyle: { textAlign: "center" },
  },
  {
    title: t("Points", { ns: "profile" }),
    field: "reward_point",
    headerStyle: { width: "184px", textAlign: "center" },
    style: { width: 184, textAlign: "center" },
    render: ({ origin, value }) => `${RenderCommaMoney(value)} Points`,
  },
  {
    title: t("Status", { ns: "profile" }),
    field: "status",
    headerStyle: { width: "184px", textAlign: "center" },
    style: { width: 184, textAlign: "center" },
    render: ({ origin, value }) => (
      <Displays.RewardStatus type={origin.reward_type} status={value} />
    ),
  },
];

export default withTranslation(["profile"])(withRouter(RedeemHistoryContainer));
