import React from "react";
import { connect } from "react-redux";
import { PDPAContainerStyled, PDPAItemStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { setReduxAuthenPDPA } from "store/reducers/authenReducer";
import { userService } from "apiServices";
import queryString from "query-string";
import { ROUTE_PATH } from "utils/constants/routePath";
import { withTranslation } from "react-i18next";
import { Buttons, Cards, Displays, Forms, Modals } from "components";

class PDPAContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux, type } = this.props;
    let res = await userService.GET_PDPA_LATEST();
    if (res && res.status === 200) {
      if (
        !authenRedux ||
        !authenRedux.pdpa_accepted_latest_ref_id ||
        type === "modal"
      ) {
        this.setState({
          data: res.data,
          isLoading: false,
        });
      } else {
        let resCheckHistory = await userService.GET_PDPA_ACCEPTED_HISTORY(
          res.data._id
        );
        if (resCheckHistory && resCheckHistory.status === 200) {
          this.setState({
            initialValues: resCheckHistory.data,
            data: res.data,
            isLoading: false,
          });
        } else {
          this.setState({
            data: res.data,
            isLoading: false,
          });
        }
      }
    }
  };

  renderShowModal = (e) => {
    const { t } = this.props;
    const { data } = this.state;
    switch (e) {
      case "privacy":
        this.setState({
          renderModal: (
            <PDPAItemStyled>
              <Cards.ConditionCard
                title={data.privacy_title}
                footerNode={
                  <Buttons.BgStandard
                    label={t("OK", { ns: "common" })}
                    onClick={this.handleCloseModal}
                  />
                }
              >
                {data.privacy_body}
              </Cards.ConditionCard>
            </PDPAItemStyled>
          ),
          isShowModal: true,
        });
        break;
      case "marketing":
        this.setState({
          renderModal: (
            <PDPAItemStyled>
              <Cards.ConditionCard
                title={data.marketing_title}
                footerNode={
                  <Buttons.BgStandard
                    label={t("OK", { ns: "common" })}
                    onClick={this.handleCloseModal}
                  />
                }
              >
                {data.marketing_body}
              </Cards.ConditionCard>
            </PDPAItemStyled>
          ),
          isShowModal: true,
        });
        break;
      case "product_warranty":
        this.setState({
          renderModal: (
            <PDPAItemStyled>
              <Cards.ConditionCard
                title={data.product_warranty_title}
                footerNode={
                  <Buttons.BgStandard
                    label={t("OK", { ns: "common" })}
                    onClick={this.handleCloseModal}
                  />
                }
              >
                {data.product_warranty_body}
              </Cards.ConditionCard>
            </PDPAItemStyled>
          ),
          isShowModal: true,
        });
        break;
      default:
        break;
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: false,
    });
  };

  handleSubmit = async (values) => {
    const { type, onAccept } = this.props;
    let { is_regis } = queryString.parse(this.props.router.location.search);
    const { data, initialValues } = this.state;
    let params = {
      ...values,
      pdpa_id: data._id,
      is_accepted_privacy_pdpa:
        values.is_accepted_privacy_pdpa === "1" ? true : false,
      is_accepted_product_warranty_pdpa:
        values.is_accepted_product_warranty_pdpa === "1" ? true : false,
      is_accepted_certified_true_info_pdpa:
        values.is_accepted_certified_true_info_pdpa === "1" ? true : false,
      is_accepted_marketing_pdpa:
        values.is_accepted_marketing_pdpa === "1" ? true : false,
    };
    if (type === "modal") {
      onAccept && onAccept(params);
    } else {
      if (!is_regis) {
        this.setState({
          isBgLoading: true,
        });
        if (initialValues) {
          params.pdpa_history_ref_id = initialValues._id;
        }
        params.is_member = true;
        let res = await userService.POST_PDPA_ACCEPTED_HISTORY(params);
        if (res && res.status === 200) {
          this.props.setReduxAuthenPDPA(res.data._id);
          this.props.router.navigate(ROUTE_PATH.HOME);
        }
      } else {
        this.props.router.navigate(
          `${ROUTE_PATH.REGISTER}${this.props.router.location.search}&pdpa_id=${data._id}&is_accepted_privacy_pdpa=${params.is_accepted_privacy_pdpa}&is_accepted_marketing_pdpa=${params.is_accepted_marketing_pdpa}&is_accepted_product_warranty_pdpa=${params.is_accepted_product_warranty_pdpa}&is_accepted_certified_true_info_pdpa=${params.is_accepted_certified_true_info_pdpa}`
        );
      }
    }
  };

  render() {
    const { t } = this.props;
    const {
      isShowModal,
      isLoading,
      isBgLoading,
      data,
      renderModal,
      initialValues,
    } = this.state;
    return (
      <PDPAContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <div className="pdpa_head_title">
          {t("Terms and conditions", { ns: "common" })}
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="pdpa_body_container">
            <Forms.PDPAForm
              {...data}
              initialValues={initialValues}
              onClickMore={this.renderShowModal}
              onSubmit={this.handleSubmit}
            />
          </div>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </PDPAContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthenPDPA: (data) => dispatch(setReduxAuthenPDPA(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common"])(withRouter(PDPAContainer)));
