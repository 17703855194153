import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ScanInfoStyled } from './styled';

const ScanInfo = ({ theme_standard, amount }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <ScanInfoStyled>
      <div className={customClass}>
        <div className="scan_info_container">
          {Array.from({ length: amount }).map((e, i) => (
            <div key={i} className="si_label">
              สแกนคิวอาร์ครั้งที่ {i + 1} แล้ว
            </div>
          ))}
        </div>
      </div>
    </ScanInfoStyled>
  );
};

ScanInfo.propTypes = {};

export default ScanInfo;
