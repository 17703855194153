import styled from 'styled-components';

export const RewardStatusStyled = styled.div`
  .reward_status_container {
    border-radius: 10px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ bgColor }) => bgColor};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S10};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }

  .theme_standard {
  }
`;
