import React from 'react';
import { PointRedeemShippingContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiServices';
import { Displays, Forms, Icons } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';

class PointRedeemShippingContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_BRANCH_LIST(`?pagination=false`);
    if (res && res.status === 200) {
      this.setState({
        branchOptions: res.data.docs.map((e) => ({
          ...e,
          label: e.name,
          value: e._id,
        })),
        isLoading: false,
      });
    }
  };
  handleSubmit = async (values) => {
    const { branchOptions } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      ...values,
      reward_history_id: this.props.router.params.id,
    };
    if (values.type_shipping === '1') {
      let find = branchOptions.find((e) => e._id === values.branch.value);
      if (find) {
        params.name_shipping = find.name;
        params.phone_shipping = find.phone_number;
        params.address_shipping = `${find.province} ${find.district} ${find.sub_district} ${find.zipcode} ${find.address}`;
      }
    }
    let res = await userService.POST_SHIPPING(params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      this.props.router.navigate(ROUTE_PATH.POINT_REDEEM_E_COUPON);
    }
  };

  render() {
    const { isLoading, isBgLoading, branchOptions } = this.state;
    return (
      <PointRedeemShippingContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Displays.HeaderIconTitle
          mb={'12px'}
          icon={<Icons.SpecialPoint />}
          topLabel={'Special Points'}
          bottomLabel={'คะแนนพิเศษ'}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <Forms.ShippingForm
            branchOptions={branchOptions}
            onSubmit={this.handleSubmit}
          />
        )}
      </PointRedeemShippingContainerStyled>
    );
  }
}

export default withRouter(PointRedeemShippingContainer);
