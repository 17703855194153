import styled from 'styled-components';

export const ProductRedeemStyled = styled.div`
  .product_redeem_container {
    position: relative;
    .reward_img {
      width: 100%;
      height: auto;
    }
    .redeem_point {
      position: absolute;
      bottom: 14px;
      right: 12px;
      border-radius: 12px;
      padding: 0 12px;
      min-width: 82px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }

  .theme_standard {
  }
`;
