import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { HeaderIconTitleStyled } from './styled';

const HeaderIconTitle = ({
  theme_standard,
  theme_head_only,
  icon,
  topLabel,
  bottomLabel,
  mb,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_head_only: theme_head_only,
  });
  return (
    <HeaderIconTitleStyled mb={mb}>
      <div className={customClass}>
        <div className="header_icon_title_container">
          <div className="hi_left_col">
            <div className="circle">{icon}</div>
          </div>
          <div className="hi_right_col">
            <div className="lc_title">
              {typeof topLabel === 'string' ? (
                topLabel
              ) : (
                <div className="tl_multi_label">
                  {topLabel.map((e, i) => (
                    <div key={i}>{e}</div>
                  ))}
                </div>
              )}
            </div>
            <div className="lc_suv_title">{bottomLabel}</div>
          </div>
        </div>
      </div>
    </HeaderIconTitleStyled>
  );
};

HeaderIconTitle.propTypes = {};

export default HeaderIconTitle;
