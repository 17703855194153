import { useEffect, useState } from 'react';
import { InputRegisTypeWidgetStyled, FormContainer } from './styled';
import { useSelector, useDispatch } from 'react-redux';
import { userService } from 'apiServices';
import { useNavigate } from 'react-router-dom';
import { updateSwitchAccountThunk } from 'store/reduxThunks/updateSwitchAccountThunk';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { useTranslation } from 'react-i18next';
import { Displays, Forms, Icons, Modals } from 'components';

const InputRegisTypeWidget = ({ type = '1', value, onChange }) => {
  const authenRedux = useSelector((state) => state.authenRedux);
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_value, _setValue] = useState();
  const [_renderModal, _setRenderModal] = useState();

  useEffect(() => {
    if (!value !== _value || authenRedux) {
      _setValue(value || authenRedux.member_type_active);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClick = async (e) => {
    _setIsBgLoading(true);
    let res = await dispatch(updateSwitchAccountThunk(e));
    if (res && res.payload) {
      _setValue(e);
      dispatch(updateSwitchAccountThunk(e));
      onChange && onChange(e);
      _setIsBgLoading(false);
    } else {
      _setIsBgLoading(false);
    }
  };

  const _handleAddOrganization = () => {
    _setRenderModal(
      <FormContainer>
        <Forms.RegisterCorperateOnlyForm
          onSubmit={_handleSubmitOrganizationCreate}
        />
      </FormContainer>
    );
  };

  const _handleSubmitOrganizationCreate = async (values) => {
    _setIsBgLoading(true);
    let params = {
      ...values,
      province: values.province.value,
      member_list: [{ member_ref_id: authenRedux._id }],
    };
    let res = await userService.POST_ORGANIZATION_ONLY_CREATE(params);
    if (res && res.status === 200) {
      toast.success(t('Create success', { ns: 'common' }));
      navigate(`${ROUTE_PATH.SPLASH}`);
    } else {
      toast.error(t('Create fail', { ns: 'common' }));
      _setIsBgLoading(false);
    }
  };

  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  return (
    <>
      <InputRegisTypeWidgetStyled>
        <Displays.BGLoading visible={_isBgLoading} />
        {type === '1' && (
          <div className="input_regis_type1_container">
            <div
              className={`btn_inr_item ${_value === '1' ? 'active' : ''}`}
              onClick={() => _handleClick('1')}
            >
              <Icons.Checkbox isActive={_value === '1'} />
              <div className="inr_icon_wrap">
                <Icons.MemberPerson2 />
              </div>
              <div className="inr_label">
                {' '}
                {t('Individual', { ns: 'common' })}
              </div>
            </div>
            {authenRedux?.organization_ref_id ? (
              <div
                className={`btn_inr_item ${_value === '2' ? 'active' : ''}`}
                onClick={() => _handleClick('2')}
              >
                <Icons.Checkbox isActive={_value === '2'} />
                <div className="inr_icon_wrap">
                  <Icons.MemberCoperate />
                </div>
                <div className="inr_label">
                  {t('Corporate', { ns: 'common' })}
                </div>
              </div>
            ) : (
              <div className={`btn_inr_item`} onClick={_handleAddOrganization}>
                <div className="inr_icon_wrap">
                  <Icons.MemberCoperate />
                </div>
                <div className="inr_label">
                  {t('Add Corporate', { ns: 'common' })}
                </div>
              </div>
            )}
          </div>
        )}
        {type === '2' && (
          <div className="input_regis_type2_container">
            <div
              className={`btn_inr_item mb_10 ${_value === '1' ? 'active' : ''}`}
              onClick={() => _handleClick('1')}
            >
              <Icons.Checkbox isActive={_value === '1'} />
              <div className="inr_icon_wrap">
                <Icons.MemberPerson />
              </div>
              <div className="inr_label">
                {t('Individual Registration', { ns: 'common' })}
              </div>
            </div>
            {authenRedux?.organization_ref_id ? (
              <div
                className={`btn_inr_item ${_value === '2' ? 'active' : ''}`}
                onClick={() => _handleClick('2')}
              >
                <Icons.Checkbox isActive={_value === '2'} />
                <div className="inr_icon_wrap">
                  <Icons.MemberCoperate />
                </div>
                <div className="inr_label">
                  {t('Corporate Registration', { ns: 'common' })}
                </div>
              </div>
            ) : (
              <div
                className={`btn_inr_item add`}
                onClick={_handleAddOrganization}
              >
                <div className="inr_icon_wrap">
                  <Icons.MemberCoperate />
                </div>
                <div className="inr_label">
                  {t('Corporate Registration', { ns: 'common' })}
                </div>
              </div>
            )}
          </div>
        )}
      </InputRegisTypeWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal}
        onCloseModal={_handleCloseModal}
      >
        {_renderModal}
      </Modals.BodyEmpty>
    </>
  );
};

export default InputRegisTypeWidget;
