import React from 'react';

export default function renderIcon({
  width = 38.315,
  height = 41.602,
  color = '#fff',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38.315 41.602"
    >
      <g id="Group_85" data-name="Group 85" transform="translate(-35.233 -272)">
        <path
          id="Path_134"
          data-name="Path 134"
          d="M1940.263,796.065a8.01,8.01,0,1,1,8.01-8.01A8.018,8.018,0,0,1,1940.263,796.065Zm0-12.687a4.677,4.677,0,1,0,4.677,4.677A4.682,4.682,0,0,0,1940.263,783.379Z"
          transform="translate(-1897.021 -482.463)"
          fill={color}
        />
        <path
          id="Path_135"
          data-name="Path 135"
          d="M1973.715,784.818a8.01,8.01,0,1,1,8.01-8.01A8.019,8.019,0,0,1,1973.715,784.818Zm0-12.687a4.677,4.677,0,1,0,4.677,4.677A4.682,4.682,0,0,0,1973.715,772.132Z"
          transform="translate(-1908.177 -478.713)"
          fill={color}
        />
        <path
          id="Path_136"
          data-name="Path 136"
          d="M1954.182,773.383h-3.333V754.211a6.412,6.412,0,0,1,3.812-6.637c3.283-1.352,16.094-5.625,16.638-5.806l.321-.073a5.075,5.075,0,0,1,3.71,1.016,3.767,3.767,0,0,1,1.239,2.943v20.567h-3.333V745.655a.668.668,0,0,0-.111-.445,1.8,1.8,0,0,0-.965-.216c-1.648.551-13.263,4.441-16.229,5.662a3.143,3.143,0,0,0-1.763,3.237l.015.22Z"
          transform="translate(-1903.211 -469.664)"
          fill={color}
        />
      </g>
    </svg>
  );
}
