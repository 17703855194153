import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CustomerActFRegisItemStyled } from "./styled";
import { RENDER_ACITIVITY_FORM_STATUS } from "utils/functions/activityForm";
import { Icons } from "components";
import { IMG_PATH } from "utils/constants/imgPath";

const CustomerActFRegisItem = ({
  theme_standard,
  certificate_file,
  check_in,
  no,
  personal_first_name,
  personal_last_name,
  status,
  is_qrcode,
  onEdit,
  onShowQrCode,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const renderStatus = RENDER_ACITIVITY_FORM_STATUS(status);

  return (
    <CustomerActFRegisItemStyled
      className={customClass}
      statusBg={renderStatus.color}
    >
      <div className="mbr_name">
        {no} {personal_first_name} {personal_last_name}
      </div>
      <div className="mbr_status_info_row">
        <div className="mrt_col">
          <button className="mbr_status">{renderStatus.label}</button>
        </div>
        <div className="mrt_col">
          {is_qrcode &&
            status === '4' &&
            (!check_in ? (
              <button className="si_item bg_blue" onClick={onShowQrCode}>
                <Icons.QrCodeMark />
                ID QR code
              </button>
            ) : (
              <button className="si_item check_in_label">Check in</button>
            ))}
        </div>
        <div className="mrt_col">
          {(status === "1" || status === "2") && (
            <button className="si_item bg_purple" onClick={onEdit}>
              แก้ไข
            </button>
          )}
          {certificate_file && (
            <a target="blank" href={IMG_PATH + certificate_file}>
              <button className="si_item bg_gold">
                <Icons.QrCodeMark />
                Certificate
              </button>
            </a>
          )}
        </div>

        {/* <button className="si_item bg_gold">
          <Icons.Voucher />
          Voucher
        </button> */}
      </div>
    </CustomerActFRegisItemStyled>
  );
};

CustomerActFRegisItem.propTypes = {};

export default CustomerActFRegisItem;
