import React from "react";

export default function renderIcon({
  width = 24,
  height = 24,
  color = "#4b1e78",
}) {
  return (
    <svg
      id="user-add"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        id="Path_4143"
        data-name="Path 4143"
        d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z"
        fill={color}
      />
      <path
        id="Path_4144"
        data-name="Path 4144"
        d="M9,12A6,6,0,1,0,3,6a6,6,0,0,0,6,6ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z"
        fill={color}
      />
      <path
        id="Path_4145"
        data-name="Path 4145"
        d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,1,0,2,0,7,7,0,1,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z"
        fill={color}
      />
    </svg>
  );
}
