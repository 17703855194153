import React from 'react';

export default function renderIcon({
  width = 43.421,
  height = 43.421,
  color = '#4b1e78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 43.421 43.421"
    >
      <g
        id="Group_307"
        data-name="Group 307"
        transform="translate(-19.354 -849.726)"
      >
        <path
          id="Path_104"
          data-name="Path 104"
          d="M1917.737,1453.921a20.21,20.21,0,1,1-20.178-20.213A20.21,20.21,0,0,1,1917.737,1453.921Z"
          transform="translate(-1856.462 -582.482)"
          fill="none"
          stroke={color}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <path
          id="Path_105"
          data-name="Path 105"
          d="M1892.018,1463.409q0,1.9,0,3.8a1.308,1.308,0,0,0,1.4,1.4q1.271.007,2.541,0a1.3,1.3,0,0,0,1.4-1.392q0-3.184,0-6.369a1.044,1.044,0,0,1,1.182-1.186q1.822,0,3.643,0a1.033,1.033,0,0,1,1.17,1.163q0,3.184,0,6.368a1.311,1.311,0,0,0,1.408,1.416q1.056,0,2.112,0a1.318,1.318,0,0,0,1.434-1.452q0-3.75,0-7.5a1.017,1.017,0,0,1,1.112-1.127c.429-.008.858.007,1.286-.008a1.265,1.265,0,0,0,1.2-.8,1.218,1.218,0,0,0-.3-1.376q-5.123-5.687-10.238-11.38a1.449,1.449,0,0,0-2.4,0q-5.113,5.674-10.228,11.348a1.236,1.236,0,0,0-.318,1.4,1.282,1.282,0,0,0,1.263.8c.418.006.837-.005,1.255,0a1.02,1.02,0,0,1,1.081,1.1q0,1.9,0,3.8"
          transform="translate(-1859.099 -585.025)"
          fill={color}
        />
      </g>
    </svg>
  );
}
