import React from "react";
import { connect } from "react-redux";
import { SplashContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import queryString from "query-string";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { setReduxWallet } from "store/reducers/walletReducer";
import liff from "@line/liff";
import { Displays } from "components";

class SplashContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.initialLineLiff();
  }

  initialLineLiff = async () => {
    await liff
      .init({ liffId: process.env.REACT_APP_LINE_LIFF_ID })
      .catch((err) => {
        throw err;
      });
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const accessToken = liff.getAccessToken();
      const getProfile = await liff.getProfile();
      let params = {
        access_token: accessToken,
        user_id: getProfile.userId,
      };
      let res = await userService.POST_LINE_LOGIN(params);
      if (res && res.status === 200) {
        localStorage.setItem("token", res.token);
        this.fetchMyInfomation();
      } else {
        this.props.router.navigate(
          `${ROUTE_PATH.REGISTER}?is_regis=true&lineID=${res.data.lineRefID}&avatar=${getProfile.pictureUrl}`
        );
      }
    }
  };

  fetchMyInfomation = async () => {
    let { page } = queryString.parse(this.props.router.location.search);
    let resArr = await Promise.all([
      userService.GET_MY_PROFILE(),
      userService.GET_MY_WALLET(),
    ]);
    if (resArr) {
      this.props.setReduxAuthen(resArr[0].data);
      this.props.setReduxWallet(resArr[1].data);
      this.props.router.navigate(!page ? ROUTE_PATH.HOME : ROUTE_PATH[page]);
    }
  };

  render() {
    return (
      <SplashContainerStyled>
        <Displays.Loading />
        {/* <img className="logo" alt="logo" src="/assets/images/logos/logo.png" /> */}
      </SplashContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SplashContainer));
