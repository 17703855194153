import { useState, useEffect } from 'react';
import { PopupWidgetStyled } from './styled';
import { userService } from 'apiServices';
import { useNavigate } from 'react-router-dom';
import { useComponentVisible } from 'utils/functions/detectClickOutside';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Icons } from 'components';
import { IMG_PATH } from 'utils/constants/imgPath';

const PopupWidget = () => {
  const navigate = useNavigate();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  const [_data, _setData] = useState();

  useEffect(() => {
    _fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchData = async () => {
    let res = await userService.GET_POPUP_LIST();
    if (res && res.status === 200) {
      if (res.data.docs.length > 0) {
        _setData(res.data);
        setIsComponentVisible(true);
      }
    }
  };

  const _handleClickNoti = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const _handleClickNotiItem = (e) => {
    userService.PUT_POPUP_READ_DETAIL(e._id);
    _handleClickNoti();
    switch (e.feature_type) {
      case '1':
        navigate(`${ROUTE_PATH.NEW}/${e.feature_id}`);
        break;
      case '2':
        navigate(`${ROUTE_PATH.ACTIVITIES}/${e.feature_id}`);
        break;
      case '3':
        break;
      default:
        break;
    }
  };

  return (
    isComponentVisible && (
      <PopupWidgetStyled>
        <div ref={ref}>
          <div
            className="btn_popup"
            onClick={() => _handleClickNotiItem(_data.docs[0])}
          >
            <div className="btn_close_popup">
              <Icons.Close color="black" />
            </div>
            <img
              className="popup_img"
              alt="popup"
              src={
                _data.docs[0]?.img_path
                  ? IMG_PATH + _data.docs[0]?.img_path
                  : '/assets/images/mockups/activities-1.jpg'
              }
            />
          </div>
        </div>
      </PopupWidgetStyled>
    )
  );
};

export default PopupWidget;
