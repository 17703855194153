import styled from 'styled-components';

export const BottomTabStyled = styled.div`
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  overflow: hidden;
  .bottom_tap_header_layout {
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    display: flex;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .header_item {
      border-right: 2px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      flex: 1;
      height: 57px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S19};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      cursor: pointer;
      &.acitve {
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      &:last-child {
        border-right: 0px;
      }
    }
  }
  .body_tap_body_layout {
    padding: 20px 14px 14px 14px;
    min-height: 225px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
  }

  .theme_standard {
  }
`;
