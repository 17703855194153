import styled from "styled-components";

export const ProductWarranteeContainerStyled = styled.div`
  padding: 60px 16px 104px 16px;
  .label_reccoment {
    margin-top: 20px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
`;
