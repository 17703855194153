import styled from 'styled-components';

export const ScanQRCodeStyled = styled.div`
  .scan_qr_container {
    margin: auto;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    border-radius: 16px;
    max-width: 350px;
    height: 206px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .sq_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
      .sq_label {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S24};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }
  .scan_show {
    text-align: center;
  }

  .theme_standard {
  }
`;
