import styled from 'styled-components';

export const AlertLabelStyled = styled.div`
  .advertise_container {
    .at_block {
      border-radius: 12px;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      height: 215px;
      display: flex;
      justify-content: center;
      align-items: center;
      .at_label {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S25};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
    }
    .advertise_btn {
      margin: auto;
      margin-top: 65px;
      max-width: 145px;
    }
  }

  .theme_standard {
  }
`;
