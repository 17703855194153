import React from 'react';

export default function renderIcon({
  width = 27.181,
  height = 16.882,
  color = '#4B1E78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.181 16.882"
    >
      <g id="Group_256" data-name="Group 256" transform="translate(0 0)">
        <path
          id="Path_3818"
          data-name="Path 3818"
          d="M404.04,7515.56a1.114,1.114,0,0,1,1.113,1.113v12.657a1.115,1.115,0,0,1-1.113,1.113H381.086a1.115,1.115,0,0,1-1.114-1.113v-12.657a1.114,1.114,0,0,1,1.114-1.113H404.04m0-1H381.086a2.113,2.113,0,0,0-2.114,2.112v12.657a2.113,2.113,0,0,0,2.114,2.113H404.04a2.113,2.113,0,0,0,2.113-2.113v-12.657a2.113,2.113,0,0,0-2.113-2.112Z"
          transform="translate(-378.972 -7514.56)"
          fill={color}
        />
        <g
          id="Group_253"
          data-name="Group 253"
          transform="translate(16.828 0.278)"
        >
          <path
            id="Path_3819"
            data-name="Path 3819"
            d="M401.105,7517.916a.5.5,0,0,1-.5-.5v-2a.5.5,0,1,1,1,0v2A.5.5,0,0,1,401.105,7517.916Z"
            transform="translate(-400.605 -7514.917)"
            fill={color}
          />
          <path
            id="Path_3820"
            data-name="Path 3820"
            d="M401.105,7527.259a.5.5,0,0,1-.5-.5v-3.839a.5.5,0,0,1,1,0v3.839A.5.5,0,0,1,401.105,7527.259Z"
            transform="translate(-400.605 -7516.584)"
            fill={color}
          />
          <path
            id="Path_3821"
            data-name="Path 3821"
            d="M401.105,7535.288a.5.5,0,0,1-.5-.5v-2a.5.5,0,1,1,1,0v2A.5.5,0,0,1,401.105,7535.288Z"
            transform="translate(-400.605 -7518.776)"
            fill={color}
          />
        </g>
        <path
          id="Path_3822"
          data-name="Path 3822"
          d="M408.337,7521.485h-3.545a.5.5,0,0,1,0-1h3.545a.5.5,0,1,1,0,1Z"
          transform="translate(-384.596 -7515.876)"
          fill={color}
        />
        <path
          id="Path_3823"
          data-name="Path 3823"
          d="M408.337,7526.1h-3.545a.5.5,0,0,1,0-1h3.545a.5.5,0,1,1,0,1Z"
          transform="translate(-384.596 -7516.902)"
          fill={color}
        />
        <path
          id="Path_3824"
          data-name="Path 3824"
          d="M408.337,7530.718h-3.545a.5.5,0,0,1,0-1h3.545a.5.5,0,1,1,0,1Z"
          transform="translate(-384.596 -7517.927)"
          fill={color}
        />
        <g
          id="Group_255"
          data-name="Group 255"
          transform="translate(2.413 3.382)"
        >
          <g id="Group_254" data-name="Group 254">
            <path
              id="Path_3825"
              data-name="Path 3825"
              d="M382.074,7521.6a2.783,2.783,0,1,1,2.8,2.7A2.552,2.552,0,0,1,382.074,7521.6Zm3.925.014c0-.975-.452-1.412-1.143-1.412s-1.143.437-1.143,1.4c0,.988.466,1.426,1.158,1.426S386,7522.592,386,7521.618Zm4.674-2.711h1.468l-6.594,10.407h-1.483Zm-2.1,7.7a2.783,2.783,0,1,1,2.8,2.7A2.551,2.551,0,0,1,388.569,7526.6Zm3.925.014c0-.975-.452-1.4-1.143-1.4s-1.143.423-1.143,1.384c0,.989.466,1.426,1.158,1.426S392.494,7527.591,392.494,7526.617Z"
              transform="translate(-382.074 -7518.907)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
