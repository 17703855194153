import styled from "styled-components";

export const PopupWidgetStyled = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.COLORS.BLACK_1}66;
  z-index: 101;
  .btn_popup {
    position: relative;
    padding: 10px;
    cursor: pointer;
    .btn_close_popup {
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 36px;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.GRAY_1};
      z-index: 103;
    }
    .popup_img {
      max-width: 300px;
      width: 100%;
      height: auto;
    }
  }
`;
