import React from "react";
import { PhoneFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { Buttons, Inputs } from "components";

const PhoneForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    phone_number: yup.string().required("Phone number is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(["common", "register"]);

  return (
    <PhoneFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t("Mobile number", { ns: "register" })}
                errors={errors.phone_number?.message}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label={t("Edit", { ns: "common" })} />
      </form>
    </PhoneFormStyled>
  );
};

PhoneForm.propTypes = {};

export default PhoneForm;
