import { PageLayoutStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { LanguageWidget } from "widgets";

const PageLayout = () => {
  return (
    <PageLayoutStyled>
      <div className="lang_mainlayout">
        <LanguageWidget />
      </div>
      <Outlet />
    </PageLayoutStyled>
  );
};

export default PageLayout;
