import React from 'react';
import { ProductWarranteeContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { MemberSwitchTypeWidget, MyPointWidget } from 'widgets';
import { Displays, Forms, Headers, Icons } from 'components';

class ProductWarranteeContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleSubmit = () => {
    // this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTEE}/id`);
  };

  handleSuccessScan = async (sn) => {
    const split = sn.split('/');
    const serialNo = split[split.length - 1];
    this.setState({
      isBgLoading: true,
    });
    let res = await userService.GET_PRODUCT_WARRANTEE_ACTIVE_BY_SN(serialNo);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTEE}/${serialNo}`);
    } else {
      this.setState({
        isBgLoading: false,
      });
      toast.error('เลขซีเรียลไม่ถูกต้อง');
    }
  };

  render() {
    const { t } = this.props;
    return (
      <ProductWarranteeContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={'25px'}
          icon={<Icons.SubMenuProductWarrantee color="#fff" />}
          topLabel={'Product Warranty'}
          bottomLabel={'รับประกันสินค้า'}
        />
        <MemberSwitchTypeWidget type="2" />
        <Forms.ScanQRCodeForm
          onSuccessScan={this.handleSuccessScan}
          onSubmit={this.handleSubmit}
        />
        <div className="label_reccoment">
          {t('Scan the QR Code on the product box', { ns: 'common' })}
        </div>
      </ProductWarranteeContainerStyled>
    );
  }
}

export default withTranslation(['profile'])(
  withRouter(ProductWarranteeContainer)
);
