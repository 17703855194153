import styled from 'styled-components';

export const PointRedeemQRCodeContainerStyled = styled.div`
  padding-bottom: 20px;
  .prq_btn_wrap {
    margin: auto;
    margin-top: 20px;
    width: fit-content;
  }
`;
