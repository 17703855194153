import styled from 'styled-components';

export const HeaderIconTitleStyled = styled.div`
  margin-bottom: ${({ mb }) => (mb ? mb : '18px')};
  .header_icon_title_container {
    display: flex;
    align-items: center;
    column-gap: 12px;
    .hi_left_col {
      .circle {
        border-radius: 64px;
        width: 64px;
        height: 64px;
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .hi_right_col {
      .lc_title {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      }
      .lc_suv_title {
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
        .tl_multi_label {
          line-height: 24px;
        }
      }
    }
  }

  .theme_standard {
  }

  .theme_head_only {
    .header_icon_title_container {
      .hi_right_col {
        .lc_title {
          font-size: ${({ theme }) => theme.FONT.SIZE.S26};
        }
      }
    }
  }
`;
