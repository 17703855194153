import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { RewardStatusStyled } from './styled';
import {
  RENDER_REWARD_STATUS,
  RENDER_REWARD_VOUCHER_GIFT_STATUS,
} from 'utils/functions/reward';

const RewardStatus = ({ theme_standard, type, status }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const rewardStatusRender =
    type === '1'
      ? RENDER_REWARD_STATUS(status)
      : RENDER_REWARD_VOUCHER_GIFT_STATUS(status);
  return (
    <RewardStatusStyled bgColor={rewardStatusRender.bgColor}>
      <div className={customClass}>
        <div className="reward_status_container">
          {rewardStatusRender.label}
        </div>
      </div>
    </RewardStatusStyled>
  );
};

RewardStatus.propTypes = {};

export default RewardStatus;
