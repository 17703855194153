import React from 'react';

export default function renderIcon({ width = 111, height = 111 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 111 111.001"
    >
      <g
        id="Group_611"
        data-name="Group 611"
        transform="translate(-2784.677 -1429.673)"
      >
        <path
          id="Path_1820"
          data-name="Path 1820"
          d="M2840.143,1540.673a55.5,55.5,0,1,1,55.534-55.57A55.348,55.348,0,0,1,2840.143,1540.673Zm-11.738-30.005a.331.331,0,0,0,.361.295l-.075.012.012-.075c.08.4.389.393.7.388l.065-.017a.742.742,0,0,0,.773.3c.139-.127.263-.068.385.029.525.614,1.313.11,1.957.52a4.391,4.391,0,0,0,5.427-.148c1.9-1.5,3.7-3.122,5.523-4.712,2.458-2.143,4.886-4.319,7.349-6.457,3.269-2.839,6.566-5.644,9.836-8.481,2.463-2.137,4.855-4.358,7.364-6.439,3.623-3,7.137-6.128,10.619-9.291a3.374,3.374,0,0,0,.93-1.363,4.692,4.692,0,0,0-.966-5.146c-1.147-1.285-2.213-2.643-3.382-3.906-1.677-1.812-3.081-4.164-6.176-3.482l.027.016-.311-.309c-.713-.665-1.436-1.321-2.139-2-2.271-2.182-4.941-2.176-7.177.05-3.544,3.528-7.07,7.076-10.617,10.6q-5.713,5.677-11.45,11.329l-.34.305.036-.034-.315.315.033-.033-.313.319.036-.036-.319.313.034-.034-.315.316.034-.034-.316.315.034-.035-.316.316.034-.035-.315.317.035-.035-.315.317.035-.035-.315.319.036-.036-.318.318.036-.036-.318.316.036-.036-.315.32.033-.023a1.428,1.428,0,0,1-1.141-.431,48.179,48.179,0,0,1-3.806-4.464c-2.07-3.107-4.639-4.9-8.523-4.124a3.213,3.213,0,0,1-.42.03l.037.025-.36-.289.055.06a1.073,1.073,0,0,0-1.621-.482h-1.442c-.319-.253-.6-.272-.817.13l.034-.023a.591.591,0,0,0-.612.263l-.1.013-.452.3.03-.016c-.267,0-.5.049-.553.374l.064-.058c-.277.011-.358.21-.416.432l-.051.051c-.218.053-.416.13-.448.395l.045-.052-.328.328.052-.046c-.267.034-.342.235-.395.455l-.045.046c-.221.053-.419.132-.451.4l.046-.053-.329.33.048-.028q-.68-.082-.585.6l.026-.05-.337.321.047-.048-.328.329.047-.048-.321.336.049-.026c-.447-.063-.644.134-.6.578l.029-.049-.328.33.049-.029c-.447-.043-.642.157-.581.6l.026-.051-.337.322.048-.047-.331.324.049-.046c-.259.044-.333.242-.387.459l-.048.048c-.222.06-.425.141-.422.425l-.018-.072.074,0c-.35.055-.378.316-.38.594-1.114,2.555-.515,4.644,1.49,6.568,3.514,3.37,6.917,6.857,10.35,10.312,3,3.018,5.977,6.059,8.964,9.09l.348.345Z"
          transform="translate(0 0)"
          fill="#02b653"
        />
        <path
          id="Path_1821"
          data-name="Path 1821"
          d="M2871.445,1551.138l.1-.014a.6.6,0,0,0,.612-.263l-.034.023.817-.13h1.442l1.621.482-.055-.06.36.289-.036-.025q4.638,4.64,9.277,9.281c2.416,2.415,2.417,2.414,4.613-.291l-.033.023.315-.32-.036.036.318-.316-.036.036.318-.317-.036.036.315-.319-.035.035.315-.316-.035.035.315-.317-.035.035.316-.316-.034.035.316-.315-.034.034.315-.315-.034.033.319-.312-.035.035.313-.319-.034.033.315-.315-.036.033.34-.3q5.726-5.663,11.45-11.329c3.547-3.525,7.072-7.073,10.617-10.6,2.235-2.225,4.906-2.232,7.177-.05.7.675,1.426,1.331,2.139,2l.31.309-.026-.016c.865.912,1.719,1.836,2.6,2.734,2.646,2.705,2.669,5.073-.03,7.776q-18.255,18.287-36.525,36.561a5.864,5.864,0,0,1-4.526,1.833c-.122-.1-.247-.156-.386-.029-.183-.291-.482-.284-.772-.3l-.066.017a.786.786,0,0,0-.695-.388l-.012.075.074-.012a.362.362,0,0,0-.36-.295l.048.056-.347-.345c-2.987-3.031-5.964-6.072-8.964-9.09-3.433-3.454-6.836-6.942-10.35-10.312-2.006-1.923-2.6-4.012-1.49-6.568a.692.692,0,0,0,.38-.594l-.074,0,.018.071a.553.553,0,0,0,.422-.424l.048-.048a.509.509,0,0,0,.387-.459l-.049.046a.343.343,0,0,0,.331-.323l-.048.046a.349.349,0,0,0,.337-.321l-.026.051a.782.782,0,0,0,.581-.6l-.049.029a.337.337,0,0,0,.327-.33l-.029.049a.809.809,0,0,0,.6-.578l-.049.025a.347.347,0,0,0,.321-.336l-.046.047a.347.347,0,0,0,.328-.328l-.047.047a.349.349,0,0,0,.337-.32l-.026.05a.858.858,0,0,0,.585-.6l-.048.028a.342.342,0,0,0,.329-.329l-.046.053a.529.529,0,0,0,.451-.4l.045-.046a.515.515,0,0,0,.394-.454l-.051.046a.348.348,0,0,0,.327-.328l-.045.051a.5.5,0,0,0,.448-.394l.051-.051a.5.5,0,0,0,.416-.432l-.063.058a.619.619,0,0,0,.553-.374l-.03.016A.475.475,0,0,0,2871.445,1551.138Z"
          transform="translate(-56.399 -74.381)"
          fill="#fefefe"
        />
        <path
          id="Path_1822"
          data-name="Path 1822"
          d="M2948.03,1595.682a5.865,5.865,0,0,0,4.526-1.833q18.243-18.3,36.525-36.561c2.7-2.7,2.676-5.071.03-7.776-.879-.9-1.733-1.822-2.6-2.734,3.095-.682,4.5,1.67,6.175,3.482,1.169,1.263,2.235,2.622,3.382,3.906a4.691,4.691,0,0,1,.967,5.146,3.378,3.378,0,0,1-.93,1.363c-3.482,3.162-7,6.286-10.619,9.291-2.509,2.081-4.9,4.3-7.364,6.439-3.27,2.837-6.568,5.643-9.837,8.481-2.462,2.137-4.891,4.314-7.349,6.457-1.824,1.591-3.624,3.215-5.523,4.712a4.391,4.391,0,0,1-5.428.148C2949.343,1595.792,2948.554,1596.3,2948.03,1595.682Z"
          transform="translate(-117.409 -84.081)"
          fill="#059143"
        />
        <path
          id="Path_1823"
          data-name="Path 1823"
          d="M2923.7,1606.609c-2.2,2.7-2.2,2.706-4.613.291q-4.64-4.639-9.277-9.281a3.208,3.208,0,0,0,.419-.03c3.885-.775,6.453,1.017,8.524,4.124a48.174,48.174,0,0,0,3.806,4.464A1.428,1.428,0,0,0,2923.7,1606.609Z"
          transform="translate(-89.937 -120.56)"
          fill="#059143"
        />
        <path
          id="Path_1824"
          data-name="Path 1824"
          d="M2904.711,1595.718l-1.621-.482A1.073,1.073,0,0,1,2904.711,1595.718Z"
          transform="translate(-85.108 -118.861)"
          fill="#059143"
        />
        <path
          id="Path_1825"
          data-name="Path 1825"
          d="M2874.8,1612.2a.809.809,0,0,1-.6.578C2874.152,1612.332,2874.35,1612.135,2874.8,1612.2Z"
          transform="translate(-64.335 -131.18)"
          fill="#059143"
        />
        <path
          id="Path_1826"
          data-name="Path 1826"
          d="M2879.814,1607.191a.859.859,0,0,1-.585.6Q2879.134,1607.109,2879.814,1607.191Z"
          transform="translate(-67.95 -127.584)"
          fill="#059143"
        />
        <path
          id="Path_1827"
          data-name="Path 1827"
          d="M2871.8,1615.164a.782.782,0,0,1-.581.6C2871.163,1615.321,2871.358,1615.122,2871.8,1615.164Z"
          transform="translate(-62.197 -133.316)"
          fill="#059143"
        />
        <path
          id="Path_1828"
          data-name="Path 1828"
          d="M2895.874,1595.25l-.817.13C2895.272,1594.977,2895.555,1595,2895.874,1595.25Z"
          transform="translate(-79.335 -118.875)"
          fill="#059143"
        />
        <path
          id="Path_1829"
          data-name="Path 1829"
          d="M2865.5,1622.173a.692.692,0,0,1-.38.594C2865.119,1622.489,2865.147,1622.228,2865.5,1622.173Z"
          transform="translate(-57.816 -138.358)"
          fill="#059143"
        />
        <path
          id="Path_1830"
          data-name="Path 1830"
          d="M2893.615,1596.1a.6.6,0,0,1-.612.263A.591.591,0,0,1,2893.615,1596.1Z"
          transform="translate(-77.858 -119.615)"
          fill="#059143"
        />
        <path
          id="Path_1831"
          data-name="Path 1831"
          d="M2941.208,1718.471a.786.786,0,0,1,.695.388C2941.6,1718.864,2941.288,1718.873,2941.208,1718.471Z"
          transform="translate(-112.505 -207.571)"
          fill="#059143"
        />
        <path
          id="Path_1832"
          data-name="Path 1832"
          d="M2943.912,1719.791c.29.017.589.01.772.3A.741.741,0,0,1,2943.912,1719.791Z"
          transform="translate(-114.449 -208.52)"
          fill="#059143"
        />
        <path
          id="Path_1833"
          data-name="Path 1833"
          d="M2889.737,1598.073a.619.619,0,0,1-.553.374C2889.232,1598.122,2889.47,1598.076,2889.737,1598.073Z"
          transform="translate(-75.114 -121.036)"
          fill="#059143"
        />
        <path
          id="Path_1834"
          data-name="Path 1834"
          d="M2888.346,1599.2a.5.5,0,0,1-.416.431C2887.989,1599.406,2888.07,1599.207,2888.346,1599.2Z"
          transform="translate(-74.212 -121.843)"
          fill="#059143"
        />
        <path
          id="Path_1835"
          data-name="Path 1835"
          d="M2886.6,1600.91a.5.5,0,0,1-.448.394C2886.189,1601.04,2886.387,1600.964,2886.6,1600.91Z"
          transform="translate(-72.938 -123.075)"
          fill="#059143"
        />
        <path
          id="Path_1836"
          data-name="Path 1836"
          d="M2866.69,1620.909a.553.553,0,0,1-.422.424C2866.266,1621.05,2866.468,1620.969,2866.69,1620.909Z"
          transform="translate(-58.643 -137.449)"
          fill="#059143"
        />
        <path
          id="Path_1837"
          data-name="Path 1837"
          d="M2884.328,1603.133a.515.515,0,0,1-.394.454C2883.987,1603.368,2884.061,1603.167,2884.328,1603.133Z"
          transform="translate(-71.34 -124.673)"
          fill="#059143"
        />
        <path
          id="Path_1838"
          data-name="Path 1838"
          d="M2882.623,1604.912a.528.528,0,0,1-.45.4C2882.2,1605.044,2882.4,1604.965,2882.623,1604.912Z"
          transform="translate(-70.074 -125.952)"
          fill="#059143"
        />
        <path
          id="Path_1839"
          data-name="Path 1839"
          d="M2868.328,1619.106a.509.509,0,0,1-.387.459C2867.994,1619.348,2868.069,1619.15,2868.328,1619.106Z"
          transform="translate(-59.845 -136.153)"
          fill="#059143"
        />
        <path
          id="Path_1840"
          data-name="Path 1840"
          d="M3084.152,1546.354l-.31-.309Z"
          transform="translate(-215.022 -83.641)"
          fill="#059143"
        />
        <path
          id="Path_1841"
          data-name="Path 1841"
          d="M2891.5,1597.08a.474.474,0,0,1-.452.3Z"
          transform="translate(-76.45 -120.322)"
          fill="#059143"
        />
        <path
          id="Path_1842"
          data-name="Path 1842"
          d="M2909.018,1597.508l-.36-.289Z"
          transform="translate(-89.11 -120.422)"
          fill="#059143"
        />
        <path
          id="Path_1843"
          data-name="Path 1843"
          d="M2885.481,1602.13a.348.348,0,0,1-.327.328Z"
          transform="translate(-72.217 -123.952)"
          fill="#059143"
        />
        <path
          id="Path_1844"
          data-name="Path 1844"
          d="M2878.49,1609.154a.349.349,0,0,1-.337.32Z"
          transform="translate(-67.184 -129.001)"
          fill="#059143"
        />
        <path
          id="Path_1845"
          data-name="Path 1845"
          d="M2877.483,1610.125a.348.348,0,0,1-.328.328Z"
          transform="translate(-66.468 -129.698)"
          fill="#059143"
        />
        <path
          id="Path_1846"
          data-name="Path 1846"
          d="M2869.472,1618.12a.343.343,0,0,1-.331.323Z"
          transform="translate(-60.708 -135.445)"
          fill="#059143"
        />
        <path
          id="Path_1847"
          data-name="Path 1847"
          d="M2970.379,1618.06l-.315.315Z"
          transform="translate(-133.245 -135.402)"
          fill="#059143"
        />
        <path
          id="Path_1848"
          data-name="Path 1848"
          d="M2873.471,1614.11a.337.337,0,0,1-.327.33Z"
          transform="translate(-63.585 -132.563)"
          fill="#059143"
        />
        <path
          id="Path_1849"
          data-name="Path 1849"
          d="M2969.385,1619.062l-.312.319Z"
          transform="translate(-132.532 -136.122)"
          fill="#059143"
        />
        <path
          id="Path_1850"
          data-name="Path 1850"
          d="M2968.383,1620.069l-.319.313Z"
          transform="translate(-131.808 -136.846)"
          fill="#059143"
        />
        <path
          id="Path_1851"
          data-name="Path 1851"
          d="M2876.5,1611.124a.347.347,0,0,1-.321.336Z"
          transform="translate(-65.766 -130.416)"
          fill="#059143"
        />
        <path
          id="Path_1852"
          data-name="Path 1852"
          d="M2967.38,1621.061l-.315.315Z"
          transform="translate(-131.09 -137.559)"
          fill="#059143"
        />
        <path
          id="Path_1853"
          data-name="Path 1853"
          d="M2870.483,1617.142a.349.349,0,0,1-.337.321Z"
          transform="translate(-61.43 -134.742)"
          fill="#059143"
        />
        <path
          id="Path_1854"
          data-name="Path 1854"
          d="M2966.38,1622.062l-.316.315Z"
          transform="translate(-130.37 -138.278)"
          fill="#059143"
        />
        <path
          id="Path_1855"
          data-name="Path 1855"
          d="M2965.38,1623.059l-.316.316Z"
          transform="translate(-129.652 -138.995)"
          fill="#059143"
        />
        <path
          id="Path_1856"
          data-name="Path 1856"
          d="M2964.381,1624.058l-.315.317Z"
          transform="translate(-128.934 -139.713)"
          fill="#059143"
        />
        <path
          id="Path_1857"
          data-name="Path 1857"
          d="M2963.385,1625.061l-.315.316Z"
          transform="translate(-128.219 -140.434)"
          fill="#059143"
        />
        <path
          id="Path_1858"
          data-name="Path 1858"
          d="M2962.388,1626.062l-.315.319Z"
          transform="translate(-127.502 -141.153)"
          fill="#059143"
        />
        <path
          id="Path_1859"
          data-name="Path 1859"
          d="M2881.5,1606.142a.342.342,0,0,1-.329.329Z"
          transform="translate(-69.352 -126.836)"
          fill="#059143"
        />
        <path
          id="Path_1860"
          data-name="Path 1860"
          d="M2960.388,1628.066l-.318.316Z"
          transform="translate(-126.062 -142.593)"
          fill="#059143"
        />
        <path
          id="Path_1861"
          data-name="Path 1861"
          d="M2959.391,1629.061l-.315.32Z"
          transform="translate(-125.348 -143.309)"
          fill="#059143"
        />
        <path
          id="Path_1862"
          data-name="Path 1862"
          d="M2939.086,1716.62l.347.345Z"
          transform="translate(-110.98 -206.241)"
          fill="#059143"
        />
        <path
          id="Path_1863"
          data-name="Path 1863"
          d="M2940.149,1717.648a.361.361,0,0,1,.36.295A.331.331,0,0,1,2940.149,1717.648Z"
          transform="translate(-111.744 -206.98)"
          fill="#059143"
        />
        <path
          id="Path_1864"
          data-name="Path 1864"
          d="M2971.394,1617.095l-.34.3Z"
          transform="translate(-133.957 -134.708)"
          fill="#059143"
        />
        <path
          id="Path_1865"
          data-name="Path 1865"
          d="M2961.39,1627.066l-.318.317Z"
          transform="translate(-126.783 -141.875)"
          fill="#059143"
        />
      </g>
    </svg>
  );
}
