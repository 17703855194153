export const RENDER_REWARD_STATUS = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ดำเนินการ',
        bgColor: '#4B1E78',
      };
    case '2':
      return {
        label: 'ต้องการใช้',
        bgColor: '#4B1E78',
      };
    case '3':
      return {
        label: 'ใช้แล้ว',
        bgColor: '#B3B3B3',
      };
    default:
      break;
  }
};

export const RENDER_REWARD_VOUCHER_GIFT_STATUS = (key) => {
  switch (key) {
    case '1':
      return {
        label: 'ดำเนินการ',
        bgColor: '#4B1E78',
      };
    case '2':
      return {
        label: 'กรอกที่อยู่',
        bgColor: '#4B1E78',
      };
    case '3':
      return {
        label: 'จัดส่ง',
        bgColor: '#4B1E78',
      };
    case '4':
      return {
        label: 'จัดส่ง',
        bgColor: '#4B1E78',
      };
    case '5':
      return {
        label: 'สำเร็จ',
        bgColor: '#B3B3B3',
      };
    default:
      break;
  }
};
