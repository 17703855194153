import styled from "styled-components";

export const ActivitiesContainerStyled = styled.div`
  padding: 60px 20px 104px 20px;
  .fav_row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    .activities_item {
      margin-bottom: 28px;
      flex-basis: calc(50% - 10px);
      overflow: hidden;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
