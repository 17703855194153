import React from "react";

export default function renderIcon({
  width = 24.002,
  height = 24.002,
  color = "#4b1e78",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.002 24.002"
    >
      <path
        id="bank"
        d="M24,23a1,1,0,0,1-1,1H1a1,1,0,0,1,0-2H23A1,1,0,0,1,24,23ZM.291,8.552A2.443,2.443,0,0,1,.444,5.986a4.716,4.716,0,0,1,1.668-1.5l7.5-3.9a5.174,5.174,0,0,1,4.774,0l7.5,3.907a4.716,4.716,0,0,1,1.668,1.5,2.443,2.443,0,0,1,.153,2.566A2.713,2.713,0,0,1,21.292,10H21v8h1a1,1,0,0,1,0,2H2a1,1,0,0,1,0-2H3V10H2.708A2.713,2.713,0,0,1,.291,8.552ZM5,18H8V10H5Zm5-8v8h4V10Zm9,0H16v8h3ZM2.063,7.625A.717.717,0,0,0,2.708,8H21.292a.717.717,0,0,0,.645-.375.452.452,0,0,0-.024-.5,2.7,2.7,0,0,0-.949-.864l-7.5-3.907a3.176,3.176,0,0,0-2.926,0l-7.5,3.907a2.712,2.712,0,0,0-.949.865.452.452,0,0,0-.026.5Z"
        transform="translate(0.002 0.002)"
        fill={color}
      />
    </svg>
  );
}
