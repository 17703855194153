import styled from "styled-components";

export const ActQuestionContainerStyled = styled.div`
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  min-width: 300px;
  max-height: 64vh;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  overflow: auto;
  .action_row {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 16px;
    .btn_col {
      width: 100%;
      max-width: 177px;
    }
  }
`;
