import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LogoStyled } from './styled';

const Logo = ({ theme_standard, mb, mbNode, nodeLeft, nodeRight }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LogoStyled mb={mb} mbNode={mbNode}>
      <div className={customClass}>
        <div className="h_logo_figure">
          <img
            className="header_img"
            alt="misumi header"
            src="/assets/images/logos/logo.png"
          />
        </div>
        {(nodeLeft || nodeRight) && (
          <div className="header_info_block">
            <div className="hib_col">{nodeLeft}</div>
            <div className="hib_col">{nodeRight}</div>
          </div>
        )}
      </div>
    </LogoStyled>
  );
};

Logo.propTypes = {};

export default Logo;
