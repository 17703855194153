import styled from "styled-components";

export const LanguageWidgetStyled = styled.div`
  .btn_lang {
    padding: 4px 8px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1}1C;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: pointer;
    &.left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.active {
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
  }
`;
