import styled from 'styled-components';

export const ProductWarranteeNoLoginFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 15px;
    &.mb_12 {
      margin-bottom: 12px;
    }
    &.mb_82 {
      margin-bottom: 82px;
    }
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 145px;
  }
`;
