import React from "react";
import { InputCheckBoxStyled } from "./styled";
import cx from "classnames";

export const InputCheckBox = ({
  theme_standard_input_check_box,
  label,
  value,
  checked,
  fontSize,
  fontFamily,
  fontColor,
  onChange,
  onChangeCustom,
  errors,
  disabled,
}) => {
  const customClass = cx({
    theme_standard_input_check_box: theme_standard_input_check_box,
  });

  return (
    <InputCheckBoxStyled
      disabled={disabled}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontColor={fontColor}
    >
      <div className={customClass}>
        <label className="container">
          <input
            type="checkbox"
            name={value}
            value={value}
            checked={value}
            onChange={(e) => {
              onChange(e);
              onChangeCustom && onChangeCustom(e);
            }}
            disabled={disabled}
          />
          <span className="checkmark"></span>
          {label && <span className="lable_show">{label}</span>}
        </label>
        {errors && <div className="errors">{errors}</div>}
      </div>
    </InputCheckBoxStyled>
  );
};

export default InputCheckBox;
