import styled from "styled-components";

export const SystemLayoutStyled = styled.div`
  position: relative;
  margin: auto;
  border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  min-height: calc(100vh - 2px);
  background-color: ${({ theme }) => theme.COLORS.WHITE_1};
  .lang_mainlayout {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }
  .notification_mainlayout {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }
  .body_mainlayout {
  }
  .menu_mainlayout {
    position: fixed;
    bottom: 0px;
    margin: auto;
    width: 100%;
    max-width: ${({ theme }) => theme.SCREENS.MOBILE};
    z-index: 99;
  }
`;
