import styled from 'styled-components';

export const ProductWarranteeNotLoginSuccessContainerStyled = styled.div`

  .recomment_label {
    margin-top: 28px;
    margin-bottom: 100px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .btn_wrap {
    margin: auto;
    width: fit-content;
  }
`;
