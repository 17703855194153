import React from 'react';
import { TermConditionContainerStyled } from './styled';
import { Buttons, Cards } from 'components';

class TermConditionContainer extends React.Component {
  render() {
    const { onClose } = this.props;
    return (
      <TermConditionContainerStyled>
        <Cards.ConditionCard
          title="เงื่อนไขการรับประกัน"
          footerNode={<Buttons.BgStandard label={'ตกลง'} onClick={onClose} />}
        >
          <h4>
            1. บริษัท สยามดนตรียามาฮ่า จํากัด
            รับประกันสินค้าจะปลอดจากความชำรุดบกพร่องภายใต้การใช้งานปกติ
            หรือรับประกันสินค้าว่าจะปราศจากความชำรุดบกพร่องจากโรงงานผลิต
            ตลอดระยะเวลาการรับประกัน
            โดยนับจากวันที่ซื้อตามวันที่แสดงในต้นฉบับของใบเสร็จที่ซื้อสินค้า
          </h4>
          <h4>
            2. บริษัทฯ ขอสงวนสิทธิ์ ในการยกเว้น
            การรับประกันนี้ไม่ครอบคลุมถึงความเสียหายหรือการชำรุดบกพร่องที่เกิดขึ้นมาจากสาเหตุ
            ดังนี้ต่อไปนี้
          </h4>
          <ul>
            <li>
              หมายเลขเครื่องของสินค้าถูกแก้ไข ลบ ขีดฆ่าหรือฉีกขาด
              และใบรับประกันสินค้าไม่ระบุวันที่ซื้อสินค้า หรือถูกแก้ไข
              ไม่ว่ากรณีใดๆทั้งสิ้น
            </li>
            <li>
              ความเสียหายอันเกิดจากอุบัติเหตุ การใช้งานที่ผิดวิธี
              การใช้กระแสไฟฟ้าที่ไม่เหมาะสมการติดตั้งหรือการดัดแปลงที่ไม่เหมาะสม
            </li>
            <li>
              ความเสียหายที่ผิดปกติอันเกิดจากของเหลวหกใส่สินค้า
              หรือสัตว์และแมลงเข้าไปในสินค้า
            </li>
            <li>
              ความเสียหายอันเกิดจากภัยธรรมชาติ เช่น ไฟไหม้ น้าท่วม ฟ้าผ่า
              แผ่นดินไหว
            </li>
            <li>
              ความเสียหายตามอายุใช้งาน เช่น รอยขูดขีด รอยแตก รอยกระแทก
              รอยเปรอะเปื้อน รอยขึ้นสนิม บนส่วนประกอบภายนอกของสินค้า
            </li>
            <li>
              ส่วนประกอบหรืออะไหล่ซึ่งถูกถอดเปลี่ยน ดัดแปลง
              หรือซ่อมแซมจากพนักงานที่ไม่ใช่เจ้าหน้าที่ของบริษัท
              สยามดนตรียามาฮ่า จำกัด และ /หรือศูนย์บริการที่มิได้รับการแต่งตั้ง
            </li>
            <li>
              สินค้าที่เป็นตัวสาธิต
              และสินค้าที่ขายตามสภาพที่ไม่อยู่ในเงื่อนไขการรับประกันตามที่ฝ่ายขายได้แจ้งไว้
            </li>
            <li>
              บริษัทฯไม่รับผิดชอบค่าขนส่งในการนำสินค้าเข้ารับประกันที่ศูนย์บริการฯ
              ศูนย์บริการแต่งตั้ง หรือผู้แทนจำหน่ายของบริษัทฯ
            </li>
          </ul>
          <h4>
            3. โปรดแสดงหลักฐานการลงทะเบียนในระบบ SIAM MUSIC MEMBERS หรือ
            ใบรับประกันสินค้าพร้อมใบเสร็จรับเงินจากร้านค้าในการขอรับประกันสินค้าซึ่งมีหมายเลขเครื่องที่ตรงกันเท่านั้น
          </h4>
          <h4>
            4. การรับประกันนี้ใช้ได้เฉพาะกับผลิตภัณฑ์และอุปกรณ์เสริมที่ซื้อจาก
            บริษัท สยามดนตรียามาฮ่า จํากัด
            หรือตัวแทนจำหน่ายที่ได้รับอนุญาตเท่านั้น
          </h4>
          <h4>
            5. การรับประกันนี้ครอบคลุมเฉพาะส่วนประกอบภายในเท่านั้น
            ไม่รวมส่วนประกอบที่เป็นไม้และพลาสติกและรอยขีดข่วนบนส่วนประกอบภายนอก
          </h4>
        </Cards.ConditionCard>
      </TermConditionContainerStyled>
    );
  }
}

export default TermConditionContainer;
