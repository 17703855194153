import { MyPointWidgetStyled } from "./styled";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MyPointWidget = ({ isGame }) => {
  const { t } = useTranslation(["common"]);
  const { walletRedux } = useSelector((state) => state);
  return (
    <MyPointWidgetStyled>
      <div className="my_point_label">
        {isGame ? "Your coin" : t("Your points", { ns: "common" })}
      </div>
      <div className="my_point">
        {isGame ? walletRedux?.coin : walletRedux?.point}
      </div>
    </MyPointWidgetStyled>
  );
};

export default MyPointWidget;
