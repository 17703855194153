import React from 'react';
import { LoginContainerStyled } from './styled';
import { connect } from 'react-redux';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import queryString from 'query-string';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { setReduxWallet } from 'store/reducers/walletReducer';
import { Forms } from 'components';

class LoginContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      this.fetchMyInfomation();
    } else {
      toast.error('Login failed');
    }
  };

  fetchMyInfomation = async () => {
    let { page } = queryString.parse(this.props.router.location.search);
    let resArr = await Promise.all([
      userService.GET_MY_PROFILE(),
      userService.GET_MY_WALLET(),
    ]);
    if (resArr) {
      this.props.setReduxAuthen(resArr[0].data);
      this.props.setReduxWallet(resArr[1].data);
      this.props.router.navigate(!page ? ROUTE_PATH.HOME : ROUTE_PATH[page]);
    }
  };

  render() {
    return (
      <LoginContainerStyled>
        <Forms.LoginForm onSubmit={this.handleSubmit} />
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
