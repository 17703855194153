export const RENDER_ACITIVITY_FORM_STATUS = (key) => {
  switch (key) {
    case "1":
      return {
        label: "Waiting",
        color: "#FEDB04",
      };
    case "2":
      return {
        label: "Reject",
        color: "#EA9027",
      };
    case "3":
      return {
        label: "Cancel",
        color: "#EA9027",
      };
    case "4":
      return {
        label: "Approved",
        color: "#0FB863",
      };
    default:
      return false;
  }
};
