import styled from 'styled-components';

export const MenuBarWidgetStyled = styled.div`
  height: 85px;
  display: flex;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .menu_wrap {
    border-top: 1.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    border-right: 1.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
    &.active {
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      .menu_item {
        .mi_label {
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
    .menu_item {
      text-align: center;
      .mi_icon_wrap {
        margin: auto;
        width: fit-content;
        height: 48px;
      }
      .muti_label_block {
        line-height: 10px;
      }
      .mi_label {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S11};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
      }
    }
  }
`;
