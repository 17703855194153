import styled from 'styled-components';

export const PointRedeemDetailContainerStyled = styled.div`
  padding-bottom: 20px;
  .reward_wrap {
    margin-bottom: 76px;
  }
  .btn_wrap {
    margin: auto;
    margin-top: 30px;
    max-width: 152px;
  }
`;
