import styled from 'styled-components';

export const TermConditionContainerStyled = styled.div`
  border-radius: 38px;
  padding: 20px 36px 30px 36px;
  max-width: 370px;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  color: ${({ theme }) => theme.COLORS.GRAY_2};
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  .cdc_header {
    margin-bottom: 6px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .cdc_body {
    margin-bottom: 18px;
  }
  .cdc_footer {
  }


`;
