import React from "react";
import { ProfileContainerStyled } from "./styled";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { Outlet } from "react-router-dom";
import { MemberSwitchTypeWidget, MyPointWidget } from "widgets";
import { Displays, Headers } from "components";

class ProfileContainer extends React.Component {
  state = {};

  componentDidMount() {
    const { data } = this.props;
    this.handleClickRoute(data[0]);
  }

  handleClickRoute = (e) => {
    this.setState({
      active: e.key,
    });
    this.props.router.navigate(e.path);
  };

  render() {
    const { authenRedux, data, t } = this.props;
    const { active } = this.state;
    return (
      <ProfileContainerStyled>
        <Headers.Logo
          nodeLeft={<Displays.Avatar src={authenRedux?.img_path} />}
          nodeRight={<MyPointWidget />}
        />
        <MemberSwitchTypeWidget />
        <div className="tab_container">
          {data.map((e, i) => (
            <div
              key={i}
              className={`f_col ${active === e.key ? "active" : ""}`}
              onClick={() => this.handleClickRoute(e)}
            >
              {typeof e.name === "string" ? (
                t(e.name, { ns: "profile" })
              ) : (
                <div className="tab_muti_label">
                  {e.name.map((f, j) => (
                    <div key={j}>{t(f, { ns: "profile" })}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <Outlet />
      </ProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(withRouter(ProfileContainer)));
