import styled from 'styled-components';

export const ScanInfoStyled = styled.div`
  .scan_info_container {
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    padding: 7px 16px;
    .si_label {
      margin-bottom: 2px;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .theme_standard {
  }
`;
