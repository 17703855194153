import React from 'react';
import { connect } from 'react-redux';
import { ProfileTContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Cards } from 'components';

class ProfileTContainer extends React.Component {
  state = {};

  render() {
    const { authenRedux } = this.props;
    return (
      <ProfileTContainerStyled>
        {authenRedux &&
          (authenRedux.member_type_active === '1' ? (
            <Cards.UserInfo {...authenRedux} />
          ) : (
            <Cards.CorperateInfo {...authenRedux.organization_detail} />
          ))}
      </ProfileTContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileTContainer));
