import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { AlertLabelStyled } from './styled';
import { Buttons } from 'components';

const AlertLabel = ({ theme_standard, label, actionLabel, onClickAction }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <AlertLabelStyled>
      <div className={customClass}>
        <div className="advertise_container">
          <div className="at_block">
            <div className="at_label">
              {typeof label === 'string'
                ? label
                : label.map((e, i) => <div key={i}>{e}</div>)}
            </div>
          </div>
          {actionLabel && (
            <div className="advertise_btn">
              <Buttons.BgStandard label={actionLabel} onClick={onClickAction} />
            </div>
          )}
        </div>
      </div>
    </AlertLabelStyled>
  );
};

AlertLabel.propTypes = {};

export default AlertLabel;
