import styled from "styled-components";

export const PersonalInfoFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 18px;
    &.mb_32 {
      margin-bottom: 32px;
    }
  }
`;
