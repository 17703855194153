import styled from 'styled-components';

export const AvatarStyled = styled.div`
  .avatar_container {
    .avatar_img {
      border-radius: ${({ size }) => (size ? size : '82px')};
      width: ${({ size }) => (size ? size : '82px')};
      height: ${({ size }) => (size ? size : '82px')};
      object-fit: cover;
    }
  }

  .theme_standard {
  }
`;
