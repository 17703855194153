import React from 'react';

export default function renderIcon({
  width = 39.267,
  height = 38.277,
  color = '#4b1e78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 39.267 38.277"
    >
      <g
        id="Group_306"
        data-name="Group 306"
        transform="translate(-193.608 -852.297)"
      >
        <path
          id="Path_106"
          data-name="Path 106"
          d="M2110.463,1447.722c.953-.33,1.843-.571,2.59-.841a1.257,1.257,0,0,1,1.793.729,1.235,1.235,0,0,1-.826,1.747q-2.785,1.055-5.6,2.042a1.336,1.336,0,0,1-1.895-.963c-.621-1.8-1.26-3.595-1.867-5.4-.278-.826-.151-1.592.761-1.9.89-.3,1.475.175,1.774,1.023.239.674.586,1.678.9,2.576Z"
          transform="translate(-1910.924 -584.722)"
          fill={color}
        />
        <path
          id="Path_107"
          data-name="Path 107"
          d="M2125.448,1473.394a19.129,19.129,0,0,1-15.868-8.438,1.33,1.33,0,0,1,2.2-1.49,16.475,16.475,0,1,0-1.417-15.86,1.33,1.33,0,1,1-2.434-1.075,19.14,19.14,0,1,1,17.514,26.862"
          transform="translate(-1911.712 -582.82)"
          fill={color}
        />
        <path
          id="Path_108"
          data-name="Path 108"
          d="M2139.589,1465.117a1.323,1.323,0,0,1-.592-.14l-9.585-4.771a1.331,1.331,0,0,1-.737-1.191v-9.056a1.331,1.331,0,0,1,2.661,0v8.232l8.847,4.4a1.33,1.33,0,0,1-.594,2.521"
          transform="translate(-1916.711 -586.058)"
          fill={color}
        />
      </g>
    </svg>
  );
}
