import styled from 'styled-components';

export const LabelEmptyStyled = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.COLORS.GRAY_1};
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};

  .theme_standard {
  }
`;
