import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ActivityInfoConfirmStyled } from './styled';
import moment from 'moment';
import { RENDER_GENDER_TYPE } from 'utils/functions/customer';
import { Buttons } from 'components';

const ActivityInfoConfirm = ({
  theme_standard,
  activity_code,
  activity_name,
  activity_point,
  cus_name,
  cus_gender,
  cus_birthday,
  cus_province,
  cus_phone,
  onCancel,
  onConfirm,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ActivityInfoConfirmStyled>
      <div className={customClass}>
        <div className="activity_confirm_container">
          <div className="avc_title">ยืนยันการสแกน</div>
          <div className="avc_body">
            <div className="avc_row">
              <div className="cr_left_col">ชื่อกิจกรรม</div>
              <div className="cr_right_col">{activity_code}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">รหัสกิจกรรม</div>
              <div className="cr_right_col">{activity_name}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">แต้มกิจกรรม</div>
              <div className="cr_right_col">{activity_point}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">ชื่อลูกค้า</div>
              <div className="cr_right_col">{cus_name}</div>
            </div>

            <div className="avc_row">
              <div className="cr_left_col">เพศลูกค้า</div>
              <div className="cr_right_col">
                {RENDER_GENDER_TYPE(cus_gender)?.labelTh}
              </div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">วันเกิดลูกค้า</div>
              <div className="cr_right_col">
                {moment(cus_birthday).format('DD/MM/YYYY')}
              </div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">เบอร์โทรลูกค้า</div>
              <div className="cr_right_col">{cus_phone}</div>
            </div>
            <div className="avc_row">
              <div className="cr_left_col">จังหวัด</div>
              <div className="cr_right_col">{cus_province}</div>
            </div>
          </div>
          <div className="avc_action_row">
            <div className="ca_col">
              <Buttons.BgStandard
                theme_cancel
                label={'ยกเลิก'}
                onClick={onCancel}
              />
            </div>
            <div className="ca_col">
              <Buttons.BgStandard label={'ตกลง'} onClick={onConfirm} />
            </div>
          </div>
        </div>
      </div>
    </ActivityInfoConfirmStyled>
  );
};

ActivityInfoConfirm.propTypes = {};

export default ActivityInfoConfirm;
