import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { NavigationMenuItemStyled } from './styled';

const NavigationMenuItem = ({
  theme_standard,
  isSurvey,
  icon,
  topLabel,
  bottomLabel,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <NavigationMenuItemStyled>
      <div className={customClass}>
        <div className="natigation_menu_item_container">
          <div className="nmi_left_col">{icon}</div>
          <div className="nmi_right_col">
            <div className="rc_label">{topLabel}</div>
            <div className="rc_label">{bottomLabel}</div>
          </div>
          {isSurvey && <div className="surver_status">Surver</div>}
        </div>
      </div>
    </NavigationMenuItemStyled>
  );
};

NavigationMenuItem.propTypes = {};

export default NavigationMenuItem;
