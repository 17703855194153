import { useEffect, useState } from "react";
import { InputRegisTypeWidgetStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { Icons } from "components";

const InputRegisTypeWidget = ({ value, onChange }) => {
  const { t } = useTranslation(["common"]);

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (!value !== _value) {
      _setValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClick = (e) => {
    _setValue(e);
    onChange && onChange(e);
  };

  return (
    <InputRegisTypeWidgetStyled>
      <div
        className={`btn_inr_item mb_10 ${_value === "1" ? "active" : ""}`}
        onClick={() => _handleClick("1")}
      >
        <Icons.Checkbox isActive={_value === "1"} />
        <div className="inr_icon_wrap">
          <Icons.MemberPerson />
        </div>
        <div className="inr_label">
          {t("Individual Registration", { ns: "common" })}
        </div>
      </div>
      <div
        className={`btn_inr_item ${_value === "2" ? "active" : ""}`}
        onClick={() => _handleClick("2")}
      >
        <Icons.Checkbox isActive={_value === "2"} />
        <div className="inr_icon_wrap">
          <Icons.MemberCoperate />
        </div>
        <div className="inr_label">
          {t("Corporate Registration", { ns: "common" })}
        </div>
      </div>
    </InputRegisTypeWidgetStyled>
  );
};

export default InputRegisTypeWidget;
