import styled from "styled-components";

export const RegisterCoperateFormStyled = styled.div`
  .form_sec {
    &.mb_20 {
      margin-bottom: 20px;
    }
    .fs_title {
      margin-bottom: 12px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    }
    .input_wrap {
      margin-bottom: 15px;
      &.mb_12 {
        margin-bottom: 12px;
      }
      &.mb_82 {
        margin-bottom: 82px;
      }
    }
  }
`;
