import { useState } from 'react';
import { BtnScanQRWidgetStyled } from './styled';
import QrReader from 'react-qr-scanner';
import { useTranslation } from 'react-i18next';

import { Buttons, Modals } from 'components';

const BtnScanQRWidget = ({ btnLabel, onScanSuccess }) => {
  const { t } = useTranslation(['home']);

  const [_isShowModal, _setIsShowModal] = useState(false);

  const _handleClickScan = () => {
    _setIsShowModal(true);
  };

  const _handleCloseModal = () => {
    _setIsShowModal(false);
  };

  // TEST
  // const [_value, _setValue] = useState();
  // const _handleChangeValue = (e) => {
  //   _setValue(e.target.value);
  // };

  const _successScan = (e) => {
    if (e) {
      onScanSuccess && onScanSuccess(e.text);
      _handleCloseModal();
    }
  };

  return (
    <BtnScanQRWidgetStyled>
      <Buttons.BgStandard
        label={btnLabel ? btnLabel : t('Scan QR Code', { ns: 'home' })}
        onClick={_handleClickScan}
      />
      <Modals.BodyEmpty
        isShowModal={_isShowModal}
        onCloseModal={_handleCloseModal}
      >
        <div className="scan_show">
          <QrReader
            delay={300}
            style={{
              width: 240,
              height: 240,
            }}
            constraints={{
              video: {
                facingMode: { exact: `environment` },
              },
            }}
            onError={() => {}}
            onScan={_successScan}
          />
          {/* <button onClick={() => onScanSuccess('https://siammusicmembers.com/product-warrantee/6595760')}>
            Test
          </button> */}
        </div>
      </Modals.BodyEmpty>
    </BtnScanQRWidgetStyled>
  );
};

export default BtnScanQRWidget;
