import styled from 'styled-components';

export const ProductWarranteeDetailContainerStyled = styled.div`
  padding: 60px 16px 104px 16px;
  .warrantee_title {
    margin-bottom: 4px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .product_info_wrap {
    margin-bottom: 38px;
  }
  .product_more_item {
    padding: 12px 0px;
    ${({ theme }) => theme.COLORS.GRAT_1};
    border-top: 1px solid;
    &:first-child {
      margin-top: 4412px;
    }
  }
  .btn_add_sn {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;
