import React from "react";
import { AdminScanActivityContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { toast } from "react-toastify";
import { Cards, Displays, Forms, Headers, Icons, Modals } from "components";

class AdminScanActivityContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleCloseModal = () => {
    this.setState({
      isBgLoading: false,
      isShowModal: false,
      renderModal: null,
    });
  };

  handleSubmit = () => {
    this.props.router.navigate(`${ROUTE_PATH.PRODUCT_WARRANTEE}/id`);
  };

  handleSuccessScan = async (activityRegis, isSubmit) => {
    const split = activityRegis.split(",");
    if (!split || split.length < 3) {
      toast.error("เลขซีเรียลไม่ถูกต้อง");
    } else {
      switch (split[4]) {
        // Activity Normal
        case "1":
          let params = {
            customer_id: split[0],
            activity_id: split[1],
            activity_history_id: split[2],
            member_type: split[3],
          };
          this.setState({
            isBgLoading: true,
          });
          let res = await userService.POST_ADMIN_ACTIVITY_SCAN({
            ...params,
            isVeriry: true,
          });
          if (res && res.status === 200) {
            this.setState({
              values: params,
              isShowModal: true,
              renderModal: (
                <Cards.ActivityInfoConfirm
                  {...res.data}
                  onCancel={this.handleCloseModal}
                  onConfirm={this.handleUseQR}
                />
              ),
              isBgLoading: false,
            });
          } else {
            this.setState({
              isBgLoading: false,
            });
            toast.error(res?.data?.error?.message || "เลขซีเรียลไม่ถูกต้อง");
          }
          break;
        // Activity Special Form
        case "2":
          let paramsForm = {
            activity_form_history_id: split[2],
          };
          let resForm = await userService.GET_MY_ACTIVITY_FORM_DETAIL(
            paramsForm.activity_form_history_id
          );
          if (resForm && resForm.status === 200) {
            this.setState({
              isShowModal: true,
              renderModal: (
                <Cards.ActivityFormInfoConfirm
                  {...resForm.data}
                  onCancel={this.handleCloseModal}
                  onConfirm={async () => {
                    this.setState({
                      isBgLoading: true,
                    });
                    let resComfirm =
                      await userService.POST_ACTIVITY_FORM_HISTORY_CHECK_IN(
                        paramsForm
                      );
                    if (resComfirm && resComfirm.status === 200) {
                      this.setState({
                        isBgLoading: true,
                      });
                      this.handleCloseModal();
                      toast.success("ยืนยันสำเร็จ");
                    } else {
                      this.setState({
                        isBgLoading: false,
                      });
                      this.handleCloseModal();
                      toast.error("ยืนยันไม่สำเร็จ โปรดตรวจสอบเงื่อนไข");
                    }
                  }}
                />
              ),
              isBgLoading: false,
            });
          }
          break;
        default:
          break;
      }
    }
  };

  handleUseQR = async () => {
    const { values } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let res = await userService.POST_ADMIN_ACTIVITY_SCAN(values);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      this.handleCloseModal();
      toast.success("Scan success");
    }
  };

  render() {
    const { isBgLoading, isShowModal, renderModal } = this.state;
    return (
      <AdminScanActivityContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.Logo />
        <Displays.HeaderIconTitle
          mb={"64px"}
          icon={<Icons.SubMenuProductWarrantee color="#fff" />}
          topLabel={"Activity Scan"}
          bottomLabel={"สแกนกิจกรรม"}
        />
        <Forms.ScanQRCodeForm
          onSuccessScan={this.handleSuccessScan}
          onSubmit={this.handleSubmit}
        />
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </AdminScanActivityContainerStyled>
    );
  }
}

export default withRouter(AdminScanActivityContainer);
