import React from 'react';
import { ShippingFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const ShippingForm = ({ branchOptions, onSubmit }) => {
  const schema = yup.object().shape({
    type_shipping: yup.string().required('Type is required'),
  });

  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchTypeShipping = watch('type_shipping');

  const _onSubmit = (values) => {
    let isErr = false;
    switch (values.type_shipping) {
      case '1':
        if (!values.branch) {
          isErr = true;
          setError(`branch`, {
            type: 'custom',
            message: 'Branch is required',
          });
        }
        break;
      case '2':
        if (!values.name_shipping) {
          isErr = true;
          setError(`name_shipping`, {
            type: 'custom',
            message: 'Name is required',
          });
        }
        if (!values.phone_shipping) {
          isErr = true;
          setError(`phone_shipping`, {
            type: 'custom',
            message: 'Phone is required',
          });
        }
        if (!values.address_shipping) {
          isErr = true;
          setError(`address_shipping`, {
            type: 'custom',
            message: 'Address is required',
          });
        }
        break;
      default:
        break;
    }
    if (!isErr) {
      onSubmit(values);
    }
  };

  return (
    <ShippingFormStyled>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form_title">กรุณาระบุสถานที่จัดส่งของรางวัล</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                options={[
                  {
                    label: 'ศูนย์ Yamaha',
                    value: '1',
                  },
                  {
                    label: 'กำหนดเอง',
                    value: '2',
                  },
                ]}
                errors={errors.type_shipping?.message}
              />
            )}
            name="type_shipping"
            defaultValue=""
          />
        </div>
        {watchTypeShipping === '2' && (
          <>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder={'ชื่อ-นามสกุล ผู้รับ'}
                    errors={errors.name_shipping?.message}
                  />
                )}
                name="name_shipping"
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder={'เบอร์โทรผู้รับ'}
                    errors={errors.phone_shipping?.message}
                  />
                )}
                name="phone_shipping"
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextArea
                    {...field}
                    placeholder={'ที่อยู่ผู้รับ'}
                    errors={errors.address_shipping?.message}
                  />
                )}
                name="address_shipping"
                defaultValue=""
              />
            </div>
          </>
        )}
        {watchTypeShipping === '1' && (
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={branchOptions}
                  // placeholder={t('test')}
                  errors={errors.branch?.message}
                />
              )}
              name="branch"
              defaultValue=""
            />
          </div>
        )}
        <div className="btn_submit_wrap">
          <Buttons.BgStandard label="ตกลง" type="submit" />
        </div>
      </form>
    </ShippingFormStyled>
  );
};

ShippingForm.propTypes = {};

export default ShippingForm;
