import styled from "styled-components";

export const ScanQRCodeFormStyled = styled.div`
  .input_wrap {
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 145px;
  }
`;
