import moment from 'moment';
import { RENDER_UNIT } from './unit';

export const RENDER_STATUS = (key) => {
  switch (key) {
    case '1':
      return false;
    // return {
    //   label: 'Ready',
    //   bgColor: '#FEDB04',
    // };
    case '2':
      return {
        label: 'Waiting',
        bgColor: '#FEDB04',
      };
    case '3':
      return {
        label: 'Approved',
        bgColor: '#0FB863',
      };
    case '4':
      return {
        label: 'Expired',
        bgColor: '#95909A',
      };
    case '5':
      return {
        label: 'Rejected',
        bgColor: '#95909A',
      };
    default:
      break;
  }
};

export const CAL_WARRANTY_EXPIRE = (
  dateStart,
  warrantyPeriod,
  warrantyUnit,
  extensionPeriod,
  extensionUnit
) => {
  let warrantyExpire = moment(dateStart);
  if (warrantyPeriod && warrantyUnit) {
    const unit = RENDER_UNIT(warrantyUnit);
    warrantyExpire.add(warrantyPeriod, unit.momentVarAdd);
  }
  if (extensionPeriod && extensionUnit) {
    const unit = RENDER_UNIT(extensionUnit);
    warrantyExpire.add(extensionPeriod, unit.momentVarAdd);
  }
  return warrantyExpire;
};

export const CHECK_WARRANTY = (
  approved_warranty_date,
  warrantyPeriod,
  warrantyUnit,
  extensionPeriod,
  extensionUnit
) => {
  let today = moment();
  let warrantyExpire = CAL_WARRANTY_EXPIRE(
    approved_warranty_date,
    warrantyPeriod,
    warrantyUnit,
    extensionPeriod,
    extensionUnit
  );
  if (moment(warrantyExpire).isAfter(today)) {
    return true;
  } else {
    return false;
  }
};

export const RENDER_WARRANTY_STATUS = (status, hasWarranty) => {
  switch (status) {
    case '1':
      return {
        label: 'พร้อม',
        bg: '#4b1e78',
      };
    case '2':
      return {
        label: 'รออนุมัติ',
        bg: '#FFB341',
      };
    case '3':
      if (hasWarranty) {
        return {
          label: 'ประกัน',
          bg: '#008000',
        };
      } else {
        return {
          label: 'หมดประกัน',
          bg: '#e4e4e4',
        };
      }
    case '4':
      return {
        label: 'หมดอายุ',
        bg: '#e4e4e4',
      };
    case '5':
      return {
        label: 'ยกเลิก',
        bg: '#e4e4e4',
      };
    default:
      break;
  }
};
