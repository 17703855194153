import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "apiServices";
import { setReduxAuthen } from "store/reducers/authenReducer";
import { setReduxWallet } from "store/reducers/walletReducer";

export const updateSwitchAccountThunk = createAsyncThunk(
  "updateSwitchAccountThunk/update",
  async (data, thunkAPI) => {
    let res = await userService.PUT_MEMBER_TYPE_UPDATE_DETAIL({
      member_type_active: data,
    });
    if (res && res.status === 200) {
      let resArr = await Promise.all([
        userService.GET_MY_PROFILE(),
        userService.GET_MY_WALLET(),
      ]);
      if (resArr) {
        thunkAPI.dispatch(setReduxAuthen(resArr[0].data));
        thunkAPI.dispatch(setReduxWallet(resArr[1].data));
      }
      return true;
    } else {
      return false;
    }
  }
);
