import styled from 'styled-components';

export const BgStandardStyled = styled.div`
  .btn {
    border-radius: 12px;
    width: 100%;
    height: ${({ height }) => (height ? height : '40px')};
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }

  .theme_cancel {
    .btn {
      background: ${({ theme }) => theme.COLORS.GRAY_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }

  .theme_stadard_btn {
  }
`;
