import React from 'react';
import { SpecialPointsContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { MyPointWidget } from 'widgets';
import { Cards, Displays, Headers, Icons } from 'components';

class SpecialPointsContainer extends React.Component {
  handleClickMenu = (e) => {
    this.props.router.navigate(e.path);
  };

  render() {
    const { data } = this.props;
    return (
      <SpecialPointsContainerStyled>
        <Headers.Logo mbNode="55px" nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={'25px'}
          icon={<Icons.SpecialPoint />}
          topLabel={'Special Points'}
          bottomLabel={'คะแนนพิเศษ'}
        />
        {data &&
          data.map((e, i) => (
            <div
              key={i}
              className="menu_item_wrap"
              onClick={() => this.handleClickMenu(e)}
            >
              <Cards.NavigationMenuItem
                isSurvey
                icon={e.icon}
                topLabel={e.name}
                bottomLabel={e.name_th}
              />
            </div>
          ))}
      </SpecialPointsContainerStyled>
    );
  }
}

export default withRouter(SpecialPointsContainer);
