import React from 'react';
import { UserInfoConditionContainerStyled } from './styled';
import { Buttons, Cards } from 'components';

class UserInfoConditionContainer extends React.Component {
  render() {
    const { onClose } = this.props;
    return (
      <UserInfoConditionContainerStyled>
        <Cards.ConditionCard
          title="นโยบายเรื่องความเป็นส่วนตัว"
          footerNode={<Buttons.BgStandard label={'ตกลง'} onClick={onClose} />}
        >
          <p className="article">
            บริษัท สยามดนตรียามาฮ่า จำกัด (“ยามาฮ่า”)
            เห็นว่าการคุ้มครองข้อมูลส่วนบุคคลของลูกค้าแต่ละราย (“ลูกค้า” หรือ
            “ท่าน”)
            ถือเป็นภาระผูกพันของยามาฮ่าตามกฎหมายและคำสั่งตลอดจนความรับผิดชอบทางสังคมที่สำคัญ
          </p>
          <p className="article">
            ยามาฮ่าทำธุรกิจในประเทศไทยและประมวลผลข้อมูลส่วนบุคคลตามที่ระบุไว้ใน
            “นโยบายเรื่องความเป็นส่วนตัว” ดังรายละเอียดต่อจากนี้
            (“นโยบายความเป็นส่วนตัว”)
            และยังให้ความเคารพต่อสิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่านรวมทั้งคุ้มครองสิทธิ์ดังกล่าวโดยเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
            พ.ศ. 2562 (“กฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล” หรือ
            “พระราชบัญญัติ”)
          </p>
          <h3>1. แหล่งที่มาและหมวดหมู่ของข้อมูลส่วนบุคคล</h3>
          <h4>1.1 แหล่งที่มาของข้อมูลส่วนบุคคลที่ยามาฮ่าอาจจะเก็บรวบรวม</h4>
          <ul>
            <li>
              <div>
                ข้อมูลส่วนบุคคลที่ท่านจัดทำขึ้นและแจ้งต่อยามาฮ่าหรืออยู่ในความครอบครองของยามาฮ่า
              </div>
              <div>
                ยามาฮ่าอาจเก็บรวบรวมข้อมูลส่วนบุคคลของท่านซึ่งถือเป็นข้อมูลส่วนบุคคลที่ท่านแจ้งให้ยามาฮ่าทราบหรืออยู่ในความครอบครองของยามาฮ่า
                หรือได้มาจากบุคคลที่เกี่ยวข้องกับท่านหรือธุรกิจของท่าน
                บิดามารดาของท่าน
                หรือตัวแทนตามกฎหมายโดยเป็นไปตามกฎหมายที่นำมาบังคับใช้
                ซึ่งจะรวมถึงบุคคลที่ได้รับมอบอำนาจและได้รับมอบหมายจากท่านหรือบุคคลที่กระทำการในนามของท่านดังมีรายละเอียดต่อไปนี้
                <ul>
                  <li>
                    เมื่อท่านได้เข้าทำสัญญากับยามาฮ่า เช่น
                    สัญญาการเป็นผู้แทนจำหน่าย สัญญาการเป็นผู้รับอนุญาตแฟรนไชส์
                    สัญญาการจัดหาสินค้า
                  </li>
                  <li>
                    เมื่อท่านได้ร้องขอ ลงทะเบียน สั่งซื้อ หรือซื้อสินค้า บริการ
                    เข้าเรียนในหลักสูตรโรงเรียนดนตรีที่เป็นของยามาฮ่า และ/หรือ
                    ยามาฮ่ามีสิทธิแต่เพียงผู้เดียวในการจัดหาและจัดจำหน่ายโดยเป็นไปตามคุณภาพและมาตรฐานของยามาฮ่า
                    (“สินค้าและบริการของยามาฮ่า”)
                  </li>
                  <li>
                    เมื่อท่านมีการร้องขอหรือลงทะเบียนที่จะใช้สินค้าและบริการของยามาฮ่า
                    (ภายใต้ขอบเขตความรับผิดชอบของยามาฮ่าในฐานะของผู้ควบคุมข้อมูลโดยเป็นไปตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล)
                  </li>
                  <li>
                    เมื่อท่านได้มาเยี่ยมชมหรือติดต่อกับยามาฮ่าผ่านไปรษณีย์อิเล็กทรอนิกส์
                    โทรศัพท์ บัญชีไลน์ และช่องทางการติดต่อออนไลน์อื่นๆ
                    ของยามาฮ่าโดยเป็นไปตามความสนใจของท่าน เช่น เว็บไซต์
                    แพลตฟอร์มสื่อสังคม
                    ซึ่งทางยามาฮ่ามีสิทธิ์ที่จะประมวลผลข้อมูลส่วนบุคคลของท่านตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
                  </li>
                  <li>
                    เมื่อท่านได้ตอบคำถามในแบบสำรวจของยามาฮ่าหรือเข้าร่วมการส่งเสริมการตลาดของยามาฮ่า
                  </li>
                  <li>
                    เมื่อท่านได้ตกลงที่จะรับข้อมูลและข่าวสารเมื่อมีการอัพเดตและรับสิทธิประโยชน์จากยามาฮ่า
                  </li>
                  <li>เมื่อท่านได้เข้าร่วมในงาน สัมมนา และกิจกรรมของยามาฮ่า</li>
                  <li>
                    เมื่อท่านจ่ายเงินและทำธุรกรรมที่เกี่ยวข้องกับสินค้าและบริการของยามาฮ่า
                    เราจะจัดเก็บข้อมูลที่เกี่ยวข้องกับการจ่ายเงินและธุรกรรม
                  </li>
                  <li>
                    เมื่อท่านได้เข้าเยี่ยมชม ลงชื่อเข้าใช้ และ/หรือ
                    ใช้งานคุณลักษณะต่างๆ
                    ผ่านทางเว็บไซต์ที่ยามาฮ่าเป็นผู้ดำเนินการ
                  </li>
                </ul>
              </div>
            </li>
            <li>
              ข้อมูลส่วนบุคคลจากแหล่งที่มาอื่นๆ
              <ul>
                <li>
                  บุคคลและบริษัทที่แนะนำท่านให้ยามาฮ่ารู้จัก
                  ซึ่งจะรวมถึงหากแต่ไม่ได้จำกัดไว้แค่เพียง
                  ผู้แทนจำหน่ายธุรกิจของยามาฮ่า ตัวแทน
                  บริษัทภายนอกที่ยามาฮ่าบ้านจ้าง
                  รวมทั้งเจ้าของโรงเรียนดนตรียามาฮ่า (“โรงเรียนดนตรียามาฮ่า”)
                  ซึ่งที่มีความสัมพันธ์ตามกฎหมายกับยามาฮ่าวัตถุประสงค์ในการขายสินค้าและบริการของยามาฮ่า
                </li>
                <li>
                  ยามาฮ่า คอร์ปอเรชั่นและบริษัทอื่นๆ
                  ที่ถือว่าเป็นบริษัทในเครือของยามาฮ่า
                  ที่มีสิทธิ์โดยชอบตามกฎหมายในการเปิดเผยข้อมูลส่วนบุคคลให้ยามาฮ่าทราบ
                </li>
                <li>
                  ธุรกิจส่วนบุคคลและบริษัทที่ท่านทำหน้าที่เป็นกรรมการ
                  ผู้มีอำนาจลงนาม ตัวแทน พนักงาน บริษัทภายนอกที่รับการว่าจ้าง
                  ผู้รับจ้าง
                  และบุคคลที่ได้รับมอบหมายหรือบุคคลที่ทำหน้าที่เป็นผู้ติดต่อ
                  (“บุคลากรของคู่ค้าทางธุรกิจ”)
                </li>
                <li>ผู้ให้บริการสื่อสังคมและผู้ให้บริการเทคโนโลยีอื่นๆ</li>
              </ul>
            </li>
          </ul>
          <h4>1.2 หมวดหมู่ของข้อมูลส่วนบุคคล</h4>
          <ul>
            <li>
              ชื่อ นามสกุล คำนำหน้าชื่อ อายุ วันเดือนปีเกิด ที่อยู่อีเมล
              และที่อยู่สำหรับการติดต่อ ตำแหน่งที่ตั้ง หมายเลขโทรศัพท์ เพศ
              อาชีพและประเภทของธุรกิจ
              สถานที่ทำงานหรือสถานศึกษาก่อนหน้านี้และปัจจุบัน ข้อมูลทางการศึกษา
              ข้อมูลที่เกี่ยวข้องกับสื่อสังคม รหัสและบัญชีผู้ใช้งาน
              หมายเลขอ้างอิง ข้อมูลที่เกี่ยวกับการเข้าเยี่ยมชมร้านของยามาฮ่า
              สินค้าและบริการของยามาฮ่าที่มีการจัดซื้อ
            </li>
            <li>
              ข้อมูลที่ปรากฏอยู่ในบัตรประจำตัวของทางราชการและหนังสือรับรองการจดทะเบียนบริษัทเช่น
              บัตรประจำตัวประชาชน หมายเลขหนังสือเดินทาง ทะเบียนบ้าน
              หนังสือรับรองการจดทะเบียนบริษัท ข้อมูลเกี่ยวกับการจดทะเบียนภาษี
              ใบสูติบัตร หนังสือรับรองการจดทะเบียนเปลี่ยนชื่อและนามสกุล
              หรือเอกสารอื่นๆ ที่ใช้ในการระบุตัวตน
              ซึ่งสามารถนำมาใช้ได้เพื่อวัตถุประสงค์เดียวกัน
            </li>
            <li>ข้อมูลที่ได้จากแบบสอบถามหรือแคมเปญ</li>
            <li>
              ข้อมูลที่จำเป็นต่อการทำธุรกรรมหรือการสนับสนุนเกี่ยวกับสินค้าหรือบริการ
              (หมายเลขซีเรียล ประวัติในการซื้อ วิธีการชำระเงิน
              หมายเลขบัญชีธนาคาร และข้อมูลอื่นๆ ที่คล้ายคลึงกัน)
            </li>
            <li>
              ข้อมูลที่เกิดขึ้นจากการใช้สินค้าและบริการของยามาฮ่า (รหัสอ้างอิง
              วิธีการใช้สินค้า ความถี่ในการใช้สินค้า และข้อมูลที่คล้ายคลึงกัน)
            </li>
            <li>
              ข้อมูลที่ได้จากการสอบถามข้อมูลกับทางยามาฮ่า (เบอร์โทรศัพท์
              ข้อมูลที่เกี่ยวข้องกับสื่อสังคม รหัสและบัญชีผู้ใช้งาน
              ประวัติการขอให้มีการซ่อมแซมและข้อมูลที่คล้ายคลึงกัน
              ข้อมูลที่เกี่ยวข้องกับปัญหาที่เกิดขึ้นกับการใช้สินค้าและผลิตภัณฑ์ของยามาฮ่า
              และข้อมูลที่คล้ายคลึงกัน)
            </li>
            <li>
              ข้อมูลที่เกิดจากการเข้าร่วมและธุรกรรมที่ทำกับยามาฮ่า
              เช่นประวัติการเข้าชมคอนเสิร์ต เข้าร่วมงาน การทดสอบ กิจกรรม
              และการประกวดที่ยามาฮ่าให้ความร่วมมือหรือเป็นผู้สนับสนุนร่วม
              (รวมทั้งภาพถ่าย เสียงและภาพเคลื่อนไหวที่บันทึกไว้
              ตลอดจนข้อมูลที่เกี่ยวข้องกับการแสดงดนตรีหรือร้องเพลง)
              ประวัติการเข้าร่วมในชั้นเรียนดนตรี หลักสูตร
              หรือการฝึกสอนที่คล้ายคลึงกันซึ่งทางยามาฮ่าเป็นผู้ดำเนินการ
              หรือได้รับจากโรงเรียนดนตรียามาฮ่าตามหลักการทางด้านกฎหมายโดยเป็นไปตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
              (รวมทั้งภาพถ่าย เสียงและภาพเคลื่อนไหวที่บันทึกไว้
              ตลอดจนข้อมูลที่เกี่ยวข้องกับการแสดงดนตรีหรือร้องเพลง
              และกิจกรรมอื่นๆ ที่เกี่ยวข้องกับการเข้าร่วมดังกล่าว)
              ประวัติในการซื้อหรือใช้งานสินค้าและบริการของยามาฮ่า
              ประวัติการออกใบอนุญาต การใช้งาน
              และสิ่งที่คล้ายกันในส่วนของงานศิลปะ (รวมทั้งส่วนของดนตรี)
              ซึ่งยามาฮ่าเป็นผู้ที่ถือสิทธิ์อยู่
            </li>
            <li>
              ข้อมูลที่ได้จากการใช้ซอฟต์แวร์หรืออินเทอร์เน็ต
              ซึ่งทางยามาฮ่ามีสิทธิ์ที่จะประมวลผลตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
              (ข้อมูลที่ได้รับจากคุกกี้ ฯลฯ
              ข้อมูลที่เกี่ยวข้องกับบราวเซอร์และระบบปฏิบัติการของอุปกรณ์ที่ท่านใช้งานอยู่
              ข้อมูลเกี่ยวกับตำแหน่งที่ตั้ง ข้อมูลในการใช้บริการ
              หน้าเพจที่ท่านไปเยี่ยมชม ลิงก์ที่ท่านคลิก
              อีเมลจากยามาฮ่าที่ท่านเปิดอ่าน ที่อยู่ไอพี ประเทศที่เข้าชม
              จำนวนของผู้ใช้งาน วันที่เข้าเยี่ยมชม และข้อมูลที่คล้ายคลึงกัน)
            </li>
            <li>ข้อมูลที่ได้รับจากแบบสอบถามหรือแคมเปญของยามาฮ่า</li>
            <li>
              ข้อมูลที่จ้งให้ทราบโดยผู้ดำเนินธุรกิจที่ให้บริการโดยใช้ข้อมูลที่สามารถแยกความแตกต่างระหว่างบุคคลแต่ไม่สามารถระบุตัวตนได้
              (เช่น การระบุตัวตนจากโฆษณาโดยได้รับความยินยอมจากท่าน)
            </li>
            <li>
              ข้อมูลในเรื่องของแพลตฟอร์มสื่อสังคมของบัญชีอย่างเป็นทางการของยามาฮ่า
              (เป็นไปตามความสนใจหรือคำร้องขอของท่าน)
            </li>
            <li>
              ข้อมูลอื่นๆ
              ที่เกี่ยวข้องซึ่งจำเป็นต่อการอำนวยความสะดวกให้กับการดำเนินธุรกิจและการบริหารจัดการของยามาฮ่า
              เช่น
              ภาพจากกล้องวงจรปิดที่บันทึกไว้และข้อมูลที่เกี่ยวข้องกับคำสั่งศาลและ/หรือการปฏิบัติตามกฎหมายของยามาฮ่า
            </li>
          </ul>
          <h3>
            2. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล
          </h3>
          <h4>
            2.1 ความจำเป็นต่อการดำเนินการตามสัญญาที่ท่านได้ทำไว้กับยามาฮ่า เช่น
          </h4>
          <ul>
            <li>
              การจัดหา การบำรุงรักษา การคุ้มครอง การจัดหาข้อมูลเกี่ยวกับแคมเปญ
              สินค้าและบริการใหม่ของ ยามาฮ่า และข้อมูลที่คล้ายคลึงกัน
              การใช้แบบสอบถาม บริการหลังการขายของสินค้า และการคุ้มครองลูกค้า
            </li>
            <li>การติดต่อที่เกี่ยวข้องกับธุรกรรม</li>
            <li>การจัดหาสินค้าและบริการของยามาฮ่า</li>
            <li>การจัดให้มีการชำระเงินและการทำธุรกรรมที่เกี่ยวข้อง</li>
            <li>การสร้างการบริหารจัดการบัญชี</li>
            <li>
              การปฏิบัติตามข้อตกลงที่ทำไว้กับยามาฮ่า (การประมวลผลการชำระเงิน
              ฯลฯ)
            </li>
            <li>
              การบริหารจัดการลิขสิทธิ์และสิทธิข้างเคียงสำหรับแหล่งที่มาของเสียง
            </li>
            <li>
              การดำเนินกิจกรรมด้านบริการและการจัดการเรื่องการซ่อมแซมในระหว่างระยะเวลารับประกันและการดูแลหลังการขายหลังจากที่มีการทำกิจกรรมด้านบริการและการจัดการเรื่องการซ่อมแซมอันมีส่วนเกี่ยวข้องกับสินค้าและบริการของยามาฮ่า
            </li>
            <li>การตอบข้อซักถาม การร้องขอโบรชัวร์ หรือการร้องขออื่นๆ</li>
            <li>
              การใช้สิทธิ์หรือการปฏิบัติตามภาระผูกพันตามสัญญา กฎหมาย
              กฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล และสิ่งที่คล้ายคลึงกัน
            </li>
            <li>การคัดกรองในกรณีที่มีการยื่นคำขอเช่าอุปกรณ์</li>
            <li>
              การติดต่อหรือการได้รับ/การจัดส่งเอกสารหรือพัสดุ
              การจัดส่งหนังสือบอกกล่าวที่เกี่ยวข้องกับสินค้าและบริการที่ยามาฮ่าเป็นผู้ดำเนินการและประกาศที่เกี่ยวข้องกับการจัดงาน
            </li>
          </ul>
          <h4>
            2.2 ความจำเป็นต่อการปฏิบัติตามภาระผูกพันตามกฎหมายของยามาฮ่า เช่น
          </h4>
          <p className="article">
            ยามาฮ่าจะต้องปฏิบัติตามกฎหมายและคำสั่งมากมายที่นำมาบังคับใช้ซึ่งออกโดยหน่วยงานทางกฎหมายที่เกี่ยวข้อง
            ดังนั้น ยามาฮ่าจึงมีหน้าที่ที่จะต้องเก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของท่าน
            ซึ่งจะรวมถึงหากแต่มิได้จำกัดไว้แค่เพียงการดำเนินการเพื่อวัตถุประสงค์ดังต่อไปนี้
          </p>
          <ul>
            <li>การปฏิบัติตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล</li>
            <li>
              การปฏิบัติตามกฎหมายที่นำมาบังคับใช้กับเรา (เช่น
              กฎหมายเรื่องอาชญากรรมทางคอมพิวเตอร์ กฎหมายธุรกรรมอิเล็กทรอนิกส์
              กฎหมายคุ้มครองผู้บริโภค
              กฎหมายและคำสั่งที่เกี่ยวข้องกับภาษีและการบัญชี รวมทั้งกฎหมายอื่นๆ
              ที่เรามีหน้าที่ที่จะต้องปฏิบัติตาม)
            </li>
          </ul>
          <h4>
            2.3 เพื่อวัตถุประสงค์ที่ถูกต้องตามกฎหมายของยามาฮ่าและบุคคลภายนอก
            เช่น
          </h4>
          <ul>
            <li>
              การจัดทำฐานข้อมูลลูกค้าหรือการบันทึกข้อมูลในระบบสื่อสารข้อมูล
            </li>
            <li>
              การบันทึกภาพเคลื่อนไหวและการถ่ายภาพตามความเหมาะสมในคอนเสิร์ต งาน
              การทดสอบ
              และการประกวดที่จัดขึ้นเส้นทางยามาฮ่ามีส่วนให้ความร่วมมือหรือร่วมสนับสนุน
              (รวมทั้งการบันทึกเสียงและข้อมูลที่เกี่ยวข้องกับการเล่นดนตรีหรือการร้องเพลง)
            </li>
            <li>
              แบบสำรวจความพึงพอใจและการประเมินหลังจากที่มีการใช้สินค้าและ/หรือบริการ
            </li>
            <li>การติดตามหนี้สิน</li>
            <li>การฟ้องร้องหรือกระบวนการทางกฎหมายอื่นๆ</li>
            <li>การใช้กล้องวงจรปิด การควบคุมการเข้าออกสถานที่ของยามาฮ่า</li>
            <li>การบริหารจัดการข้อร้องเรียนหรือเหตุการณ์ที่ต้องสงสัย</li>
            <li>
              การดำเนินงานด้านไอที การบริหารจัดการและการป้องกันระบบ
              การรับมือและการลดจำนวนเหตุการณ์ไม่คาดฝันด้านความมั่นคงปลอดภัย
            </li>
            <li>
              การอำนวยความสะดวกในการเข้าร่วมกิจกรรม งาน แคมเปญ
              หรือการประชุมที่จัดขึ้นซึ่งได้รับความร่วมมือหรือร่วมสนับสนุนจากยามาฮ่า
            </li>
            <li>
              การวิเคราะห์ การวิจัย
              และ/หรือการจัดทำรายงานเพื่อนำไปใช้ในองค์กรของยามาฮ่า
            </li>
            <li>
              การปรับปรุงสินค้าและบริการของยามาฮ่า การพัฒนาสินค้าและบริการใหม่ๆ
              ของยามาฮ่า
            </li>
            <li>
              การบริหารจัดการผู้แทนจำหน่ายของยามาฮ่า โรงเรียนดนตรียามาฮ่า
              และบุคลากรที่เกี่ยวข้องของคู่ค้าทางธุรกิจ เช่น
              พนักงานธุรการและครูโดยเป็นไปตามคุณภาพและมาตรฐานของยามาฮ่า
            </li>
            <li>
              การจัดทำหนังสือบอกกล่าวที่เกี่ยวข้องกับสินค้าและบริการของยามาฮ่าที่ท่านร้องขอ
              รวมทั้งประกาศที่เกี่ยวข้องกับงานหรือสิทธิประโยชน์ที่เกี่ยวข้องแล้วแต่ความเหมาะสม
            </li>
            <li>
              การจัดทำประกาศสาธารณะที่เกี่ยวข้องกับสินค้า บริการ งาน
              และสิ่งที่คล้ายคลึงกันของยามาฮ่า
              คอร์ปอเรชั่นและบริษัทในเครือของยามาฮ่า
            </li>
          </ul>
          <h4>2.4 วัตถุประสงค์ที่ต้องได้รับความยินยอมจากท่าน</h4>
          <ul>
            <li>
              การวิเคราะห์และ/หรือการวิจัยเพื่อพัฒนา
              การปรับปรุงสินค้าและบริการของยามาฮ่าที่จำเป็นต้องได้รับความยินยอมจากท่านโดยเป็นไปตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
            </li>
            <li>
              การดำเนินงานด้านการตลาด การนำเสนอสินค้าและบริการของยามาฮ่า
              เอกสิทธิ์ที่ยามาฮ่ามอบให้
              ซึ่งจะต้องได้รับความยินยอมจากท่านโดยเป็นไปตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
            </li>
            <li>
              การบันทึกและการเปิดเผยข้อมูลส่วนบุคคลของท่านเช่น ภาพเคลื่อนไหว
              คำพูด
              และภาพถ่ายส่วนบุคคลเพื่อวัตถุประสงค์ด้านการค้าซึ่งจะถูกนำไปตีพิมพ์ผ่านช่องทางออนไลน์และออฟไลน์
            </li>
          </ul>
          <p className="article">
            ในกรณีที่ท่านเป็นผู้เยาว์
            บุคคลที่ไร้ความสามารถหรือเสมือนไร้ความสามารถซึ่งผู้ปกครอง
            ตัวแทนทางกฎหมาย ผู้ปกครองตามกฎหมาย และผู้คุ้มครองตามกฎหมาย
            (แล้วแต่กรณี) จะเป็นผู้ให้ความยินยอม
            (เว้นแต่กฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคลจะระบุว่าสามารถดำเนินการได้โดยไม่ต้องขอรับความยินยอม)
          </p>
          <h4>
            2.5
            วัตถุประสงค์อื่นที่ยามาฮ่าอาจจะประมวลผลข้อมูลส่วนบุคคลของท่านตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
          </h4>
          <ul>
            <li>
              การป้องกันหรือการหยุดยั้งอันตรายที่จะเกิดขึ้นกับชีวิต ร่างกาย
              หรือสุขภาพของบุคคล
            </li>
            <li>
              การจัดทำเอกสารประวัติหรือหนังสือรายปีเพื่อสิทธิประโยชน์สาธารณะหรือส่วนที่เกี่ยวข้องกับการศึกษา
              การวิจัย หรือสถิติ
            </li>
            <li>
              การดำเนินงานเพื่อผลประโยชน์สาธารณะหรือการปฏิบัติตามหน้าที่ในการใช้อำนาจจากทางราชการที่
              ยามาฮ่าได้รับ
            </li>
          </ul>
          <h3>3. การบริหารจัดการความปลอดภัยของข้อมูลส่วนบุคคล</h3>
          <p className="article">
            ยามาฮ่าจะใช้มาตรการด้านเทคนิค กายภาพ และองค์กรเพื่อที่จะรักษาความลับ
            ความถูกต้อง ความครบถ้วน
            และความพร้อมต่อการใช้งานของข้อมูลส่วนบุคคลของท่านเพื่อป้องกันไม่ให้มีการเข้าถึงข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต
            ตลอดจนการสูญหาย การถูกทำลาย การปลอมแปลง การเปิดเผย
            และสิ่งอื่นที่คล้ายคลึงกันและความพยายามที่จะปกป้องข้อมูลส่วนบุคคลของท่านด้วยการจัดตั้งระบบการบริหารจัดการความปลอดภัย
          </p>
          <h3>4. การแบ่งปันข้อมูลส่วนบุคคล</h3>
          <p className="article">
            ยามาฮ่าอาจจะว่าจ้างให้บุคคลภายนอกเป็นผู้ดำเนินการทั้งหมดหรือบางส่วนในการประมวลผลข้อมูลส่วนบุคคลของท่าน
            ยามาฮ่าจะไม่อนุมัติให้มีการใช้หรือการแจ้งข้อมูลส่วนบุคคลของท่านโดยผู้รับจ้างเว้นแต่ผู้รับจ้างจำเป็นที่จะต้องใช้หรือแจ้งข้อมูลเพื่อปฏิบัติหน้าที่ตามที่ได้รับมอบหมายหรือปฏิบัติตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
            รวมทั้งกฎหมายและคำสั่งที่นำมาบังคับใช้
            ยามาฮ่าได้กำหนดให้ผู้รับจ้างดังกล่าวคุ้มครองข้อมูลส่วนบุคคลโดยเป็นไปตามสัญญาที่ทำไว้
          </p>
          <p className="article">
            ในส่วนของการส่งมอบสินค้าและบริการของยามาฮ่า
            ยามาฮ่าอาจจะมอบสิทธิ์ให้กับผู้แทนจำหน่ายและโรงเรียนดนตรียามาฮ่า
            (แล้วแต่กรณี)
            ในการดำเนินธุรกิจในฐานะของบริษัทหรือนิติบุคคลอื่นโดยเป็นไปตามคุณภาพและมาตรฐานของยามาฮ่า
            ดังนั้น
            ยามาฮ่าอาจแบ่งปันข้อมูลส่วนบุคคลของท่านต่อผู้รับดังกล่าวเพื่อที่จะให้บริการที่เกี่ยวข้องกับสินค้าและบริการของยามาฮ่าตามที่ท่านร้องขอ
            ซื้อ หรือนำไปใช้เท่านั้น ในบางกรณี
            ท่านอาจจะอยู่ภายใต้บังคับของนโยบายการคุ้มครองข้อมูลส่วนบุคคลของผู้รับข้อมูลส่วนบุคคลของท่านตามที่กล่าวมา
          </p>
          <p className="article">
            ยามาฮ่าจะมีรายชื่อผู้รับข้อมูลส่วนบุคคลซึ่งทางยามาฮ่าอาจเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อให้บริการที่เกี่ยวข้องกับสินค้าและบริการของยามาฮ่า
            ในส่วนของนโยบายความเป็นส่วนตัวนี้
            ยามาฮ่าอาจจะเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลหรือบริษัทโดยพิจารณาตามความสัมพันธ์และธุรกรรมที่ท่านทำไว้กับบุคคลเหล่านั้น
            ดังมีรายละเอียดต่อไปนี้
            (ท่านสามารถขอให้ยามาฮ่าจัดส่งรายชื่อทั้งหมดของบุคคลและบริษัทที่ยามาฮ่าอาจจะเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลเหล่านั้น)
          </p>
          <table>
            <thead>
              <tr>
                <th>ประเภทของผู้รับข้อมูล</th>
                <th> ตัวอย่าง</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>บริษัทที่อยู่ในกลุ่มบริษัทยามาฮ่า</td>
                <td>
                  <div>- ยามาฮ่า คอร์ปอเรชั่นและบริษัทในเครือ</div>
                  <div>- บริษัท สยามกลการ จำกัด</div>
                </td>
              </tr>
              <tr>
                <td>เพื่อนำเสนอสินค้าและบริการของยามาฮ่าให้แก่ท่าน</td>
                <td>
                  <div>- ผู้แทนจำหน่ายสินค้าของยามาฮ่า</div>
                  <div>- โรงเรียนดนตรียามาฮ่า</div>
                  <div>- ผู้ให้บริการและตัวแทนภายนอกรายอื่นๆ</div>
                </td>
              </tr>
              <tr>
                <td>เพื่ออำนวยความสะดวกให้กับสินค้าและบริการของยามาฮ่า</td>
                <td>
                  <div>
                    - ผู้ให้บริการด้านเทคโนโลยี ได้แก่
                    ผู้ให้บริการคลาวด์คอมพิวติ้ง ศูนย์ข้อมูล แพลตฟอร์มข้อมูล
                    นักพัฒนา
                    และผู้จำหน่ายสินค้าที่เคยเข้าถึงข้อมูลส่วนบุคคลของท่านแล้ว
                  </div>
                  <div>- ผู้ให้บริการสื่อสังคมออนไลน์</div>
                  <div>- ผู้ให้บริการจัดส่งเอกสารหรือพัสดุ</div>
                </td>
              </tr>
              <tr>
                <td>บุคคลที่กฎหมายกำหนดไว้</td>
                <td>
                  <div>
                    ในบางกรณี
                    ยามาฮ่าอาจจำเป็นที่จะต้องเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อเป็นการปฏิบัติตามคำสั่งของบุคคลที่มีอำนาจทางกฎหมายหรือสิทธิทางกฎหมายและ/หรือเพื่อปฏิบัติตามกฎหมาย
                  </div>
                </td>
              </tr>
              <tr>
                <td>ที่ปรึกษา / ผู้เชี่ยวชาญ</td>
                <td>
                  เพื่อประโยชน์ที่จะเกิดขึ้นกับยามาฮ่า
                  ยามาฮ่าอาจเปิดเผยข้อมูลส่วนบุคคลของท่านต่อผู้ตรวจสอบภายนอก
                  ที่ปรึกษาทางกฎหมาย ที่ปรึกษาด้านบริการ
                  หรือที่ปรึกษาหรือผู้เชี่ยวชาญรายอื่น แล้วแต่กรณี
                </td>
              </tr>
              <tr>
                <td>บุคคลภายนอกรายอื่นใด</td>
                <td>
                  <div>
                    ยามาฮ่าอาจเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลภายนอกรายอื่นใดเพื่อวัตถุประสงค์ตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                    บุคคลภายนอกรายอื่นใดที่ได้รับข้อมูลส่วนบุคคลของท่านอาจจะรวมถึงหากแต่มิได้จำกัดไว้แค่เพียง
                  </div>
                  <div>
                    -
                    บุคคลที่ท่านเข้าทำสัญญาหรือมีความสัมพันธ์ที่เกี่ยวข้องกับการจัดหาสินค้าและบริการของยามาฮ่า
                    (เช่น บุคคลที่ได้รับมอบอำนาจหรือบุคคลที่เป็นผู้ติดต่อ ครู
                    นักเรียน และผู้ปกครอง หรือตัวแทนตามกฎหมาย)
                  </div>
                  <div>
                    - การควบรวมและการถือครองกิจการ
                    การปรับปรุงโครงสร้างองค์กรของยามาฮ่า
                    รวมทั้งการครอบครองและการโอนสิทธิที่เกี่ยวข้องกับสินค้าและบริการของยามาฮ่า
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <h3>5. การโอนข้อมูลส่วนบุคคล</h3>
          <h4>การโอนข้อมูลส่วนบุคคลโดยเป็นไปตามพระราชบัญญัติ</h4>
          <p>
            ยามาฮ่าดูแลเอาใจใส่เป็นอย่างมากเพื่อที่จะทำให้แน่ใจว่าข้อมูลส่วนบุคคลของท่านได้รับการประมวลผล
            ในบางครั้งคราว ยามาฮ่าอาจจะโอนข้อมูลส่วนบุคคลของท่านไปนอกประเทศไทย
            ในกรณีนี้
            ยามาฮ่าจะตรวจสอบให้แน่ใจว่าผู้รับข้อมูลมีมาตรการในการคุ้มครองข้อมูลในระดับที่เหมาะสมก่อนที่จะเปิดเผยข้อมูลส่วนบุคคลของท่านโดยเป็นไปตามกฎหมายและคำสั่งที่นำมาบังคับใช้
            ซึ่งจะรวมถึงพระราชบัญญัติด้วย
          </p>
          <h4>ในกรณีที่ไม่สามารถนำเอาพระราชบัญญัติมาบังคับใช้ได้</h4>
          <p>
            ยามาฮ่าอาจจะส่งข้อมูลส่วนบุคคลของท่านจากประเทศหนึ่งที่ท่านอาศัยอยู่ไปยังประเทศอื่น
            (รวมทั้งประเทศญี่ปุ่น)
            และจัดเก็บข้อมูลส่วนบุคคลของท่านในประเทศเหล่านั้น
            (เมื่อรวมกับการประมวลผลแล้วก็จะเรียกว่า “การโอน”)
            กฎหมายคุ้มครองความเป็นส่วนตัวที่เทียบเท่าได้กับกฎหมายของประเทศที่ท่านอาศัยอยู่อาจไม่ถูกนำมาบังคับใช้ในประเทศที่เป็นผู้รับโอนข้อมูลส่วนบุคคลของท่าน
            อย่างไรก็ตาม
            ยามาฮ่าจะประมวลผลข้อมูลส่วนบุคคลของท่านโดยเป็นไปตามกฎหมายและคำสั่งที่นำมาบังคับใช้รวมทั้งนโยบายความเป็นส่วนตัว
          </p>
          <h3>6. ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล</h3>
          <p>
            ยามาฮ่าจะจัดเก็บและใช้ข้อมูลส่วนบุคคลที่ได้รับจากลูกค้าภายในระยะเวลาที่จำเป็นต่อการปฏิบัติตามวัตถุประสงค์ในการใช้งานเท่านั้น
            ยามาฮ่าจะจัดเก็บข้อมูลส่วนบุคคลของท่านนานกว่าที่กำหนดไว้ในกรณีที่จำเป็นต่อการปฏิบัติตามบทบัญญัติว่าด้วยข้อจำกัดหรือเป็นระยะเวลาตามที่กฎหมายกำหนดไว้หรือให้การอนุญาต
            ตัวอย่างเช่น
          </p>
          <p>
            อาจมีการจัดเก็บข้อมูลส่วนบุคคลไว้สูงสุดถึง 10
            ปีหลังจากที่ท่านมิได้มีฐานะเป็นลูกค้าของเรา
            เพื่อที่จะดำเนินการเรียบร้อยตามกฎหมายโดยเป็นไปตามกฎหมาย
            ปฏิบัติตามหรือใช้สิทธิในการเรียกร้องโดยเป็นไปตามกฎหมาย
            หรือแก้ต่างการร้องเรียนทางกฎหมาย
          </p>
          <p>
            หลังจากที่เราไม่จำเป็นที่จะต้องเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้อีกต่อไป
            ยามาฮ่าจะลบข้อมูลนั้นหรือจัดเก็บข้อมูลในรูปแบบที่ไม่สามารถระบุตัวตนของเจ้าของข้อมูลได้
          </p>
          <h3>7. การประมวลผลข้อมูลส่วนบุคคลของเด็ก</h3>
          <p>
            ในส่วนของข้อมูลส่วนบุคคลของผู้เยาว์
            ยามาฮ่าไม่มีเจตจำนงที่จะอนุญาตให้เด็กซื้อสินค้าและบริการของยามาฮ่าเว้นแต่เราจะได้รับความยินยอมจากบุคคลเหล่านั้น
            (ตามที่กฎหมายที่นำมาบังคับใช้ให้การอนุญาต)
            หรือยามาฮ่าได้รับความยินยอมจากตัวแทนตามกฎหมาย เช่น
            บุคคลที่มีอำนาจในการปกครองเด็กเหล่านั้นแล้วแต่กรณีตามที่ไม้ที่นำมาบังคับใช้กำหนดไว้
            และจะดำเนินการตามความจำเป็นโดยเป็นไปตามกฎหมายและคำสั่งที่นำมาบังคับใช้
            รวมทั้งบทบัญญัติที่คล้ายคลึงกัน
          </p>
          <p>
            หากท่านมีฐานะเป็นผู้ปกครองหรือตัวแทนตามกฎหมายซึ่งเป็นผู้แจ้งข้อมูลส่วนบุคคลของผู้เยาว์ต่อยามาฮ่า
            กรุณาติดต่อกับเรา และในกรณีนี้
            ท่านอาจร้องขอที่จะใช้สิทธิ์ของท่านโดยเป็นไปตามขอบเขตที่ระบุไว้ในกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
          </p>
          <p>
            หากท่านเป็นผู้เยาว์และจำเป็นที่จะต้องซื้อสินค้าและบริการของยามาฮ่า
            เราขอให้ท่านขอรับความยินยอมจากผู้ปกครองของท่านหรือตัวแทนตามกฎหมายของท่าน
            ซึ่งสามารถกระทำการในนามของท่านเพื่อที่จะแจ้งข้อมูลส่วนบุคคลของท่านได้
            (แล้วแต่กรณีตามที่กฎหมายที่นำมาบังคับใช้กำหนดไว้)
          </p>
          <p>
            ในกรณีที่ยามาฮ่าร้องขอความยินยอมจากผู้เยาว์เพื่อที่จะใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ที่จะต้องอาศัยสิ่งอื่นนอกเหนือจากความยินยอมของผู้เยาว์แต่เพียงอย่างเดียวโดยเป็นไปตามข้อกำหนดของประมวลกฎหมายแพ่งและพาณิชย์
            ยามาฮ่าจะขอรับความยินยอมจากผู้ปกครองของผู้เยาว์หรือตัวแทนตามกฎหมายของผู้เยาว์
            ซึ่งเป็นบุคคลที่มีอำนาจในการปกครองผู้เยาว์โดยเป็นไปตามหลักเกณฑ์ที่กำหนดไว้ในกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
          </p>
          <h3>8. คุกกี้</h3>
          <p>
            ยามาฮ่าอาจสร้างคุกกี้และใช้เทคโนโลยีอื่นๆ
            ที่คล้ายคลึงกันในเว็บไซต์ของยามาฮ่ารวมทั้งเว็บไซต์อื่นๆ
            ที่ยามาฮ่าเป็นผู้ดำเนินการซึ่งระบุเป้าหมายเป็นบุคคลที่อยู่ในประเทศไทย
            การดำเนินการในส่วนนี้จะช่วยทำให้ยามาฮ่าสามารถที่จะมอบสมรรถนะที่ดียิ่งขึ้น
            บริการและโฆษณาที่เหมาะสมยิ่งขึ้นให้แก่ลูกค้าแต่ละราย
            ท่านอาจจะปฏิเสธคุกกี้ทั้งหมดผ่านทางการตั้งค่าในบราวเซอร์ของท่านและ/หรือการตั้งค่าคุกกี้
            อย่างไรก็ตาม
            การตั้งค่าในส่วนนี้อาจลดความสามารถในการทำงานในระหว่างที่มีการใช้งานเว็บไซต์อยู่
            สำหรับรายละเอียดเพิ่มเติม
            กรุณาดูในนโยบายที่เกี่ยวข้องกับคุกกี้ของเว็บไซต์แต่ละเว็บไซต์
          </p>
          <h3>9. สิทธิของลูกค้าที่เกี่ยวข้องกับข้อมูลส่วนบุคคล</h3>
          <p>
            ท่านสามารถที่จะใช้สิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่านได้โดยอยู่ภายใต้บังคับของเงื่อนไขและข้อยกเว้นตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
            ท่านสามารถที่จะใช้สิทธิ์ที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่านได้
            ซึ่งจะอยู่ภายใต้ความรับผิดชอบของยามาฮ่าในฐานะของผู้ควบคุมข้อมูลตามที่ระบุไว้ในกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
          </p>
          <p>
            ท่านสามารถใช้สิทธิ์ของท่านตามที่กฎหมายกำหนดไว้ได้
            ซึ่งจะรวมถึงหลักเกณฑ์ที่ยามาฮ่าเป็นผู้กำหนดไว้ หากท่านมีอายุต่ำกว่า
            20 ปี หรือถูกจำกัดความสามารถในการทำนิติกรรมตามกฎหมาย
            ท่านอาจจะร้องขอให้ผู้ปกครองหรือตัวแทนตามกฎหมายของท่านแสดงเจตจำนงที่จะใช้สิทธิ์เหล่านี้ในนามของท่าน
            ทั้งนี้ ภายใต้บังคับของเล่นไขและข้อยกเว้นตามกฎหมายที่นำมาบังคับใช้
            ท่านจะมีสิทธิ์ในการเข้าถึงและ/หรือขอรับสำเนา ส่ง แก้ไข ลบ
            ทำลายข้อมูลส่วนบุคคลของท่าน หรือทำให้ไม่สามารถใช้ในการระบุตัวตนได้
            ซึ่งจะรวมถึงการค้าหรือการจำกัดการเก็บรวบรวม การใช้งาน
            หรือการเปิดเผยข้อมูลส่วนบุคคลของท่าน
          </p>
          <p>
            หากยามาฮ่าได้รับความยินยอมจากท่าน
            ท่านก็สามารถที่จะเพิกถอนความยินยอมนั้นได้
            ท่านมีสิทธิ์ที่จะร้องขอข้อมูลส่วนบุคคลของท่านที่เราเป็นผู้เปิดเผยรวมทั้งวิธีการที่เราได้รับข้อมูลส่วนบุคคลของท่านโดยไม่จำเป็นต้องได้รับความยินยอม
            รวมทั้งข้อมูลส่วนบุคคลที่ท่านเชื่อว่ายามาฮ่ามิได้ปฏิบัติตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
            นอกจากนั้น
            ท่านยังมีสิทธิ์ที่จะยื่นคำร้องต่อหน่วยงานที่มีอำนาจโดยเป็นไปตามกฎหมายเรื่องการคุ้มครองข้อมูลส่วนบุคคล
          </p>
          <p>
            เจ้าของข้อมูลส่วนบุคคลจะมีสิทธิ์ในการเพิกถอนความยินยอมของตนเองไม่ว่าเมื่อใดก็ตาม
            หากแต่อย่างไรก็ตาม
            การเพิกถอนความยินยอมก็จะไม่ส่งผลต่อข้อมูลส่วนบุคคลที่ก่อนหน้านี้ได้รับการแจ้งให้ทราบและประมวลผลโดยได้รับความยินยอม
          </p>
          <p>
            ท่านสามารถยื่นคำร้องโดยใช้แบบฟอร์มคำร้องของยามาฮ่าสำหรับเจ้าของข้อมูล
            ซึ่งจะสามารถดาวน์โหลดได้ที่นี่{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacyportaluatde.onetrust.com/webform/55b5e5fc-6f4a-4be2-8d91-36d5da569818/draft/791395bb-c993-4b05-a850-c6cd73415796"
            >
              คลิก
            </a>
          </p>
          <h3>10. การปฏิบัติตามกฎหมายและคำสั่ง</h3>
          <p>
            ยามาฮ่าปฏิบัติตามกฎหมาย คำสั่ง และแนวทางปฏิบัติที่นำมาบังคับใช้
            อันมีส่วนเกี่ยวข้องกับข้อมูลส่วนบุคคล
            และแสดงความรับผิดชอบต่อสังคมในฐานะของบริษัท
          </p>
          <h3>11. การปรับปรุงอย่างต่อเนื่อง</h3>
          <p>
            ยามาฮ่าจะคอยทบทวนและปรับปรุงแผนการปฏิบัติตามข้อกำหนด
            (แผนงานในการปฏิบัติตามกฎหมาย คำสั่ง และบทบัญญัติที่คล้ายคลึงกัน)
            อย่างต่อเนื่อง
            เพื่อที่จะทำให้แน่ใจว่ามีการคุ้มครองข้อมูลส่วนบุคคลที่เชื่อถือได้
          </p>
          <h3>12. ข้อมูลในการติดต่อ</h3>
          <p>
            หากท่านมีข้อซักถามเกี่ยวกับนโยบายความเป็นส่วนตัวนี้
            ขอได้โปรดสอบถามโดยใช้ข้อมูลการติดต่อดังต่อไปนี้
          </p>
          <h3>
            <div>สำนักงานคุ้มครองข้อมูล</div>
            <div>บริษัท สยามดนตรียามาฮ่า จำกัด</div>
          </h3>
          <div>ชั้น 3, 4, 15 และ 16 อาคารสยามกลการ</div>
          <div>เลขที่ 891/1 ถนนพระราม 1 แขวงวังใหม่ เขตปทุมวัน</div>
          <div>กรุงเทพมหานคร 10330 ประเทศไทย</div>
          <div>เบอร์โทรศัพท์ (66) 02 821-6955</div>
          <div>
            ที่อยู่อีเมล{' '}
            <a href="mailto:contact@yamaha.co.th">contact@yamaha.co.th</a>{' '}
          </div>
          <h3>13. การแก้ไขปรับเปลี่ยนนโยบายความเป็นส่วนตัวนี้</h3>
          <p>
            ยามาฮ่าอาจดำเนินการแก้ไข ปรับเปลี่ยน เพิ่มเติม
            หรือลบเนื้อหาที่ปรากฏอยู่ในนโยบายความเป็นส่วนตัวนี้โดยเป็นไปตามนโยบายภายในองค์กรของยามาฮ่า
            การพัฒนาด้านเทคนิค หรือกฎหมายและคำสั่งที่นำมาบังคับใช้ ทั้งนี้
            ในกรณีที่มีการแก้ไขปรับเปลี่ยนส่วนสำคัญของนโยบายความเป็นส่วนตัวนี้
            ยามาฮ่าจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงดังกล่าวตามสมควรด้วยวิธีการที่เหมาะสม
          </p>
          <p>ณ เวลาปัจจุบัน นโยบายความเป็นส่วนตัวได้รับการตีพิมพ์ในวันที่</p>
          <p>31 พฤษภาคม 2022</p>
        </Cards.ConditionCard>
      </UserInfoConditionContainerStyled>
    );
  }
}

export default UserInfoConditionContainer;
