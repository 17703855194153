import DateTime from './DateTime';
import DropdownSelect from './DropdownSelect';
import InputCheckBox from './InputCheckBox';
import InputSearch from './InputSearch';
import Radio from './Radio';
import ScanQRCode from './ScanQRCode';
import TextArea from './TextArea';
import TextField from './TextField';
import Upload from './Upload';

const EXPORT = {
  DateTime,
  DropdownSelect,
  InputCheckBox,
  InputSearch,
  Radio,
  ScanQRCode,
  TextArea,
  TextField,
  Upload,
};

export default EXPORT;
