import styled from "styled-components";

export const RadioStyled = styled.div`
  .label {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .radio_row {
    .radio_item {
      margin-bottom: 14px;
      input {
      }
      label {
        padding-left: 12px;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    position: absolute;
    top: 4px;
    left: 4px;

    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .theme_standard {
  }

  .theme_horizontal {
    .radio_row {
      display: flex;
      .radio_item {
        flex: 1;
      }
    }
  }

  .theme_question {
    .label {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    }
  }
`;
