import styled from "styled-components";

export const ActivityQuestionformStyled = styled.div`
  .input_wrap {
    margin-bottom: 8px;
    .f_question_img {
      margin-bottom: 1rem;
      width: 100%;
      height: auto;
    }
  }
  .passed_label {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .btn_submit_wrap {
    margin: auto;
    margin-top: 1.75rem;
    max-width: 145px;
  }
`;
