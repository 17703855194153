import styled from "styled-components";

export const PageLayoutStyled = styled.div`
  position: relative;
  margin: auto;
  padding: 60px 28px;
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  .lang_mainlayout {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }
`;
