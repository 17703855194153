import styled from "styled-components";

export const RegisterFormStyled = styled.div`
  .input_wrap {
    margin-bottom: 15px;
    &.mb_12 {
      margin-bottom: 12px;
    }
    &.mb_82 {
      margin-bottom: 82px;
    }
  }
`;
