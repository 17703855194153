import React from 'react';

export default function renderIcon({
  width = 36.112,
  height = 39.097,
  color = '#fff',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36.112 39.097"
    >
      <path
        id="Path_193"
        data-name="Path 193"
        d="M1969.92,915.787a1.667,1.667,0,0,0-1.546-1.476c-.444-.029-1.052-.077-2.561-.1l-1.58,1.712-13.059,13.888-5.7-5.429-2.823,2.935,8.9,8.479,15.119-16.912a48.116,48.116,0,0,1-1.362,10.035c-2.935,12.266-11.717,16.151-13.344,16.774-1.57-.612-10.429-4.572-13.35-16.774a46.078,46.078,0,0,1-1.365-11.429c3.022-.339,9.959-1.362,14.052-3.927a28.359,28.359,0,0,0,8.658,2.95l2.713-2.889c-3.8-.624-8.163-1.688-10.258-3.335a1.665,1.665,0,0,0-1.029-.356h-.18a1.665,1.665,0,0,0-1.029.356c-3.378,2.653-11.712,3.8-14.639,4.018a1.666,1.666,0,0,0-1.532,1.474,43.966,43.966,0,0,0,1.368,13.912c3.622,15.134,15.3,19.093,15.8,19.255a1.67,1.67,0,0,0,.515.081h.551a1.674,1.674,0,0,0,.516-.081c.5-.161,12.175-4.12,15.8-19.255a43.944,43.944,0,0,0,1.368-13.912"
        transform="translate(-1933.908 -909.938)"
        fill={color}
      />
    </svg>
  );
}
