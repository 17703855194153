import styled from 'styled-components';

export const MyPointWidgetStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 9px;
  .my_point_label {
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .my_point {
    border-radius: 12px;
    padding: 0 16px;
    min-width: 46px;
    heigth: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
`;
