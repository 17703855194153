import React from 'react';
import { PointRedeemQRCodeContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import { userService } from 'apiServices';
import { Buttons, Displays, Navigations } from 'components';

class PointRedeemQRCodeContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { router } = this.props;
    let res = await userService.GET_REWARD_HISTORY_DETAIL(router.params?.id);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <PointRedeemQRCodeContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="tab_zone">
              <Navigations.BottomTab
                data={[
                  {
                    key: 'reward_eletronic',
                    name: 'รหัส',
                    element: (
                      <div>
                        {`รหัส ${data.reward_e_counpon_code}`}
                        {/* <div className="btn_link">
                          <a
                            href={'data.code'}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Go to redeem
                          </a>
                        </div> */}
                        {/* {(data.code.search('https') !== -1 ||
                      data.code.search('http') !== -1) && (
                      <div className="btn_link">
                        <a
                          href={data.code}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Go to redeem
                        </a>
                      </div>
                    )} */}
                      </div>
                    ),
                  },
                  {
                    key: 'reward_card',
                    name: 'บาร์โค้ด',
                    element: (
                      <div style={{ textAlign: 'center' }}>
                        <Barcode
                          value={data.reward_e_counpon_code}
                          width={0.5}
                          displayValue={false}
                        />
                      </div>
                    ),
                  },
                  {
                    key: 'reward_etc',
                    name: 'คิวอาร์โค้ด',
                    element: (
                      <div style={{ textAlign: 'center' }}>
                        <QRCode value={data.reward_e_counpon_code} size={190} />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className="prq_btn_wrap">
              <Buttons.BgStandard label={'กลับหน้าแรกรางวัล'} />
            </div>
          </>
        )}
      </PointRedeemQRCodeContainerStyled>
    );
  }
}

export default withRouter(PointRedeemQRCodeContainer);

// const mapStateToProps = (state) => ({
//   authenRedux: state.authenRedux,
//   companySelectedRedux: state.companySelectedRedux,
// });

// const mapDispatchToProps = (dispatch) => ({});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(PointRedeemQRCodeContainer)));
