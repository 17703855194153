import styled from 'styled-components';

export const InputCheckBoxStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .container_wrap {
    width: 50%;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }

  /* The container */
  .container {
    display: block;
    position: relative;
    // padding-left: 33px;
    // padding-top: 2px;
    padding-right: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    // position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0px;
  }

  .lable_show {
    margin-left: 4px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    margin-top: 1.7px;
    top: 0px;
    left: 0;
    max-height: 15px;
    max-width: 15px;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.COLORS.WHITE_1};
    border: 1.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    border-radius: 0px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    // background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    // border: 1px solid #F0211E;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    left: 6px;
    top: 3.5px;
    width: 1px;
    height: 4px;
    border: solid ${({ theme }) => theme.COLORS.WHITE_1};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
