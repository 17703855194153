import styled from 'styled-components';

export const NewInfoStyled = styled.div`
  .new_info_container {
    .ni_title {
      margin-bottom: 5px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_SEMIBOLD};
    }
    .ni_img {
      margin-bottom: 14px;
      width: 100%;
      heigth: auto;
    }
    .ni_description {
      white-space: pre-wrap;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }

  .theme_standard {
  }
`;
