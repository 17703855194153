import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ActivityStyled } from "./styled";
import { IMG_PATH } from "utils/constants/imgPath";

const Activity = ({
  theme_standard,
  src,
  title,
  description,
  status,
  permission_type,
  announcement_type,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  // กิจกรรมแบบ select by admin (จำกัดสิทธิ์)
  // 1. เมื่อกิจกรรมออกใหม่ > ไม่ขึ้น Lable
  // 2. เมื่อสมัครแบบ select by admin > ขึ้น "Applied"
  // 3. เมื่อ admin เลือก > ขึ้น "Resigtered"
  // 4. เมื่อadmin ไม่ได้เลือก > ขึ้น "Fully-booked"

  // กิจกรรมแบบ run by Que (จำกัดสิทธิ์)
  // 1. เมื่อกิจกรรมออกใหม่ > ไม่ขึ้น Lable
  // 2. เมื่อสมัครแบบ run by Que แล้วได้รับเลือกเพราะอยู่ในคิว > ขึ้น "Resigtered"
  // 3. เมื่อสมัครแบบ run by Que แล้วไม่ได้รับเลือกเพราะสิทธิ์เต็ม > ขึ้น "Fully-booked"  เคสนี้ไม่มีเพราะไม่ใ้หลงเลย

  // กิจกรรมแบบ อื่นๆ (ไม่จำกัดสิทธิ์)
  // 1. เมื่อกิจกรรมออกใหม่ > ไม่ขึ้น Lable
  // 2. เมื่อสมัครแบบ สำเร็จ  > ขึ้น "Resigtered"
  const _renderStatus = () => {
    // LV 1 PERMISSION
    // ====================
    switch (permission_type) {
      case "1":
        // LV 2 STATUS
        // ====================
        switch (status) {
          case "1":
          case "2":
            return (
              <div className="status_line">
                <img
                  alt="activity status"
                  src="/assets/images/icons/activity-registed.png"
                />
              </div>
            );
          case "3":
            return (
              <div className="status_line">
                <img
                  alt="activity status"
                  src="/assets/images/icons/activity-success.png"
                />
              </div>
            );
          case "4":
            return (
              <div className="status_line">
                <img
                  alt="activity status"
                  src="/assets/images/icons/activity-reject.png"
                />
              </div>
            );
          default:
            break;
        }
        break;
      case "2":
        // LV 2 ANNOCEMENT
        // ====================
        switch (announcement_type) {
          case "1":
            // LV 3 STATUS
            // ====================
            switch (status) {
              case "1":
              case "2":
                return (
                  <div className="status_line">
                    <img
                      alt="activity status"
                      src="/assets/images/icons/activity-registed.png"
                    />
                  </div>
                );
              case "-2":
                return (
                  <div className="status_line">
                    <img
                      alt="activity status"
                      src="/assets/images/icons/activity-full.png"
                    />
                  </div>
                );
              default:
                break;
            }
            break;
          case "2":
            switch (status) {
              case "1":
              case "2":
                return (
                  <div className="status_line">
                    <img
                      alt="activity status"
                      src="/assets/images/icons/activity-registed.png"
                    />
                  </div>
                );
              case "3":
                return (
                  <div className="status_line">
                    <img
                      alt="activity status"
                      src="/assets/images/icons/activity-success.png"
                    />
                  </div>
                );
              case "4":
                return (
                  <div className="status_line">
                    <img
                      alt="activity status"
                      src="/assets/images/icons/activity-full.png"
                    />
                  </div>
                );
              default:
                break;
            }
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  return (
    <ActivityStyled>
      <div className={customClass}>
        <div className="new_container">
          <div className="new_img_wrap">
            <img
              className="new_img"
              alt="news yamaha"
              src={src ? IMG_PATH + src : "/assets/images/mockups/new-1.jpg"}
            />
            {_renderStatus()}
          </div>
          <div className="new_content_block">
            <div className="n_header">{title}</div>
            <div className="n_description" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    </ActivityStyled>
  );
};

Activity.propTypes = {};

export default Activity;
