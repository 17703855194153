import React from "react";

export default function renderIcon({
  width = 11.64,
  height = 9.345,
  color = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11.64 9.345"
    >
      <g
        id="Group_460"
        data-name="Group 460"
        transform="translate(-172.18 -861.655)"
      >
        <line
          id="Line_223"
          data-name="Line 223"
          y1="8.517"
          transform="translate(178 861.655)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line
          id="Line_224"
          data-name="Line 224"
          y1="5"
          x2="6"
          transform="translate(177.5 865.5)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line
          id="Line_225"
          data-name="Line 225"
          x1="6"
          y1="5"
          transform="translate(172.5 865.5)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <line
          id="Line_226"
          data-name="Line 226"
          x2="11"
          transform="translate(172.5 870.5)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
