import React from 'react';
import { LoginAdminContainerStyled } from './styled';
import { connect } from 'react-redux';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { setReduxAuthen } from 'store/reducers/authenReducer';
import { setReduxWallet } from 'store/reducers/walletReducer';
import { Forms } from 'components';

class LoginAdminContainer extends React.Component {
  state = {};

  handleSubmit = async (values) => {
    let params = {
      ...values,
    };
    let res = await userService.POST_ADMIN_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      this.props.router.navigate(ROUTE_PATH.ACTIVITIES_ADMIN_SCAN);
    } else {
      toast.error('Login failed');
    }
  };

  render() {
    return (
      <LoginAdminContainerStyled>
        <Forms.LoginForm onSubmit={this.handleSubmit} />
      </LoginAdminContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginAdminContainer));
