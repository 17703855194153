import styled from 'styled-components';

export const BtnEmailUpdateWidgetStyled = styled.div`
  border-radius: 0.75rem;
  padding: 0.25rem 0.75rem;
  background: ${({ theme }) => theme.COLORS.PURPLE_1};
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONT.SIZE.S13};
  font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  cursor: pointer;
`;
