import React from 'react';

export default function renderIcon({
  width = 40.279,
  height = 32.753,
  color = '#4b1e78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40.279 32.753"
    >
      <g
        id="Group_309"
        data-name="Group 309"
        transform="translate(-279.245 -856.58)"
      >
        <path
          id="Path_103"
          data-name="Path 103"
          d="M2217.168,1469.036V1440.75h35.279v5.013h5v23.631a3.528,3.528,0,0,0-.144.41c-.588,2.65-1.87,3.695-4.537,3.695h-19.215c-3.724,0-7.448.014-11.172,0-2.809-.014-4.392-1.277-5.048-3.963-.042-.168-.109-.33-.164-.495m2.506-25.752v.959q0,11.807,0,23.614c0,2.3.835,3.14,3.109,3.141h27.956a5.731,5.731,0,0,1-.823-3.782q.01-11.449,0-22.9v-1.034Z"
          transform="translate(-1937.923 -584.17)"
          fill={color}
        />
        <rect
          id="Rectangle_33"
          data-name="Rectangle 33"
          width="11.193"
          height="12.41"
          transform="translate(284.327 869.285)"
          fill={color}
        />
        <rect
          id="Rectangle_34"
          data-name="Rectangle 34"
          width="25.048"
          height="2.351"
          transform="translate(284.325 864.216)"
          fill={color}
        />
        <rect
          id="Rectangle_35"
          data-name="Rectangle 35"
          width="11.162"
          height="2.331"
          transform="translate(298.234 869.277)"
          fill={color}
        />
        <rect
          id="Rectangle_36"
          data-name="Rectangle 36"
          width="11.16"
          height="2.325"
          transform="translate(298.236 874.316)"
          fill={color}
        />
        <rect
          id="Rectangle_37"
          data-name="Rectangle 37"
          width="8.632"
          height="2.321"
          transform="translate(298.209 879.359)"
          fill={color}
        />
      </g>
    </svg>
  );
}
