import styled from 'styled-components';

export const UserInfoConditionContainerStyled = styled.div`
  border-radius: 38px;
  padding: 20px 36px 30px 36px;
  max-width: 370px;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  color: ${({ theme }) => theme.COLORS.GRAY_2};
  font-size: ${({ theme }) => theme.FONT.SIZE.S14};
  font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  .cdc_header {
    margin-bottom: 6px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .cdc_body {
    margin-bottom: 18px;
  }
  .cdc_footer {
  }

  .article {
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }

  table {
    border-spacing: 0px;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    tr {
      margin: 0;
      padding: 0;
      th {
        border-bottom: 1px solid gray;
        padding: 4px;
        color: ${({ theme }) => theme.COLORS.GRAY_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        &:last-child {
          border-left: 1px solid gray;
        }
      }
    }
    tr {
      margin: 0;
      padding: 0;
      td {
        border-bottom: 1px solid gray;
        padding: 4px;
        color: ${({ theme }) => theme.COLORS.GRAY_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
        &:last-child {
          border-left: 1px solid gray;
        }
      }
    }
  }
`;
