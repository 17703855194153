import styled from "styled-components";

export const RegisterContainerStyled = styled.div`
  .title_form {
    margin-bottom: 26px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
  }
  .input_regis_type_wrap {
    margin-bottom: 42px;
  }
`;
