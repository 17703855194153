import React, { useEffect } from "react";
import { PersonalInfoFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const PersonalInfoForm = ({ initialValue, onSubmit }) => {
  const schema = yup.object().shape({
    personal_first_name: yup.string().required("First Name is required"),
    personal_last_name: yup.string().required("Last Name is required"),
    personal_email: yup.string().email().required("Email is required"),
    personal_phone_number: yup
      .string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Invalid phone number"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValue) {
      reset({ ...initialValue });
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation(["common", "form"]);

  return (
    <PersonalInfoFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                label={t("First Name", { ns: "form" })}
                errors={errors.personal_first_name?.message}
              />
            )}
            name="personal_first_name"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                label={t("Last Name", { ns: "form" })}
                errors={errors.personal_last_name?.message}
              />
            )}
            name="personal_last_name"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                label={t("Email", { ns: "form" })}
                errors={errors.personal_email?.message}
              />
            )}
            name="personal_email"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_32">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                label={t("Phone Number", { ns: "form" })}
                errors={errors.personal_phone_number?.message}
              />
            )}
            name="personal_phone_number"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label={t("Next", { ns: "common" })} />
      </form>
    </PersonalInfoFormStyled>
  );
};

PersonalInfoForm.propTypes = {};

export default PersonalInfoForm;
