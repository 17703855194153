import React from "react";
import { ActivitiesFormDetailContainerStyled } from "./styled";
import { connect } from "react-redux";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { setReduxWallet } from "store/reducers/walletReducer";
import Linkify from "react-linkify";
import { withTranslation } from "react-i18next";
import { IMG_PATH } from "utils/constants/imgPath";
import QRCode from "react-qr-code";
import { MyPointWidget } from "widgets";
import { Buttons, Cards, Displays, Headers, Icons, Modals } from "components";

class ActivitiesFormDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { authenRedux, router } = this.props;
    let res = await Promise.all([
      userService.GET_ACTIVITY_FORM_DETAIL(router.params?.id),
      userService.GET_ACTIVITY_FORM_HISTORY_BY_ME_ACTIVITY(
        router.params?.id,
        authenRedux.member_type_active
      ),
    ]);
    if (res && res[0].status === 200) {
      this.setState({
        data: res[0].data,
        activityRegisData: res[1].data ? res[1].data : false,
        isShowModal: false,
        isLoading: false,
        isBgLoading: false,
      });
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(`${ROUTE_PATH.ACTIVITIES}`);
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
      renderModal: false,
    });
  };

  handleActivityRegis = () => {
    const { router } = this.props;
    this.props.router.navigate(
      `${ROUTE_PATH.ACTIVITY_FORM}/${router.params?.id}/quiz/create`
    );
  };

  handleEdit = (e) => {
    const { router } = this.props;
    this.props.router.navigate(
      `${ROUTE_PATH.ACTIVITY_FORM}/${router.params?.id}/quiz/${e._id}`
    );
  };

  handleDownloadDoc = (e) => {
    const pdfPath = IMG_PATH + e; // ปรับ path ตามที่ต้องการ
    const link = document.createElement("a");
    link.href = pdfPath;
    link.setAttribute("download", "statistics.pdf"); // ตั้งชื่อไฟล์ที่ต้องการดาวน์โหลด
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleShowQrCode = (e) => {
    this.setState({
      renderModal: (
        <div className="qrcode_container">
          <div className="btn_close" onClick={this.handleCloseModal}>
            <Icons.Close />
          </div>
          <QRCode
            value={`${e.customer_ref_id},${e.activity_form_ref_id},${e._id},${
              e.member_type || "1"
            },2`}
            size={170}
          />
        </div>
      ),
      isShowModal: true,
    });
  };

  render() {
    const { t } = this.props;
    const {
      isLoading,
      isBgLoading,
      isShowModal,
      renderModal,
      data,
      activityRegisData,
    } = this.state;
    return (
      <ActivitiesFormDetailContainerStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={"25px"}
          icon={<Icons.Music />}
          topLabel={"Online Form"}
          bottomLabel={"แบบฟอร์ม"}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <div className="activity_wrap">
              <img
                className="act_img"
                alt="activity"
                src={
                  data.img
                    ? IMG_PATH + data.img
                    : "/assets/images/mockups/activities-1.jpg"
                }
              />
            </div>
            <div className="activities_title">{data.name}</div>
            <div className="activities_sub_article">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                <div dangerouslySetInnerHTML={{ __html: data.description }} />
              </Linkify>
            </div>
            {data.document && (
              <div className="doc_block">
                <div className="doc_label">{data.document_name}</div>
                <button
                  type="button"
                  className="doc_download"
                  onClick={() => this.handleDownloadDoc(data.document)}
                >
                  <Icons.Download color="#4B1E78" /> {t("Download")}
                </button>
              </div>
            )}
            {data.img2 && (
              <div className="img_position2">
                <img
                  className="act_img2"
                  alt="activity"
                  src={
                    data.img2
                      ? IMG_PATH + data.img2
                      : "/assets/images/mockups/activities-1.jpg"
                  }
                />
              </div>
            )}
            {!activityRegisData || data.is_active ? (
              <div className="btn_wrap">
                <Buttons.BgStandard
                  label={t("Register", { ns: "common" })}
                  height="50px"
                  onClick={() => {
                    this.handleActivityRegis();
                  }}
                />
              </div>
            ) : (
              <div className="action_row">
                <Buttons.BgStandard
                  label={t("Back", { ns: "common" })}
                  onClick={this.handleClickBack}
                />
              </div>
            )}
          </>
        )}
        {activityRegisData && activityRegisData.length > 0 && (
          <div className="member_registed_layout">
            <div className="mbr_title">รายชื่อผู้สมัครแล้ว</div>
            {activityRegisData.map((e, i) => (
              <div key={i} className="mbr_item_wrap">
                <Cards.CustomerActFRegisItem
                  {...e}
                  no={i + 1}
                  onEdit={() => this.handleEdit(e)}
                  onShowQrCode={() => this.handleShowQrCode(e)}
                />
              </div>
            ))}
          </div>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </ActivitiesFormDetailContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxWallet: (data) => dispatch(setReduxWallet(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(withRouter(ActivitiesFormDetailContainer)));
