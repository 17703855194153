import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { UserInfoStyled } from "./styled";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BtnEmailUpdateWidget from "widgets/BtnEmailUpdateWidget";

const UserInfo = ({
  theme_standard,
  first_name,
  last_name,
  phone_number,
  province,
  birthday,
  email,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t } = useTranslation(["profile"]);

  return (
    <UserInfoStyled>
      <div className={customClass}>
        <div className="user_info_container">
          <div className="ui_row">
            <div className="ui_label">
              {t("First name", { ns: "profile" })} :{" "}
            </div>
            <div className="ui_value">
              {`${first_name}`}
              <BtnEmailUpdateWidget type="first_name" />
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">
              {t("Last name", { ns: "profile" })} :{" "}
            </div>
            <div className="ui_value">
              {`${last_name}`} <BtnEmailUpdateWidget type="last_name" />
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Mobile number", { ns: "profile" })} : </div>
            <div className="ui_value">
              {phone_number} <BtnEmailUpdateWidget type="phone_number" />
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Address", { ns: "profile" })} : </div>
            <div className="ui_value">{province}</div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Date of Birth", { ns: "profile" })} :</div>
            <div className="ui_value">
              {moment(birthday).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="ui_row">
            <div className="ui_label">{t("Email", { ns: "profile" })} : </div>
            <div className="ui_value">
              {email ? email : <BtnEmailUpdateWidget />}
            </div>
          </div>
        </div>
      </div>
    </UserInfoStyled>
  );
};

UserInfo.propTypes = {};

export default UserInfo;
