import styled from 'styled-components';

export const ShippingFormStyled = styled.div`
  .form_title {
    margin-bottom: 9px;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S19};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .input_wrap {
    margin-bottom: 12px;
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 145px;
  }
`;
