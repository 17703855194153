import styled from "styled-components";

export const HeaderRowPanelStyled = styled.div`
  .table_container {
    .table_header {
      .header_row {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        .header_col {
          width: 100%;
          padding: 0px 6px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S16};
          font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.PURPLE_1};
          &:first-child {
            border-left: none;
          }
        }
      }
    }
    .table_body {
      .label_empty {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.COLORS.GRAY_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
      }
      .body_row_wrap {
        .body_row {
          max-width: 1200px;
          width: 100%;
          margin: auto;
          display: flex;
          cursor: pointer;
          padding: 10px 0;
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
            font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
            color: ${({ theme }) => theme.COLORS.PURPLE_1};
            padding: 0 6px;
            width: 100%;
            word-break: break-all;
          }
        }
        &:hover {
          background: ${({ theme }) => theme.COLORS.GRAY_5}0D;
        }
      }
    }
  }

  .theme_standard {
  }
`;
