import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const authenSlice = createSlice({
  name: "authenReducer",
  initialState,
  reducers: {
    setReduxAuthen: (state, action) => {
      state = action.payload;
      return state;
    },

    setReduxAuthenClear: (state) => {
      state = false;
      return state;
    },
    setReduxAuthenFirstname: (state, action) => {
      state["first_name"] = action.payload;
      return state;
    },
    setReduxAuthenLastname: (state, action) => {
      state["last_name"] = action.payload;
      return state;
    },
    setReduxAuthenPhoneNumber: (state, action) => {
      state["phone_number"] = action.payload;
      return state;
    },
    setReduxAuthenEmail: (state, action) => {
      state["email"] = action.payload;
      return state;
    },
    setReduxAuthenPDPA: (state, action) => {
      state["pdpa_accepted_latest_ref_id"] = action.payload;
      return state;
    },
    setReduxAuthenOrganizationPhoneNumber: (state, action) => {
      state["organization_detail"]["phone_number"] = action.payload;
      return state;
    },
    setReduxAuthenOrganizationProvince: (state, action) => {
      state["organization_detail"]["province"] = action.payload;
      return state;
    },
    setReduxAuthenOrganizationEmail: (state, action) => {
      state["organization_detail"]["email"] = action.payload;
      return state;
    },
  },
});
export const {
  setReduxAuthen,
  setReduxAuthenClear,
  setReduxAuthenEmail,
  setReduxAuthenFirstname,
  setReduxAuthenLastname,
  setReduxAuthenPhoneNumber,
  setReduxAuthenPDPA,
  setReduxAuthenOrganizationPhoneNumber,
  setReduxAuthenOrganizationProvince,
  setReduxAuthenOrganizationEmail,
} = authenSlice.actions;

export default authenSlice.reducer;
