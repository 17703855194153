import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ProductWarranteeStyled } from './styled';
import {
  CAL_WARRANTY_EXPIRE,
  RENDER_STATUS,
} from 'utils/functions/productWarranty';
import { useTranslation } from 'react-i18next';
import { RENDER_UNIT } from 'utils/functions/unit';

const ProductWarrantee = ({
  theme_standard,
  description,
  descriptionLine2,
  sn,
  warrantyPeriod,
  warrantyUnit,
  warrantyDescription,
  rejectedReason,
  approvedWarrantyDate,
  extensionPeriod,
  extensionUnit,
  status,
  onEdit,
  isEdit,
  isDelete,
  onDelete
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t, i18n } = useTranslation(['history']);

  const renderStatus = RENDER_STATUS(status);
  const renderUnit = RENDER_UNIT(warrantyUnit);
  const renderExtensionUnit = RENDER_UNIT(extensionUnit);
  const renderWarrantyExpire =
    status !== '3'
      ? false
      : CAL_WARRANTY_EXPIRE(
          approvedWarrantyDate,
          warrantyPeriod,
          warrantyUnit,
          extensionPeriod,
          extensionUnit
        );
  return (
    <ProductWarranteeStyled statusBg={renderStatus && renderStatus.bgColor}>
      <div className={customClass}>
        <div className="product_warrantee_container">
          <div className="pw_row">
            <div className="label">
              {t('Product Name', { ns: 'history' })} :{' '}
            </div>
            <div className="value">
              {description}
              {descriptionLine2 ? ` ${descriptionLine2}` : ''}
            </div>
          </div>
          <div className="pw_row">
            <div className="label">{t('Product No', { ns: 'history' })} : </div>
            <div className="value">{sn}</div>
          </div>
          <div className="pw_row">
            <div className="label">{t('Warranty', { ns: 'history' })} : </div>
            <div className="value flex">
              {warrantyPeriod}
              {renderUnit
                ? ` ${
                    i18n.language === 'en'
                      ? renderUnit.labelEN
                      : renderUnit.labelTH
                  }`
                : ''}
              {renderStatus && (
                <div className="pw_status">
                  {t(renderStatus.label, { ns: 'history' })}
                </div>
              )}
            </div>
          </div>
          {extensionPeriod && (
            <div className="pw_row">
              <div className="label">
                {t('Warranty Extension', { ns: 'history' })} :{' '}
              </div>
              <div className="value flex">
                {extensionPeriod}
                {renderExtensionUnit
                  ? ` ${
                      i18n.language === 'en'
                        ? renderExtensionUnit.labelEN
                        : renderExtensionUnit.labelTH
                    }`
                  : ''}
              </div>
            </div>
          )}
          {renderWarrantyExpire && (
            <div className="pw_row">
              <div className="label">
                {t('Expiration date', { ns: 'history' })} :{' '}
              </div>
              <div className="value">
                {renderWarrantyExpire.format('DD/MM/YYYY')}
              </div>
            </div>
          )}
          <div className="pw_row">
            <div className="label">{t('Condition', { ns: 'history' })} : </div>
            <div className="value">{warrantyDescription}</div>
          </div>
          {status === '5' && (
            <>
              <div className="pw_row">
                <div className="label">
                  {t('Reason for cancellation', { ns: 'history' })} :{' '}
                </div>
                <div className="value">{rejectedReason}</div>
              </div>
              {isEdit && (
                <div className="btn_edit_pw" onClick={onEdit}>
                  {t('Edit', { ns: 'common' })}
                </div>
              )}
            </>
          )}
          {isDelete && (
            <div className="btn_edit_pw" onClick={onDelete}>
              {t('Delete', { ns: 'common' })}
            </div>
          )}
        </div>
      </div>
    </ProductWarranteeStyled>
  );
};

ProductWarrantee.propTypes = {};

export default ProductWarrantee;
