import styled from "styled-components";

export const QuestionStyled = styled.div`
  .new_container {
    display: flex;
    column-gap: 15px;
    cursor: pointer;
    .n_left_col {
      flex-basis: 97px;
      flex-shrink: 0;
      .new_img {
        width: 97px;
        height: 97px;
        object-fit: cover;
      }
    }
    .n_right_col {
      overflow: hidden;
      .n_header {
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.COLORS.PURPLE_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S19};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
      .n_description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONT.SIZE.S11};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }

  .theme_standard {
  }
`;
