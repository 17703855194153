import React from 'react';

export default function renderIcon({
  width = 45.542,
  height = 37.454,
  color = '#fff',
}) {
  return (
    <svg
      id="Group_226"
      data-name="Group 226"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.542 37.454"
    >
      <g id="Group_223" data-name="Group 223" transform="translate(4.028)">
        <path
          id="Path_3769"
          data-name="Path 3769"
          d="M1207.436,5535.569h-40.168a.673.673,0,1,1,0-1.346h40.168a.673.673,0,1,1,0,1.346Z"
          transform="translate(-1166.595 -5524.278)"
          fill={color}
        />
        <path
          id="Path_3770"
          data-name="Path 3770"
          d="M1226.707,5545.406h-8.364a.673.673,0,1,1,0-1.346h8.364a.673.673,0,1,1,0,1.346Z"
          transform="translate(-1185.865 -5527.989)"
          fill={color}
        />
        <path
          id="Path_3771"
          data-name="Path 3771"
          d="M1187.059,5555.244h-19.791a.673.673,0,1,1,0-1.346h19.791a.673.673,0,1,1,0,1.346Z"
          transform="translate(-1166.595 -5531.701)"
          fill={color}
        />
        <path
          id="Path_3772"
          data-name="Path 3772"
          d="M1224.147,5555.244h-12.589a.673.673,0,1,1,0-1.346h12.589a.673.673,0,1,1,0,1.346Z"
          transform="translate(-1183.306 -5531.701)"
          fill={color}
        />
        <path
          id="Path_3773"
          data-name="Path 3773"
          d="M1184.745,5565.081h-17.477a.673.673,0,0,1,0-1.347h17.477a.673.673,0,0,1,0,1.347Z"
          transform="translate(-1166.595 -5535.413)"
          fill={color}
        />
        <path
          id="Path_3774"
          data-name="Path 3774"
          d="M1224.363,5565.081h-12.232a.673.673,0,0,1,0-1.347h12.232a.673.673,0,0,1,0,1.347Z"
          transform="translate(-1183.522 -5535.413)"
          fill={color}
        />
        <path
          id="Path_3775"
          data-name="Path 3775"
          d="M1192.276,5545.406h-25.008a.673.673,0,1,1,0-1.346h25.008a.673.673,0,1,1,0,1.346Z"
          transform="translate(-1166.595 -5527.989)"
          fill={color}
        />
        <path
          id="Path_3776"
          data-name="Path 3776"
          d="M1189.576,5574.773a3.027,3.027,0,1,1,3.49-2.991A3.274,3.274,0,0,1,1189.576,5574.773Zm0-4.637a1.7,1.7,0,1,0,2.144,1.646A1.947,1.947,0,0,0,1189.576,5570.136Z"
          transform="translate(-1173.948 -5537.32)"
          fill={color}
        />
        <path
          id="Path_3777"
          data-name="Path 3777"
          d="M1195.807,5565.7a.674.674,0,0,1-.673-.673v-10.684a.673.673,0,0,1,.545-.661l9.737-1.9a.675.675,0,0,1,.8.662v11.02a.673.673,0,1,1-1.346,0v-10.2l-8.391,1.633v10.129A.673.673,0,0,1,1195.807,5565.7Z"
          transform="translate(-1177.363 -5530.899)"
          fill={color}
        />
        <path
          id="Path_3778"
          data-name="Path 3778"
          d="M1182.069,5551.367a3.531,3.531,0,1,1,4.071-3.49A3.818,3.818,0,0,1,1182.069,5551.367Zm0-5.409a1.987,1.987,0,1,0,2.5,1.919A2.272,2.272,0,0,0,1182.069,5545.958Z"
          transform="translate(-1170.897 -5528.113)"
          fill={color}
        />
        <path
          id="Path_3779"
          data-name="Path 3779"
          d="M1189.336,5541.343a.785.785,0,0,1-.785-.785v-12.853a.786.786,0,0,1,1.134-.7,5.717,5.717,0,0,0,2.1.565h3.241a.77.77,0,0,1,.27.048c.227.083,2.211.858,2.211,2.458a.785.785,0,0,1-1.57,0c0-.32-.592-.73-1.063-.935h-3.089a5.792,5.792,0,0,1-1.663-.286v11.707A.785.785,0,0,1,1189.336,5541.343Z"
          transform="translate(-1174.879 -5521.522)"
          fill={color}
        />
        <path
          id="Path_3780"
          data-name="Path 3780"
          d="M1211.025,5546.482a3.531,3.531,0,1,1,4.071-3.489A3.818,3.818,0,0,1,1211.025,5546.482Zm0-5.409a1.987,1.987,0,1,0,2.5,1.919A2.272,2.272,0,0,0,1211.025,5541.074Z"
          transform="translate(-1181.822 -5526.271)"
          fill={color}
        />
        <path
          id="Path_3781"
          data-name="Path 3781"
          d="M1218.292,5535.367a.785.785,0,0,1-.785-.784v-15.546a.785.785,0,0,1,1.571,0v15.546A.785.785,0,0,1,1218.292,5535.367Z"
          transform="translate(-1185.804 -5518.252)"
          fill={color}
        />
        <path
          id="Path_3782"
          data-name="Path 3782"
          d="M1205.212,5573.353a3.028,3.028,0,1,1,3.491-2.992A3.275,3.275,0,0,1,1205.212,5573.353Zm0-4.638a1.7,1.7,0,1,0,2.145,1.646A1.948,1.948,0,0,0,1205.212,5568.714Z"
          transform="translate(-1179.848 -5536.783)"
          fill={color}
        />
        <path
          id="Path_3783"
          data-name="Path 3783"
          d="M1195.806,5560.36a.673.673,0,0,1-.115-1.336l9.736-1.7a.674.674,0,0,1,.232,1.327l-9.737,1.7A.726.726,0,0,1,1195.806,5560.36Z"
          transform="translate(-1177.363 -5532.992)"
          fill={color}
        />
      </g>
      <g
        id="Group_224"
        data-name="Group 224"
        transform="translate(40.624 1.501)"
      >
        <circle
          id="Ellipse_19"
          data-name="Ellipse 19"
          cx="0.789"
          cy="0.789"
          r="0.789"
          fill={color}
        />
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="0.789"
          cy="0.789"
          r="0.789"
          transform="translate(2.808)"
          fill={color}
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="0.789"
          cy="0.789"
          r="0.789"
          transform="translate(0 2.473)"
          fill={color}
        />
        <circle
          id="Ellipse_22"
          data-name="Ellipse 22"
          cx="0.789"
          cy="0.789"
          r="0.789"
          transform="translate(2.808 2.473)"
          fill={color}
        />
      </g>
      <g id="Group_225" data-name="Group 225" transform="translate(0 19.641)">
        <circle
          id="Ellipse_23"
          data-name="Ellipse 23"
          cx="0.789"
          cy="0.789"
          r="0.789"
          fill={color}
        />
        <circle
          id="Ellipse_24"
          data-name="Ellipse 24"
          cx="0.789"
          cy="0.789"
          r="0.789"
          transform="translate(0 2.515)"
          fill={color}
        />
        <circle
          id="Ellipse_25"
          data-name="Ellipse 25"
          cx="0.789"
          cy="0.789"
          r="0.789"
          transform="translate(0 5.031)"
          fill={color}
        />
      </g>
    </svg>
  );
}
