import React from "react";
import { EcouponContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { userService } from "apiServices";
import { withTranslation } from "react-i18next";
import { Cards, Displays } from "components";

class EcouponContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_REWARD_LIST(`?is_active=true&type=1`);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handleClickItem = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.POINT_REDEEM}/detail/${e._id}`);
  };

  render() {
    const { t } = this.props;
    const { isLoading, data } = this.state;
    return (
      <EcouponContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : !data || data.docs.length === 0 ? (
          <Displays.LabelEmpty label={t("No Data", { ns: "common" })} />
        ) : (
          data.docs.map((e, i) => (
            <div
              key={i}
              className="product_item"
              onClick={() => this.handleClickItem(e)}
            >
              <Cards.ProductRedeem src={e.img} redeemPoint={e.redeem_point} />
            </div>
          ))
        )}
      </EcouponContainerStyled>
    );
  }
}

export default withTranslation(["common"])(withRouter(EcouponContainer));
