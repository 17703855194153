import React from "react";

export default function renderIcon({
  width = 52.044,
  height = 52.044,
  color = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52.044 52.044"
    >
      <g
        id="Group_528"
        data-name="Group 528"
        transform="translate(3022.631 -4805.302)"
      >
        <path
          id="Path_160"
          data-name="Path 160"
          d="M1956.426,1129.3a26.022,26.022,0,1,1,26.022-26.022A26.051,26.051,0,0,1,1956.426,1129.3Zm0-47.791a21.769,21.769,0,1,0,21.769,21.769A21.794,21.794,0,0,0,1956.426,1081.512Z"
          transform="translate(-4953.036 3728.043)"
          fill={color}
        />
        <g
          id="Group_524"
          data-name="Group 524"
          transform="translate(-3049.549 4532.066)"
        >
          <path
            id="Union_3"
            data-name="Union 3"
            d="M0,21.265A4.99,4.99,0,0,1,4.892,16.2a4.747,4.747,0,0,1,2.687.833V7.942a4.094,4.094,0,0,1,2.329-4.2C11.915,2.885,19.743.181,20.076.066l.2-.046A3.021,3.021,0,0,1,22.54.664,2.427,2.427,0,0,1,23.3,2.527V15.414a5.314,5.314,0,0,1,.118,1.106,4.99,4.99,0,0,1-4.895,5.07,5.073,5.073,0,0,1,0-10.14,4.744,4.744,0,0,1,2.741.872V2.527a.44.44,0,0,0-.067-.282,1.073,1.073,0,0,0-.591-.137c-1.006.35-8.1,2.813-9.915,3.586A2.017,2.017,0,0,0,9.607,7.741l.009.139V19.934a5.183,5.183,0,0,1,.171,1.331,4.99,4.99,0,0,1-4.9,5.07A4.99,4.99,0,0,1,0,21.265Zm2.037,0a2.913,2.913,0,0,0,2.855,2.961,2.963,2.963,0,0,0,0-5.921A2.913,2.913,0,0,0,2.037,21.265ZM15.66,16.52a2.86,2.86,0,1,0,2.859-2.961A2.915,2.915,0,0,0,15.66,16.52Z"
            transform="translate(39.232 286)"
            fill={color}
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
