import styled from "styled-components";

export const NotificationWidgetStyled = styled.div`
  .btn_notification {
    padding: 10px;
    cursor: pointer;
  }
  .notification_body {
    position: absolute;
    right: 0px;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    padding: 4px 8px;
    min-width: 360px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .noti_item {
      padding: 2px 6px;
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
      cursor: pointer;
      z-index: 2;
      &:last-child {
        margin-bottom: 0px;
        border-bottom: 0px;
      }
      .noti_title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: ${({ theme }) => theme.COLORS.BLACK_2};
        font-size: ${({ theme }) => theme.FONT.SIZE.S13};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
      }
      .noti_description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: ${({ theme }) => theme.COLORS.GRAY_3};
        font-size: ${({ theme }) => theme.FONT.SIZE.S11};
        font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      }
    }
  }
`;
