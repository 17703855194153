import styled from 'styled-components';

export const BtnLineWidgetStyled = styled.div`
  border-radius: 12px;
  background: ${({ theme }) => theme.COLORS.GREEN_1};
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONT.SIZE.S21};
  font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S21};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
`;
