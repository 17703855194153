import styled from 'styled-components';

export const DateTimeStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .date_container {
    border-radius: 9px;
    border: 1.5px solid ${({ theme }) => theme.COLORS.PURPLE_1};
    padding: 0 15px;
    width: 100%;
    height: 42px;
    background: transparent;
    text-align: left;
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    .date_time_placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_1};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .theme_standard {
  }
`;
