import styled from 'styled-components';

export const QuickTestSuccessContainerStyled = styled.div`
  padding: 60px 18px 104px 18px;
  .status_wrap {
    margin-bottom: 55px;
  }
  .btn_wrap {
    margin: auto;
    max-width: 145px;
  }
`;
