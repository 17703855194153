import styled from 'styled-components';

export const RewardStyled = styled.div`
  .reward_container {
    .rw_img {
      margin-bottom: 18px;
      width: 100%;
      height: auto;
    }
    color: ${({ theme }) => theme.COLORS.BLACK_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
  }

  .theme_standard {
  }
`;
