import React from 'react';

export default function renderIcon({
  width = 32.173,
  height = 35.45,
  color = '#4b1e78',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32.173 35.45"
    >
      <g
        id="Group_308"
        data-name="Group 308"
        transform="translate(-370.068 -853.711)"
      >
        <path
          id="Path_109"
          data-name="Path 109"
          d="M2351.623,1457.291a10.158,10.158,0,1,1,10.157-10.158,10.169,10.169,0,0,1-10.157,10.158m0-18.034a7.877,7.877,0,1,0,7.876,7.877,7.886,7.886,0,0,0-7.876-7.877"
          transform="translate(-1967.716 -583.265)"
          fill={color}
        />
        <path
          id="Path_110"
          data-name="Path 110"
          d="M2337.955,1478.183a1.141,1.141,0,0,1-1.122-.943,14.033,14.033,0,0,1-.208-2.41,13.839,13.839,0,0,1,23.624-9.784,1.14,1.14,0,1,1-1.613,1.612,11.559,11.559,0,0,0-19.731,8.173,11.69,11.69,0,0,0,.174,2.015,1.14,1.14,0,0,1-.925,1.321,1.111,1.111,0,0,1-.2.017"
          transform="translate(-1966.556 -589.022)"
          fill={color}
        />
        <path
          id="Path_111"
          data-name="Path 111"
          d="M2366.661,1472.47h3.477v-3.486h2.363v3.486h3.5v2.363h-3.5v3.467h-2.363v-3.467h-3.477Z"
          transform="translate(-1973.755 -590.938)"
          fill={color}
        />
      </g>
    </svg>
  );
}
