import React from "react";
import { PointRedeemContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Outlet } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MyPointWidget } from "widgets";
import { Headers } from "components";

class PointRedeemContainer extends React.Component {
  state = {};

  componentDidMount() {
    // const { data } = this.props;
    // this.handleClickRoute(data[0]);
  }

  handleClickRoute = (e) => {
    this.setState({
      active: e.key,
    });
    this.props.router.navigate(e.path);
  };

  render() {
    const { data, t } = this.props;
    const { active } = this.state;
    return (
      <PointRedeemContainerStyled>
        <Headers.Logo
          nodeLeft={
            <div className="title">
              {t("Point Of Redemption", { ns: "point" })}
            </div>
          }
          nodeRight={<MyPointWidget />}
        />
        <Outlet />
        <div className="footer">
          {data.map((e, i) => (
            <div
              key={i}
              className={`f_col ${active === e.key ? "active" : ""}`}
              onClick={() => this.handleClickRoute(e)}
            >
              {active === e.key ? e.iconAct : e.icon}
              {t(e.name, { ns: "point" })}
            </div>
          ))}
        </div>
      </PointRedeemContainerStyled>
    );
  }
}

export default withTranslation(["point"])(withRouter(PointRedeemContainer));
