import React from 'react';
import { QuickTestSuccessContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import queryString from 'query-string';
import { MyPointWidget } from 'widgets';
import { Buttons, Displays, Headers, Icons } from 'components';

class QuickTestSuccessContainer extends React.Component {
  handleClickBack = () => {
    this.props.router.navigate(`${ROUTE_PATH.SPECIAL_POINTS_FAV}`);
  };

  render() {
    let { point } = queryString.parse(this.props.router.location.search);
    return (
      <QuickTestSuccessContainerStyled>
        <Headers.Logo nodeRight={<MyPointWidget />} />
        <Displays.HeaderIconTitle
          mb={'25px'}
          icon={<Icons.SpecialPoint />}
          topLabel={'Special Points'}
          bottomLabel={'คะแนนพิเศษ'}
        />
        <div className="status_wrap">
          <Displays.AlertLabel
            label={['ขอบคุณที่แสดงความคิดเห็น', `คุณได้รับ ${point || 0} แต้ม`]}
          />
        </div>
        <div className="btn_wrap">
          <Buttons.BgStandard label="ย้อนกลับ" onClick={this.handleClickBack} />
        </div>
      </QuickTestSuccessContainerStyled>
    );
  }
}

export default withRouter(QuickTestSuccessContainer);
