import React from 'react';
import { RegisterCorperateOnlyFormStyled } from './styled';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import address from 'utils/jsons/thailandAddress/address.json';
import { useTranslation } from 'react-i18next';
import { Buttons, Inputs } from 'components';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const nameRegExp = /^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u;

const RegisterCorperateOnlyForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Firstname is required'),
    // .matches(nameRegExp, 'Only alphabets are allowed for this field '),
    // coporate_id: yup
    //   .string()
    //   .required('Firstname is required')
    //   .matches(nameRegExp, 'Only alphabets are all owed for this field '),
    province: yup.object().required('Province is required'),
    phone_number: yup
      .string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Invalid phone number'),
    email: yup.string().email().required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(['common', 'register']);

  const renderProvinceOptions = () => {
    return address.reduce((result, data, i) => {
      if (i === 0) {
        result.push({ label: data.province, value: data.province });
      } else {
        let find = result.find((e) => e.value === data.province);
        if (!find) {
          result.push({ label: data.province, value: data.province });
        }
      }
      return result;
    }, []);
  };

  return (
    <RegisterCorperateOnlyFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_sec mb_20">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t('ชื่อองค์กร', { ns: 'register' })}`}
                  errors={errors.name?.message}
                />
              )}
              name="name"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t('เลขทะเบียนนิติบุคคล (ถ้ามี)', {
                    ns: 'register',
                  })}`}
                  errors={errors.coporate_id?.message}
                />
              )}
              name="coporate_id"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownSelect
                  {...field}
                  options={renderProvinceOptions()}
                  placeholder={`* ${t('Province', { ns: 'register' })}`}
                  errors={errors.province?.message}
                />
              )}
              name="province"
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t('Mobile number', { ns: 'register' })}`}
                  errors={errors.phone_number?.message}
                />
              )}
              name="phone_number"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={`* ${t('Email', { ns: 'register' })}`}
                  errors={errors.email?.message}
                />
              )}
              name="email"
              defaultValue=""
            />
          </div>
        </div>
        <Buttons.BgStandard
          type="submit"
          label={t('Register', { ns: 'common' })}
        />
      </form>
    </RegisterCorperateOnlyFormStyled>
  );
};

RegisterCorperateOnlyForm.propTypes = {};

export default RegisterCorperateOnlyForm;
